// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBNNh4_dn9XUCtgZw67-mRRpdtoByYpoRc",
  authDomain: "gng-motor-service.firebaseapp.com",
  projectId: "gng-motor-service",
  storageBucket: "gng-motor-service.appspot.com",
  messagingSenderId: "310360364394",
  appId: "1:310360364394:web:755dee86c9bbf800c58113"
};

// Initialize Firebase
initializeApp(firebaseConfig);