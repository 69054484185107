// homepage nav bar
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../Navbar/Navbar.css'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

function CollapsibleExample() {
  let user = {}; let role = ''; let storSupport = false;
  try { storSupport = (window.localStorage && true); }
  catch(e) { toast.error('Tolong cookie nya di allow supaya bisa save data !') };
  if ( storSupport ) {
     let userString = localStorage.getItem('user');
     user = JSON.parse(userString);
     if (user) {role = user.role;};
  };

  const navigate = useNavigate();
  function logout() { localStorage.removeItem('user'); navigate('/') }
  function gotoLogin() { navigate('/login') }
  
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container ><img src="favicon/favicon.ico" alt="ico" width="32"/>
        <Navbar.Brand href="https://gngmotorservice.com"><span>&nbsp;&nbsp;GnG Motor Service</span></Navbar.Brand>

        <button key='jadwal-servis' className='button-transparent-nav ml-auto'  >
        <a href="https://gngmotorservice.com/jadwal-servis"><span>Jadwal Servis  .</span>
        <span><FontAwesomeIcon icon={faCalendarDays} /></span></a></button>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {/*  */}
            {role === '' ?
              <div><button className='btn btn-primary' onClick={gotoLogin}> Login  </button></div>
	    :
              /* <NavDropdown title={user.userName.toUpperCase()} id="collasible-nav-dropdown"> */
              <NavDropdown title={user.userName} id="collasible-nav-dropdown">
                {role === 'admin' ? <NavDropdown.Item href="admin/dashboard">Admin Panel</NavDropdown.Item> : ''
                } 
                <NavDropdown.Item href="/perawatan_rutin">Book Service</NavDropdown.Item>
                <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                <NavDropdown.Item href="#" onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
