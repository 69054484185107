import React from 'react';
import { Carousel } from 'react-bootstrap';
import { AllStates } from '../../context/Context';
import Loading from '../Loading/Loading';
import '../Carousel/Carousel.css' ;

const Carousell = () => {
  const { images, url } = AllStates();
  
  return (
    <div>          
    {/* images list */}
    {images ? (
    <div className="carousel-container"><Carousel  className='carousel'>
      {/* map */}
      {images && images.caraosel.map((item, index) => {
          return (
            <Carousel.Item key={images.caraosel_text[index]}>
            <div className='sliding-img'><img className="d-block w-100 sliding-img" 
            src={url+"/images/caraosel/"+item} alt={images.caraosel_text[index]} key={images.caraosel_text[index]}/></div>
             <Carousel.Caption><h1 className='caption'>{images.caraosel_text[index]}</h1></Carousel.Caption></Carousel.Item>       
           )})}
  </Carousel>
  </div>
    ) : <Loading/>}
  </div>
  )
}

export default Carousell