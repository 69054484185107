// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-transparent-home {
    background-color: Transparent; background-repeat:no-repeat; border: none;
    cursor:pointer; overflow: hidden; outline:none;
}

.rbc-calendar { min-height: 600px; }`, "",{"version":3,"sources":["webpack://./src/components/Calendar/bigCalendar.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B,EAAE,2BAA2B,EAAE,YAAY;IACxE,cAAc,EAAE,gBAAgB,EAAE,YAAY;AAClD;;AAEA,gBAAgB,iBAAiB,EAAE","sourcesContent":[".button-transparent-home {\r\n    background-color: Transparent; background-repeat:no-repeat; border: none;\r\n    cursor:pointer; overflow: hidden; outline:none;\r\n}\r\n\r\n.rbc-calendar { min-height: 600px; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
