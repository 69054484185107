// nav bar to toggle between services
import React from 'react'
const Mininav = () => {
  return (
    <div>
    <nav className='navbar navbar-expand-lg navbar-dark bg-dark '>
        <div className='container'>
        <img src="favicon/favicon.ico" alt="ico" width="32"/>
          <a href='http://gngmotorservice.com' className='navbar-brand'> <span>&nbsp;&nbsp;GnG Motor Service</span>  </a>
     <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navv'> <span className='navbar-toggler-icon'></span> </button>
        <div className='collapse navbar-collapse' id='navv'>
            <ul className='navbar-nav ml-auto'>
                <li className='nav-item '><a href="/" className='nav-link'> Home </a></li>
                <li className='nav-item '><a href="/perawatan_rutin" className='nav-link '> Servis Ringan / Rutin </a></li>
                <li className='nav-item '><a href="/service" className='nav-link'> Servis Berat</a></li>
                <li className='nav-item '><a href="/repair" className='nav-link'> Overhaul </a></li>
                <li className='nav-item '><a href="/contact" className='nav-link'> Kontak </a></li>
            </ul>
        </div>
        </div>
    </nav>
    </div>
  )
}

export default Mininav