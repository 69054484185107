import React, { useEffect, useState } from 'react'
import Linechart from '../../components/Chart/Linechart'
import DoughnutChart from '../../components/Chart/Doughnut'
import Table from 'react-bootstrap/Table';
import { AllStates } from '../../context/Context';
import '../admin/Admin.css'
import Adminnav from './Adminnav';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import dayjs from 'dayjs';
import moment from 'moment/moment';

const Dashboard = () => {
    const { bookings } = AllStates();
    const navigate = useNavigate();
    const today = new Date()
    const current_date = dayjs(today).format('YYYY-MM-DD');
    var serial_number = 0;
    const [psearch, setPsearch] = useState('');
    localStorage.setItem("activeTab", JSON.stringify('caraosel'));
    useEffect(() => { if(JSON.parse(localStorage.getItem('user')).role !== 'admin'){ navigate('/') }},[])
    return (
        <>
            <Adminnav /> <div className='container'>
            {/* line chart */}
            <div className='linechart'><p>Daftar booking servis bulanan (* Semua service di database) </p>
                <Linechart /> </div>

            <div className='upcoming-services container'>
                <div className='search-bar m-1'>
                    <input type='search' placeholder='Cari...' value={psearch} onChange={e => setPsearch(e.target.value)} /> </div> 
                { bookings ? (  <Table striped className='mb-3 mt-3' variant='info' hover responsive>
                    <thead >
                        <tr>
                            <th>No</th>
                            <th>Nama</th>
                            <th>No HP</th>
                            <th>Merek</th>
                            <th>Tipe</th>
                            <th>No Pol</th>
                            <th>Tipe Servis</th>
                            <th>Tanggal</th>
                            <th>Slot waktu</th>
                            <th>Terbayar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* sort date in ascending order */}
                        {bookings && bookings.sort(function (a, b) {
                        if (a.serviceDate < b.serviceDate) return -1;
                        if (a.serviceDate > b.serviceDate) return 1;
                        return 0;
                    })
                        .filter(item => {
                            if (psearch === '') { return item } 
                            else if (item.userName.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                            else if (item.mobile?.includes(psearch)) { return item } 
                            else if (item.bikeCompany.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                            else if (item.model.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                            else if (item.nopol.toLowerCase().includes(psearch.toLowerCase())) { return item }
                            else if (item.serviceType.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                            else if (item.serviceDate?.includes(psearch)) { return item }
                            else if (item.serviceTime?.includes(psearch)) { return item }
                            else if (item.paid?.includes(psearch)) { return item } 

                        }).map((item, index) => {
                            if (moment(current_date).isSameOrBefore(item.serviceDate)) {
                               // thisDay.push(item)
                                return (
                                    <tr key={item._id}>
                                        <td> {serial_number = serial_number + 1}</td>
                                        <td>{item.userName}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.bikeCompany}</td>
                                        <td>{item.model}</td>
                                        <td>{item.nopol}</td>
                                        <td>{item.serviceType}</td>
                                        <td>{item.serviceDate}</td>
                                        <td>{item.serviceTime ? item.serviceTime : 'NULL'}</td>
                                        <td>{item.paid ? 'Sudah' : 'Belum'}</td>
                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </Table> ) : <Loading/> }
            </div>
            <br/><br/>{/* Doughnut chart - shows overall service data */}
            <div className='doughnutchart'><p>Merk Motor yg sering diservis</p><DoughnutChart /></div>
        </div >
        </>
     )
}
export default Dashboard