// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{ display: flex; flex-direction: column; justify-content: space-around;  padding:5px; background-color: bisque; }
.address{ display: block; line-height: 200%; }
.contact{ margin: 5px 10px; }
.anchor{ text-decoration: none; display: block; color:black; line-height: 200%; }
.footer-content{ display: block; line-height: 200%; margin:5px 10px; }
.row { --bs-gutter-x: 0; --bs-gutter-y: 0; }
.address , .social , .company , .contact{ margin-bottom: 5px; }
.anchor:hover{ margin-left: 3px; }
@media (min-width: 576px) { 
    .footer{ display: flex; flex-direction: column; flex-wrap: wrap; justify-content: space-around; }
}
@media (min-width: 768px) { 
    .footer{ display: flex; flex-direction: row; justify-content: space-around; }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA,SAAS,aAAa,EAAE,sBAAsB,EAAE,6BAA6B,GAAG,WAAW,EAAE,wBAAwB,EAAE;AACvH,UAAU,cAAc,EAAE,iBAAiB,EAAE;AAC7C,UAAU,gBAAgB,EAAE;AAC5B,SAAS,qBAAqB,EAAE,cAAc,EAAE,WAAW,EAAE,iBAAiB,EAAE;AAChF,iBAAiB,cAAc,EAAE,iBAAiB,EAAE,eAAe,EAAE;AACrE,OAAO,gBAAgB,EAAE,gBAAgB,EAAE;AAC3C,0CAA0C,kBAAkB,EAAE;AAC9D,eAAe,gBAAgB,EAAE;AACjC;IACI,SAAS,aAAa,EAAE,sBAAsB,EAAE,eAAe,EAAE,6BAA6B,EAAE;AACpG;AACA;IACI,SAAS,aAAa,EAAE,mBAAmB,EAAE,6BAA6B,EAAE;AAChF","sourcesContent":[".footer{ display: flex; flex-direction: column; justify-content: space-around;  padding:5px; background-color: bisque; }\r\n.address{ display: block; line-height: 200%; }\r\n.contact{ margin: 5px 10px; }\r\n.anchor{ text-decoration: none; display: block; color:black; line-height: 200%; }\r\n.footer-content{ display: block; line-height: 200%; margin:5px 10px; }\r\n.row { --bs-gutter-x: 0; --bs-gutter-y: 0; }\r\n.address , .social , .company , .contact{ margin-bottom: 5px; }\r\n.anchor:hover{ margin-left: 3px; }\r\n@media (min-width: 576px) { \r\n    .footer{ display: flex; flex-direction: column; flex-wrap: wrap; justify-content: space-around; }\r\n}\r\n@media (min-width: 768px) { \r\n    .footer{ display: flex; flex-direction: row; justify-content: space-around; }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
