import React, { createContext, useState, useEffect, useContext } from 'react'
import axios from 'axios'
export const AppContext = createContext();
const MyContext = ({ children }) => {
  const [users, setUsers] = useState();
  const [bikes, setBikes] = useState();
  const [services, setServices] = useState();
  const [bookings, setBookings] = useState();
  const [images, setImages] = useState();
  const [servItems, setServItems] = useState();
  const [propinsi, setPropinsi] = useState();
  const url = 'https://gngmotorservice.com';
 
  useEffect(() => {
    async function getAllData() {
      try {
        await axios.get(`${url}/users`).then(res => setUsers(res.data.user)) ;
        await axios.get(`${url}/bikes`).then((res) => setBikes(res.data.bikes));
        await axios.get(`${url}/services`).then((res) => setServices(res.data.services));
        await axios.get(`${url}/bookings`).then((res) => setBookings(res.data.bookings));
        await axios.get(`${url}/image/settings`).then((res) => setImages(res.data.setup));
        await axios.get(`${url}/services/service/items`).then((res) => setServItems(res.data.servItems));
        await axios.get('https://kodepos-2d475.firebaseio.com/list_propinsi.json').then((res) => setPropinsi(res.data));
        } 
      catch (error) { console.log(error, 'context error') }
    } getAllData(); }, []);
    return (<AppContext.Provider value={{ users, bikes, services, bookings, images, servItems, propinsi, url}} >{children} </AppContext.Provider>)
}
export default MyContext
export const AllStates = () => { return useContext(AppContext); }
