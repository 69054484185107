import React, { useEffect, useState } from 'react'
import service_data from './service_data'; // static data (general service list)
import './Service.css'
import { AllStates } from '../../context/Context';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify'
import Mininav from '../../components/Navigation/Mininav';
import dayjs from 'dayjs';
import Loading from '../../components/Loading/Loading';
import Footer from '../../components/Footer/Footer'
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [register, setRegister] = useState('');
    const [bike, setBike] = useState('');
    const [model, setModel] = useState('');
    const [show, setShow] = useState(false); // for modal
    const handleClose = () => setShow(false); // for modal
    const handleShow = () => setShow(true); // for modal
    const navigate = useNavigate();
    const { bikes, services, url } = AllStates();
    const email = JSON.parse(localStorage.getItem('user'))?.email;
    const userId = JSON.parse(localStorage.getItem('user'))?.id;
    const user = localStorage.getItem('user');
    const bikeAry = [];
    let brand = [];

    // disable past date from calendar
    let current_date = new Date();
    let current_time = new Date().toISOString()
    let datee = current_date.getDate(); // 2,5,10,15...
    let month = current_date.getMonth() + 1; // 6,7,10,12
    let year = current_date.getUTCFullYear(); // 2023

    if (month < 10) { month = "0" + month; }
    if (datee < 10) { datee = "0" + datee; }

    let minDate = `${year}-${month}-${datee}`; // yyyy-mm-dd
    const [date, setDate] = useState(minDate); // yyyy-mm-dd
    const [detail, setDetail] = useState(); // get a selected bike's data for displaying the image

    // get all brands without duplicates
    bikes && bikes.map((i) => bikeAry.push(i.bikeCompany))
    brand = unique(bikeAry)

    function booknow(e) { e.preventDefault(); customerData(); }
    async function customerData() {
        const customer = {
            name, mobile, register, bike, model }
        if (customer.name === '' || customer.mobile === '' || customer.register === '' || customer.bike === '' || customer.model === '') {
            alert('please submit a valid form') } 
        else { handleShow(customer); }
    }
    async function bookings() {
        const bookingData = {
            name, mobile, date, register, bike, model, serviceDate: dayjs(date).format('YYYY-MM-DD'),
            email, serviceType: [], serviceCategory: 'Servis-Berat', userId: userId
        }
        await axios.post(`${url}/bookings/addbooking`, bookingData)
        setName(''); setMobile(''); setRegister(''); setDate(''); setModel(''); setBike('');
    }

    useEffect(() => {
        if (!user) {  navigate('/');  toast.warning('Please Login') }

    }, [])

    // to remove duplicates in array
    function unique(array) { return array.filter((item, index) => array.indexOf(item) === index) }

    return (
        <div  >
            <Mininav />
            <div className='booking-form  '>
            <div className='overlay'>Belum Tersedia<FontAwesomeIcon icon={faCircleExclamation} /></div>
                <div className='bike-details ' > <form onSubmit={booknow}>
                    <h2> Mohon di isi </h2>
                    <div className='bike-detail-feilds mb-2'> <input className='input' type='text' pattern='[A-Za-z]{1,15}' title="Nama minimal 3 huruf" placeholder='Nama' value={name}  onChange={e => setName(e.target.value)} required /> </div>
                    <div className='bike-detail-feilds mb-2'> <input type="number" name="No Hp" pattern="[0-9]{10}" title='Ketik No Hp anda' className='input' placeholder='No Hp' value={mobile} onChange={e => setMobile(e.target.value)} required /> </div>
                    <div className='bike-detail-feilds mb-2'> <input className='input' placeholder='No Polisi Kendaraan'  value={register} onChange={e => setRegister(e.target.value)} required /> </div>
                    <div className='bike-detail-feilds mb-2'> <select placeholder='Merk Motor' onChange={e => setBike(e.target.value)} required>
                        <option value=''> Pilih Merek  </option>
                        {brand.map((i) => <option value={i} key={i._id} > {i} </option>)}
                    </select> </div>
                    <div className='bike-detail-feilds mb-2'> <select placeholder='Pilih Tipe' onChange={e => setModel(e.target.value)} required>
                        <option value=''> Pilih Tipe  </option>
                        {bikes && bikes.map((i) => bike && bike == i.bikeCompany ? <option value={i.model} key={i._id}> {i.model} </option> : '')}
                    </select> </div>
                    <div>  <input className='input mb-2' id="calendar" type="date" value={date} min={minDate} onChange={e => setDate(e.target.value)} required /> </div>
                    <div>  <button className='btn btn-success' type="submit"> Booking Sekarang</button> </div>
                </form>
                </div>

                {/* modal */}
                {detail &&
                    <div>
                        <Modal show={show} onHide={handleClose} >
                            <Modal.Header >
                                <Modal.Title style={{ textAlign: "center" }}> Sevis Berat / Tune Up </Modal.Title>
                            </Modal.Header>
                            <Modal.Body ><b><p>Merek : </p></b> <span>{detail.bikeCompany}</span> </Modal.Body>
                            <Modal.Body ><b><p>Tipe : </p></b> <span>{detail.model}</span> </Modal.Body>
                            <Modal.Body><b><p>Harga : </p></b><span>₹{}</span></Modal.Body>
                            <Modal.Footer>
                                <Button variant="success" onClick={handleClose}> Kirim </Button>
                                <Button variant="danger" onClick={handleClose}> Tutup </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                }

                <div className='booking-form-img'>
                    <img className='book' src={require('./images/book_now.png')} alt='book now' />
                </div>
                
            </div>

            {/* bike image div */}
            <div className='bike-img'>
                {detail && detail.model === model ? <img src={detail.image} alt='bike' /> : <Loading />}
            </div>

            {/* service detail list */}
            <div className='general_service'>

                <div className='service-list'>
                    <p> Apa saja yg dikerjakan di Servis Berat ?  </p>
                    <ul> {service_data.map((i) => <li key={i.id}> {i.value}  </li>)} </ul>
                </div>
                <div className='service-img mb-5'> <img src={require('./images/mechanic.png')} alt="cartoon" />  </div>
            </div>
            <Footer />
        </div>
    )
}

export default Services
