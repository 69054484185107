// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse { display: flex!important; flex-basis: auto; justify-content: flex-end; }
}

.button-transparent-nav {
    background-color: Transparent; background-repeat:no-repeat; border: none;
    cursor:pointer; overflow: hidden; outline:none; text-align: right; flex: 1 1;
}

.button-transparent-nav a { color: white }`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC,uBAAuB,EAAE,gBAAgB,EAAE,yBAAyB,EAAE;AAC/G;;AAEA;IACI,6BAA6B,EAAE,2BAA2B,EAAE,YAAY;IACxE,cAAc,EAAE,gBAAgB,EAAE,YAAY,EAAE,iBAAiB,EAAE,SAAO;AAC9E;;AAEA,4BAA4B,aAAa","sourcesContent":["@media (min-width: 992px) {\r\n    .navbar-expand-lg .navbar-collapse { display: flex!important; flex-basis: auto; justify-content: flex-end; }\r\n}\r\n\r\n.button-transparent-nav {\r\n    background-color: Transparent; background-repeat:no-repeat; border: none;\r\n    cursor:pointer; overflow: hidden; outline:none; text-align: right; flex: 1;\r\n}\r\n\r\n.button-transparent-nav a { color: white }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
