import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { AllStates } from '../../context/Context';

ChartJS.register( ArcElement, Tooltip, Legend );

const DoughnutChart = () => {
    const { bookings } = AllStates();
    let totalServiceCount =0;
    let merkAll = []; let merkArray = []; let nArray = []; let nPercent = []; let colors = []; let blacks = [];
    function unique(array) { return array.filter((item, index) => array.indexOf(item) === index) };
    function merkCount(merk) { let c=0; bookings && bookings.map(i => i.bikeCompany === merk ? c++ : ''); return c; };
    useEffect( () => {}, []);

    if (bookings) {
        totalServiceCount = bookings.length;
        bookings.map( (i) => merkAll.push(i.bikeCompany) );
        merkArray = unique(merkAll);
        merkArray.map( (i) => nArray.push(merkCount(i)) );
        nArray.map( (i) => nPercent.push(((i / totalServiceCount) * 100).toFixed(2) ));
        
        for (let i = 0; i < nArray.length; i++) {
            const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
            colors.push(randomColor); blacks.push('black');
        }
    }

    const chartData = { labels: merkArray, data: nPercent };
    const data = {
        labels: chartData.labels,
        datasets: [{ label: 'percentage(%)', data: chartData.data,
            backgroundColor:colors, borderColor: blacks }]
    };
    const options = { hoverBorderWidth: 5, plugins: { legend: { display: true } } };

    return (      
        <div>
            <div >
                <h2 className='m-2'>TOTAL RINCIAN SERVIS</h2>
                <div className='chart-container m-2 d-flex' style={{flexWrap:'wrap'}}>
                    <div key='donat'><Doughnut data={data} options={options}></Doughnut></div>
                    <div className='chart-detail m-2' key='chartDetail'>
                        {merkArray.map( (x, i) => (
                        <h4>{x} : <span key={x} style={{ color:'blue' }}>{nArray[i]}</span></h4> ))}                        
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default DoughnutChart