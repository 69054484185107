import React from 'react'
import axios from 'axios';
import * as yup from 'yup'; // for form validation schema
import { useFormik } from 'formik';
import { TextField } from '@mui/material'
import { toast } from 'react-toastify';
import SendIcon from '@mui/icons-material/Send';
import '../Contact/contact.css'
import Mininav from '../../components/Navigation/Mininav';

const contactFormSchemaValidation = yup.object({
    userName: yup.string().min(3, 'Nama minmum 3 huruf').required("Masukkan nama anda"),
    email: yup.string().email().required("Masukkan Email"),
    mobile: yup.string().matches(/^[0-9]{10}/, "Masukkan No HP yg masih berlaku").required("Masukkan No HP"),
    message: yup.string().required('Ketik Pesan'),
})

const Contact = () => {
    const { values, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
        initialValues: { userName: "",  email: "",  mobile: "",  message: "" },
        validationSchema: contactFormSchemaValidation,
        onSubmit: (user_msg) => contact_form(user_msg)
    })
    async function contact_form(user_msg) {
        try {
            await axios.post('https://localhost:8000/users/send_mail', user_msg).data
            toast.success('Formulir berhasil dikirim');
            values.userName = ''; values.email = ''; values.mobile = '';  values.message = ''; } 
        catch (error) { console.log(error); toast.error('Formulir gagal dikirim') }
    }
    return (
        <div>
            <Mininav />
            <div className='contactbox-1'>
            <h1>Siapa Kami?</h1><p>GnG motor service, Service motor keliling sampai ke kantor/rumah anda - Mudah, Transparan, dan Hemat biaya</p>
           </div><div className='contactbox-2'><h1 style={{textAlign:'right'}}>Motto Kita</h1>
              <p style={{textAlign:'right'}}>
              GnG Motor Service - memberi kemudahan layanan servis tanpa harus pergi ke Bengkel </p>
            </div>

            <div className='row d-flex' style={{ justifyContent: "center" , flexDirection:"column", alignItems:"center" }}>
                <h1 style={{textAlign:'center', marginTop:'50px'}}> Kontak kami </h1>
                <div style={{textAlign:'center'}}>
                  <p>  Ada Pertanyaan ? Ketik pesan anda dan akan kita jawab secepatnya. </p></div>
                <div className='contact_form col-md-4 ' >
                    <form onSubmit={handleSubmit}>
                        <TextField id="outlined-basic1" required label="NAMA USER" onBlur={handleBlur} variant="outlined" fullWidth margin="normal" name="userName" value={values.userName} onChange={handleChange} /> <br />
                        {touched.userName && errors.userName ? <p style={{ color: "red" }}>{errors.userName}</p> : ""}
                        <TextField id="outlined-basic2" required label="EMAIL" variant="outlined" onBlur={handleBlur} fullWidth margin="normal" name="email" value={values.email} onChange={handleChange} /> <br />
                        {touched.email && errors.email ? <p style={{ color: "red" }}>{errors.email}</p> : ""}
                        <TextField id="outlined-basic3" required label="No HP" variant="outlined" onBlur={handleBlur} fullWidth margin="normal" name="mobile" value={values.mobile} onChange={handleChange} /> <br />
                        {touched.mobile && errors.mobile ? <p style={{ color: "red" }}>{errors.mobile}</p> : ""}
                        <TextField id="standard-multiline-static" required label="Pesan" variant="outlined" multiline rows={4} onBlur={handleBlur} fullWidth margin="normal" name="message" value={values.message} onChange={handleChange} />
                        {touched.message && errors.message ? <p style={{ color: "red" }}>{errors.message}</p> : ""}
                        <div className='d-flex' style={{ justifyContent: "center", alignItems: "center" }}> <button className='btn btn-primary mb-3 register-btn' type='submit'> SEND <span> <SendIcon /> </span> </button> </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact
