import React from 'react'
import '../homepage/Homepage.css'
import Nav from "../../components/Navbar/Nav"
import JadwalServis from '../../components/Calendar/bigCalendar'

//import ImageKanan from './images/kanan.png';

const Scheduling = () => {


return (
  <div className='homepage'> <Nav />
  
  <div> <JadwalServis className='mt-3' /> </div>
  </div>
)
}

export default Scheduling