
let service_data = [
    {  id: 1, value: "Item yang sudah tersebut di servis ringan / perawatan rutin"  },
    {  id: 2, value: "Pemeriksaan kebocoran klep"  },
    {  id: 3, value: "Membersihkan ruang bakar dan piston"  },
    {  id: 4, value: " "  },
    {  id: 5, value: "Berikut item Perawatan Rutin :"  },
    {  id: 6, value: "Cek dan Ganti Oli Mesin, Oli Samping dan Oli Gardan"  },
    {  id: 7, value: "Ganti Filter Udara (Jika sudah waktunya)"  },
    {  id: 8, value: "Tambah Minyak Rem"  },
    {  id: 9, value: "Cek Suspensi Depan dan Belakang"  },
    {  id: 10, value: "Tune Up Mesin"  },
    {  id: 11, value: "Cek Lampu-lampu"  },
    {  id: 12, value: "Cek Klakson" },
    {  id: 13, value: "Cek ECU / CVT"  },
    {  id: 14, value: "Cek dan Pelumasan Rantai"  },
    {  id: 15, value: "Cek dan Penambahan Tekanan Ban"  },
    {  id: 16, value: "Cek Kampas Rem dan Setel Rem" },
    {  id: 17, value: "Cek dan Penambahan air Aki"  },
    {  id: 18, value: "Cek dan Bersihkan Busi" },
    {  id: 19, value: "Cek dan Bersihkan Injektor"  },
    {  id: 20, value: "Setel tegangan V-Belt"  },
    {  id: 21, value: "Pengencangan Mur dan Baut" },
    {  id: 22, value: "Bersihkan Body Motor" }
];

export default service_data;