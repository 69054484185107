import React, { useEffect } from 'react'
import "../perawatan_rutin/Perawatan_Rutin.css"
import Booking from '../../components/Booking/Booking'
import Mininav from '../../components/Navigation/Mininav';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Perawatan_Rutin = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');

  useEffect(() => {
    if (!user) { navigate('/'); toast.warning('Please Login') } }, [])

  return (
    <div className='perawatan-rutin-page'>
      <Mininav />
      <div className='img-info'>
        <div className='perawatan-rutin-img  m-2'>
          <img src={require("../perawatan_rutin/perawatan_rutin.jpg")} alt="perawatan-rutin" />
        </div>
        <div className='info-container'>
          <div className='info-1'>
            <p className='question'> Mengapa Penting?</p>
            <p> Tentunya agar performa motor tetap prima.  </p>
          </div>

          <div className='info-2'>
            <p className='question'> Bagaimana kita melakukannya? </p>
            <p> Fokus pada ban, lampu, sistem pembakaran dan filter udara, 
              Periksa rantai, rem, klakson dan Ganti oli mesin, 
              oli gardan, atau sparepart</p>
          </div>
        </div>
      </div>
      <div> <Booking className='mt-3' /> </div>
    </div>
  )
}

export default Perawatan_Rutin