// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
    height: 100vh; width: 100vw; margin-left: 0 !important; margin-right: 0 !important;
    background: rgb(232,48,129);
    background: linear-gradient(186deg, rgba(232,48,129,1) 0%, rgba(148,187,233,1) 100%);
    display: flex; justify-content: center; align-items: center; }
.no-account{ text-decoration: none; }`, "",{"version":3,"sources":["webpack://./src/routes/login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,YAAY,EAAE,yBAAyB,EAAE,0BAA0B;IAClF,2BAA2B;IAC3B,oFAAoF;IACpF,aAAa,EAAE,uBAAuB,EAAE,mBAAmB,EAAE;AACjE,aAAa,qBAAqB,EAAE","sourcesContent":[".login-page {\r\n    height: 100vh; width: 100vw; margin-left: 0 !important; margin-right: 0 !important;\r\n    background: rgb(232,48,129);\r\n    background: linear-gradient(186deg, rgba(232,48,129,1) 0%, rgba(148,187,233,1) 100%);\r\n    display: flex; justify-content: center; align-items: center; }\r\n.no-account{ text-decoration: none; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
