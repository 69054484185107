import { TextField } from '@mui/material'
import React, { useState , useEffect } from 'react'
import '../login/Login.css'
import InputAdornment from '@mui/material/InputAdornment';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Box from '@mui/material/Box';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import { toast } from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import { AllStates } from '../../context/Context';
import { GoogleAuthProvider, getAuth, signInWithPopup,signOut } from "firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

const Login = () => {
    localStorage.removeItem('user');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const {url} = AllStates();

    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    const auth = getAuth();
    const [authorizedUser,setAuthorizedUser] = useState(false || sessionStorage.getItem("accessToken"));

    async function signInwithGoogle() {
        console.log("start signInwithGoogle");
        signInWithPopup(auth, provider)
        .then((result) => {
            console.log("signInwithPopup -> ", result);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            
            // The signed-in user info.
            const google_user = result.user;
            if(google_user){
                google_user.getIdToken().then((tkn)=>{
                    // set access token in session storage
                    //console.log("token = ", tkn);
                    sessionStorage.setItem("accessToken", tkn);
                    setAuthorizedUser(true);

                    const displayName = google_user.displayName;
                    const userName = displayName.split(' ')[0];
                    const email = google_user.email;
                    const password = ' ';
                    const role = 'user';
                    const emailVerified = google_user.emailVerified;
                    const photoURL = google_user.photoURL;
                    const guser = { 'displayName': displayName, 'userName': userName, 'email': email,
                                   'password': password, 'role': role, 'emailVerified': emailVerified,
                                   'photoURL': photoURL, 'accessToken': tkn};

                    sessionStorage.setItem("user", JSON.stringify(guser));
                    google_login();
                })
            };
            //console.log(google_user);
        })
        .catch((error) => {
            alert(error);
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log("errorCode =", errorCode);
            console.log("errorMessage =", errorMessage);
            console.log("email =", email);
            console.log("credential =", credential);
        });
    };

    function logoutUser(){
        signOut(auth).then(() => {      
            // clear session storage
            sessionStorage.clear();
            setAuthorizedUser(false);
            // window.location.replace("/");
            alert('Logged Out Successfully');
            localStorage.removeItem('user'); 
            navigate('/');
        }).catch((error) => {
            // An error happened.
            alert(error);
        });
    };

    async function google_login() {
        console.log("google login start");
        let guser = JSON.parse(sessionStorage.getItem('user'));
        let token = sessionStorage.getItem('accessToken');
        console.log(guser); console.log(token);
        try {
            await axios.post(`${url}/users/googleLogin`, guser, 
                { headers: { "Authorization": `Bearer ${token}` } }, )
            .then(res => { 
                let g_user = res.data.userdata;
                console.log(g_user);
                localStorage.setItem('user',JSON.stringify(g_user)); 
                navigate('/');  })
            .catch(error => toast.error(error.response.data.message)); }            
        catch (error) { toast.error(error.response.data.message) } 
    };

    async function login() {
        const user = { email, password };
        console.log("Trying to login for ", email);
        try {
           await axios.post(`${url}/users/login`, user)
            .then(res => { 
                console.log("res.data = ", res.data);
                const n_user = res.data.user;
                console.log("res.data.user = ", n_user);
                localStorage.setItem('user', JSON.stringify(n_user));
                navigate('/'); })
            .catch(error => {
                toast.error(error.response.data.message);
                console.log("axios error"); }) } 
        catch (error) { 
            toast.error(error.response.data.message);
            console.log("error in trying"); };
        console.log("login tried");
    };

    // aviod user entering into login page after login
    useEffect(()=>{
        if(localStorage.getItem('user') || authorizedUser){  
            navigate('/'); toast.warning('Login Sukses')
        }
    },[]);

   // const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => { event.preventDefault(); };

    return (
        <div className='row login-page col-12'>
            <div className='form col-11 col-md-6 bs mt-3' style={{borderRadius:"5px"}} >
                <h1 style={{ textAlign: "center" }}>LOGIN DI SINI</h1>
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <TextField
                        id="input-with-icon-textfield" 
                        label="EMAIL"
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><EmailIcon /></InputAdornment> ),
                        }}
                        variant="standard" fullWidth
                        value={email} onChange={(e) => setEmail(e.target.value)}
                    />   <br />
                    <FormControl sx={{ m: 1 }} variant="standard" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} >
                        <InputLabel htmlFor="standard-adornment-password" >Password</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="Bismillah"
                            startAdornment={ <InputAdornment position="start"><VpnKeyIcon /></InputAdornment> }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
                <a className='forgotPassword' href='/forgot_password'> Lupa Password?  </a> <br/>
                <a className='no-account' href='/signup'> Tidak punya akun? klik disini </a> <br /> 
                <button className='btn btn-primary mb-3 mt-3' onClick={login} >LOGIN</button>
                <br/>
                {authorizedUser ? (
                    <>
                    <p>Authorized user</p>
                    <button className='btn btn-primary mb-3 mt-3' onClick={logoutUser}>Logout Button</button>
                    </>
                ): (
                    <>
                    <button className='btn btn-primary mb-3 mt-3' onClick={signInwithGoogle}>Login dgn {" "}
                    <span><FontAwesomeIcon icon={faGoogle} />oogle</span></button>
                    </>
                )}
            </div>
        </div>
    )
}

export default Login