import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import "../../components/Booking/Booking.css";
import "../../routes/profile/Profile";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPen } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import * as yup from 'yup'; 
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import { AllStates } from '../../context/Context';
import MenuItem from '@mui/material/MenuItem'; 
import Adminnav from './Adminnav'
import "./Admin.css";

var regSchemaValidation = yup.object({
  serviceType: yup.string().required("Ketik nama / tipe servis"),
  serviceCategory: yup.string().required("Ketik kategori servis (Ringan/Berat/Overhaul)"),
  price: yup.string().matches(/^[0-9]*$/).required("Ketik harga (angkanya saja)"),
  bikeCompany: yup.string().required("Ketik merek motor yg bisa diservis"),
  transmisi: yup.string().required("pilih manual/matic"),
  cc: yup.string().matches(/^[0-9]*$/).required('ketik cc motor'),
  model: yup.string().required('ketik tipe / model motor'),
});

const AdminAddService = () => {
  const { bikes, url } = AllStates();
  const [disableBtnUpload, setDisableBtnUpload] = useState(true);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(true);
  const [serviceURL, setServiceURL] = useState(url+"/images/add_photo.png");
  const [fileImageService, setFileImageService] = useState();
  const bikeAry = []; const transmisiArray = ['manual/matic','manual','matic'];
  let brand = [];
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  function gotoServices() { navigate('/admin/services') };
  function gotoLogin() { navigate('/login')};

  // formik function
  const { values, setFieldValue, handleSubmit, handleBlur, errors, touched } = useFormik({
      initialValues: {
          serviceType: '', serviceURL: '', serviceCategory: "servis-ringan", price: 0, 
          bikeCompany: '', model: '', cc: 100, transmisi: ''
      },
      validationSchema: regSchemaValidation,
      onSubmit: (serviceData, onSubmitProps) => {
          serviceData["price"] = values.price.toString();
          serviceData["cc"] = values.cc.toString();
          console.log("OnSubmit Trying to submit data");
          sendData(serviceData); 
          onSubmitProps.resetForm();
          setDisableBtnUpdate(true)
      }
  });

  function unique(array) { return array.filter((item, index) => array.indexOf(item) === index) };
  bikes && bikes.map((i) => bikeAry.push(i.bikeCompany)); brand = unique(bikeAry);

  async function sendData(serviceData) {
      console.log("Function sendData Sending service data = ", serviceData);
      try {
          const newOrder = await axios.post(`${url}/services/addService`, serviceData)
          .then( res => { gotoServices(); window.location.reload(); })
          .catch(error => toast.error(error.response.data.message));
      }
      catch (error) { toast.error(error.response.data.message) };
  };

  function handleOnChange(e) {
    const key = e.target.name;
    const val = e.target.value || "";

    setDisableBtnUpdate(false);
    setFieldValue(key, val);
  };

  function handleFileChange(e) {
    setFileImageService(e.target.files[0]);
    setDisableBtnUpload(false);  
  };
  async function updatePicService() {
      const formData = new FormData()
      formData.append('file', fileImageService)

      try {
        await axios.post(`${url}/images/new/imagefor/services`, formData)
        .then(res => { 
          setServiceURL(res.data.keyURL); 
          setFieldValue('serviceURL', res.data.keyURL);
          setDisableBtnUpload(true);
        }).catch(error => toast.error(error.response.data.message));    } 
      catch (error) { toast.error(error.response.data.message) };  
  };

  useEffect(() => { if (!user) { gotoLogin() }; }, [user])

  return (
    <div className='set-price-page'><Adminnav />
    <div className='mt-3' >
      <div> <h3 style={{margin:'10px'}}>Tambah Servis dan Harga</h3> </div>
      <div className='profile-picture-container col-12'>
          <div className='picture col-11 col-md-4'><img src={serviceURL} alt="service profile" height='100%'/></div>
          <div className='upload-button m-3'>
              <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>GANTI FOTO 
              <span><FontAwesomeIcon icon={faPen} /></span> </p></div> 
              <div>
                <input type='file' onChange={handleFileChange} /> <br />
                <button onClick={updatePicService} className='btn btn-success m-2' disabled={disableBtnUpload}> UPLOAD 
                  <span><FontAwesomeIcon icon={faUpload} /></span>
                </button>
              </div>
            </div>
        </div>
        <div className='bookings-form'>
          <form onSubmit={handleSubmit} style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div className='form-filling row col-12'>
              <div className='left col-12 col-md-4' style={{padding:'10px'}}>
                  <TextField id="outlined-uncontrolled" required label="Nama Servis" onBlur={handleBlur} variant="outlined" key="tipe-servis"
                      fullWidth margin="normal" name="serviceType" value={values.serviceType} onChange={handleOnChange} />
                      {touched.serviceType && errors.serviceType ? <p className="error-msg" style={{ color: "red" }}>{errors.serviceType}</p> : ""}
                  <TextField select id="outlined-uncontrolled" label="Kategori Servis" onBlur={handleBlur} key="kategori-servis"
                      fullWidth margin="normal" name="serviceCategory" value={values.serviceCategory} onChange={handleOnChange}
                      error={touched.serviceCategory && Boolean(errors.serviceCategory)} helperText={touched.serviceCategory && errors.serviceCategory} >
                      <MenuItem key={"3"} value={"servis-ringan"}> --select kategori servis--  </MenuItem>
                      {["servis-ringan","service-berat","overhaul"].map((i) => <MenuItem value={i} key={i}> {i} </MenuItem>)}
                  </TextField>
                  <TextField id="outlined-uncontrolled" required label="Harga Servis" onBlur={handleBlur} key="harga-servis"
                      fullWidth margin="normal" name="price" value={values.price} onChange={handleOnChange} />
                      {touched.price && errors.price ? <p className="error-msg" style={{ color: "red" }}>{errors.price}</p> : ""}
                </div>
                <div className='right col-12 col-md-4' style={{margin:'0' , padding:'10px'}}>
                  <TextField select id="outlined-uncontrolled" label="Merek" onBlur={handleBlur} key="Merek"
                      fullWidth margin="normal" name="bikeCompany" value={values.bikeCompany} onChange={handleOnChange}
                      error={touched.bikeCompany && Boolean(errors.bikeCompany)} helperText={touched.bikeCompany && errors.bikeCompany} >
                      <MenuItem key={"1"} value={""} disabled> --Pilih merk motor--  </MenuItem>
                      {brand.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                  </TextField>
                  <TextField select id="outlined-uncontrolled" label="Trasnmisi" onBlur={handleBlur} key="Transmisi"
                      fullWidth margin="normal" name="transmisi" value={values.transmisi} onChange={handleOnChange}
                      error={touched.transmisi && Boolean(errors.transmisi)} helperText={touched.transmisi && errors.transmisi} >
                      <MenuItem key={"10"} value={""} disabled> --Pilih manual/matic--  </MenuItem>
                      {transmisiArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                  </TextField>
                  <TextField select id="outlined-uncontrolled" label="Tipe" onBlur={handleBlur} key="Tipe"
                      fullWidth margin="normal" name="model" value={values.model} onChange={handleOnChange}
                      error={touched.model && Boolean(errors.model)} helperText={touched.model && errors.model} >
                      <MenuItem key={"2"} value={""} disabled> --pilih tipe motor--  </MenuItem>
                      {bikes && bikes.map((i) => values.bikeCompany && values.bikeCompany === i.bikeCompany ? 
                      <MenuItem value={i.model} key={i._id}> {i.model} </MenuItem> : '')}
                  </TextField>
                  <TextField id="outlined-uncontrolled" required label="cc" onBlur={handleBlur} key="cc"
                      fullWidth margin="normal" name="cc" value={values.cc} onChange={handleOnChange} />
                      {touched.cc && errors.cc ? <p className="error-msg" style={{ color: "red" }}>{errors.cc}</p> : ""}
                </div>
                <div className='container-set-harga' ><div className='form-btn'>
                    <button className='btn btn-primary mb-3 register-btn' type='submit'  disabled={disableBtnUpdate}>
                      TAMBAH SERVIS</button>
                </div></div>
            </div>
          </form>
          </div>
        </div>
      </div>
    )
}

export default AdminAddService