// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactbox-1{
    background-color: chartreuse; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width:90%; margin:50px auto; border-radius: 8px; padding:15px; }
.contactbox-2{
    background-color: aqua; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width:90%;  margin: auto; border-radius: 8px; padding:15px; }`, "",{"version":3,"sources":["webpack://./src/routes/Contact/contact.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B,EAAE,4CAA4C;IAC1E,SAAS,EAAE,gBAAgB,EAAE,kBAAkB,EAAE,YAAY,EAAE;AACnE;IACI,sBAAsB,EAAE,4CAA4C;IACpE,SAAS,GAAG,YAAY,EAAE,kBAAkB,EAAE,YAAY,EAAE","sourcesContent":[".contactbox-1{\r\n    background-color: chartreuse; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\r\n    width:90%; margin:50px auto; border-radius: 8px; padding:15px; }\r\n.contactbox-2{\r\n    background-color: aqua; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\r\n    width:90%;  margin: auto; border-radius: 8px; padding:15px; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
