import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './v2Admin.css';
import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import AdminNav from './v2AdminNav';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AllStates } from '../../context/Context';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { Type } from 'react-bootstrap-table2-editor';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from "react-bootstrap/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { DatePicker } from "react-rainbow-components";
import { TimePicker } from "react-rainbow-components";

const initTime   = "12:00";
const initDate   = "2024-01-12T17:00:00.000Z";
const locale = { name: 'id', label: 'Indonesian' };

var namaProvinsi  = [];
var kodeProvinsi  = [];
var currEditArray = [];
var currEditUser  = {};
var currEditKey   = '';
var currEditIndex = -1;
var paramKhusus   = ''; 
var param00label  = [];  var usep01 = false;
var autoParam01   = [];  var usep02 = false;
var autoParam02   = [];  var usep03 = false;
var autoParam03   = [];  var usep04 = false;
var autoParam04   = [];  var usep05 = false;
var autoParam05   = [];  var usep06 = false;
var autoParam06   = [];  var usep07 = false;
var autoParam07   = [];  var usep08 = false;
var autoParam08   = [];  var usep09 = false;
var autoParam09   = [];  var usep10 = false;

const AdminUsers = () => {

    const navigate = useNavigate()
    const { users, propinsi, url } = AllStates();
    const [fileImage, setFileImage] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [input00, setInput00] = useState();
    const [input01, setInput01] = useState();
    const [input02, setInput02] = useState();
    const [input03, setInput03] = useState();
    const [input04, setInput04] = useState();
    const [input05, setInput05] = useState();
    const [input06, setInput06] = useState();
    const [input07, setInput07] = useState();
    const [input08, setInput08] = useState();
    const [input09, setInput09] = useState();
    const [input10, setInput10] = useState();
    const [editableDialog, setEditableDialog] = useState(false);
    const [disableBtnUpload, setDisableBtnUpload] = useState(true);
    function handleCheckBox(e) { setEditableDialog(e.target.checked) }
    function gotoAddUser() { navigate('/admin/add/user') };
    function gotoUpdateUser() { navigate('/admin/update/user')};
    const { SearchBar } = Search;
    let saringNama; let saringHp; let saringEmail; let saringRole;
    let fakeAlmt = ["--pilih--"];  let fakeMtr = ["--pilih--"];  let fakeSrvs = ["--pilih--"];
    if (propinsi) { 
        kodeProvinsi = Object.keys(propinsi);
        namaProvinsi = Object.values(propinsi);
        //console.log(kodeProvinsi[namaProvinsi.indexOf('DKI Jakarta')]);
    };

    function clearAllFilter() { saringNama(""); saringHp(""); saringEmail(""); saringRole(""); };
    const ClearButton = props => {
        const handleClick = () => { props.onSearch(""); props.clearAllFilter(); }; return (
          <Button variant="secondary" onClick={handleClick} style={{fontSize:"16px",padding:"5px",margin:"10px",height:"40px"}}>Clear</Button>
        );
      };
    const MyExportCSV = (props) => {
        const handleClick = () => { props.onExport(); };
        return (
            <div>
                <button className="button button1" onClick={ handleClick } style={{ margin:"20px" }} >
                Export ke file CSV</button> 
            </div>
        );
    };

    const datePick = { type: Type.DATE };
    const editorUsers =  {
        type: Type.SELECT,
        options: [{ value: 'user', label: 'user' }, 
            { value: 'mekanik', label: 'mekanik' }, 
            { value: 'manager', label: 'manager' }, 
            { value: 'partner', label: 'partner' }, 
            { value: 'admin', label: 'admin' }]
    };

    const editorAlamat =  {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
            resetAutoParam();
            currEditKey = 'alamat';
            console.dir(`current editing row: ${row._id}`);
            console.log(`current editing column: ${column.dataField}`);
            let nAlamat =[{ value:"--pilih--", label:"--pilih--"}]; 
            users.map( (user) => {
                if (user._id === row._id) {
                    if (user.alamat) {
                        user.alamat.map( (i) => nAlamat.push({ value:i.id, label:i.id }) ); };
                    };
                });
            nAlamat.push({ walue:"tambah baru", label:"tambah baru"});
            autoParam01 = nAlamat;
            autoParam08 = namaProvinsi;
            setEditableDialog(false);
            return nAlamat;
        }
    };
    const editorMotor =  {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
            resetAutoParam();
            currEditKey = 'motor';
            console.dir(`current editing row id: ${row._id}`);
            console.log(`current editing column: ${column.dataField}`);
            let nMotor =[{ value:"--pilih--", label:"--pilih--"}]; 
            users.map( (user) => {
                if (user._id === row._id) {
                    if (user.motor) {
                        user.motor.map( (i) => nMotor.push({ value:i.nopol, label:i.nopol }) );};
                    };
                }); 
            nMotor.push({ walue:"tambah baru", label:"tambah baru"});
            autoParam02 = nMotor;
            autoParam06 = ['manual', 'matic'];
            setEditableDialog(false);
            return nMotor;
        }
    };
    const editorServis =  {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
            resetAutoParam();
            currEditKey = 'servis';
            console.log(`current editing row id: ${row._id}`);
            console.log(`current editing column: ${column.dataField}`);
            let nServis = [{ value:"--pilih--", label:"--pilih--"}]; 
            let nMotor = []; let nAlamat = []; let nAlamat1 = []; let nKota = [];
            users.map( (user) => {
                if (user._id === row._id) {
                    if (user.servis) {
                        user.servis.map( (i) => nServis.push({value:i, label:i}) );
                        if (user.motor) {
                            user.motor.map( (i) => nMotor.push({ value:i.nopol, label:i.nopol }) );};
                        if (user.alamat) {
                            user.alamat.map( (i) => {
                                nAlamat.push({ value:i.id, label:i.id });
                                nAlamat1.push({ value:i.alamat1, label:i.alamat1 });
                                nKota.push({ value:i.kotakab, label:i.kotakab });
                            }); }
                        };
                    };
                }) ;
            nServis.push({walue:"tambah baru", label:"tambah baru"});
            autoParam01 = nMotor;
            autoParam02 = nAlamat;
            autoParam03 = nAlamat1;
            autoParam04 = nKota;
            paramKhusus = 'rencana';
            setEditableDialog(false);
            return nServis;
        }
    };
    const editorProfil =  {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
            resetAutoParam();
            currEditKey = 'photoURL';
            console.log(`current editing row id: ${row._id}`);
            console.log(`current editing column: ${column.dataField}`);
            let nProfil = [{ value:"Edit Profil", label:"Edit Profil" }]; 
            autoParam04 = ['user', 'mekanik', 'manager', 'partner', 'admin'];
            autoParam06 = ['laki-laki', 'perempuan'];
            autoParam07 = ['menikah', 'janda/duda', 'single'];
            autoParam08 = ['karyawan', 'wirausaha', 'PNS', 'TNI/Polri', 'lainnya'];
            paramKhusus = '';
            setEditableDialog(false);
            return nProfil;
        }
    };

    function formatDate(cell) {
        let dateObj = cell; if (typeof cell !== 'object') { dateObj = new Date(cell); }
        return `${('0' + dateObj.getUTCDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${dateObj.getUTCFullYear()}`;
    };

    function imgFormatter(cell, row) { return (<span><img src={cell} alt='foto' height='40px' /></span>)}
    const defaultSorted = [{ dataField: 'userName', order: 'desc' }];
    function closePopUp() { setShowPopup(false); };

    let kolom = [{dataField:'_id',text:'_Id',hidden:true}, 
        {dataField: 'userName', text:'Nama', sort: true, style:{Width:"10%",}, editable:true, type:"string",
            filter: textFilter({ getFilter: filter => { saringNama = filter; } }) },
        {dataField: 'mobile', text:'No Hp', sort: true, style:{Width:"10%",}, editable:true, type:"string",
            filter: textFilter({ getFilter: filter => { saringHp = filter; } }) },
        {dataField: 'email', text:'Email', sort: true, editable: false, style:{Width:"10%",},
            filter: textFilter({ getFilter: filter => { saringEmail = filter; } }) },
        {dataField: 'role', text:'Role', sort: true, editor: editorUsers, style:{Width:"5%",}, editable:true, type:"string",
            filter: textFilter({ getFilter: filter => { saringRole = filter; } }) },
        {dataField: 'periodeSIM', text:'Periode SIM', sort: true, style:{Width:"10%",},
            formatter: (cell) => formatDate(cell), editor: datePick },
        {dataField: 'photoURL', text:'Photo', editable: true, editor: editorProfil, formatter: imgFormatter, style:{Width:"15%",}, },
        {dataField: 'fakeAlamat', text: 'Alamat', editable: true, editor: editorAlamat, style:{Width:"10%",}, },
        {dataField: 'fakeMotor', text: 'Motor', editable: true, editor: editorMotor, style:{Width:"10%",}, },
        {dataField: 'fakeServis', text: 'Rencana', editable: true, editor: editorServis, style:{Width:"10%",}, },
    ];

    if (users) { users.map( (user, i) => { 
        if (user.alamat.length > 0) { fakeAlmt.push("--pilih--")} else ( fakeAlmt.push("belum ada") );
        if (user.motor.length > 0) { fakeMtr.push("--pilih--") } else ( fakeMtr.push("belum ada") );
        if (user.servis.length > 0) { fakeSrvs.push("--pilih--"); } else ( fakeSrvs.push("belum ada") );
    })};

    var baris = [];
    if (users) { users.map( (user, i) => { baris.push({
        _id: user._id,
        userName: user.userName,
        mobile: user.mobile,
        email: user.email,
        role: user.role,
        periodeSIM: user.periodeSIM,
        photoURL: user.photoURL,
        fakeAlamat: fakeAlmt[i],
        fakeMotor: fakeMtr[i],
        fakeServis: fakeSrvs[i+1],
    })})};

    function resetAutoParam() {
        paramKhusus  = ''; param00label = [];
        autoParam01  = []; autoParam02  = [];
        autoParam03  = []; autoParam04  = [];
        autoParam05  = []; autoParam06  = [];
        autoParam07  = []; autoParam08  = [];
        autoParam09  = [];
    };

    function resetParam() {
        currEditArray = []; usep01 = false;
        usep02 = false; usep03 = false;
        usep04 = false; usep05 = false;
        usep06 = false; usep07 = false;
        usep08 = false; usep09 = false;
        setInput00(''); setInput01('');
        setInput02(''); setInput03('');
        setInput04(''); setInput05('');
        setInput06(''); setInput07('');
        setInput08(''); setInput09('');
        setInput10('');        
    };

    function handleAfterSave(oldValue, newValue, row, column) {
        console.log("After Save - column.dataField = ", column.dataField, " new val = ", newValue);
        if ((oldValue === newValue) || (newValue === "--pilih--")){ console.log("Skip updating") } 
        else {
            users.map( (x) => { if (x._id === row._id) {currEditUser = x}});
            switch (column.dataField) {
                case 'fakeAlamat' : 
                    console.log("newValue= ",newValue);
                    currEditKey = 'alamat';
                    currEditArray = currEditUser.alamat;
                    console.log("currEditArray",currEditArray);
                    param00label = ["Foto", "Label Alamat", "Alamat1", "Alamat2", "Kelurahan", "Periode SIM",
                        "Kecamatan", "Kota/Kabupaten", "Propinsi", "Kode Pos", "", "", "", "", ""];
                    
                    if (newValue === "tambah baru") { 
                        currEditIndex = -1; 
                        resetParam(); 
                        setInput05(initDate);
                        paramKhusus='baru'; 
                        setEditableDialog(true);
                        usep01 = false; usep10 = true;
                    } 
                    else if ( currEditUser.alamat.length > 0 ) { 
                        console.log("currEditUser= ",currEditUser);
                        { currEditUser.alamat.map((u,i)=> {
                        if(u.id === newValue) { currEditIndex = i }}); };
                        console.log("currEditIndex= ",currEditIndex);
                        setInput00(currEditArray[currEditIndex].alamatURL);
                        setInput01(currEditArray[currEditIndex].id);
                        setInput02(currEditArray[currEditIndex].alamat1);
                        setInput03(currEditArray[currEditIndex].alamat2);
                        setInput04(currEditArray[currEditIndex].kelurahan);
                        setInput05(currEditUser.periodeSIM);
                        setInput06(currEditArray[currEditIndex].kecamatan);
                        setInput07(currEditArray[currEditIndex].kotakab);
                        setInput08(currEditArray[currEditIndex].propinsi);
                        setInput09(currEditArray[currEditIndex].kodepos);
                        setInput10(initTime);
                        usep01 = true; usep10 = true;
                    };
                    setShowPopup(true);
                    break;
                case 'fakeMotor' : 
                    console.log("newValue= ",newValue);
                    currEditKey = 'motor';
                    currEditArray = currEditUser.motor;
                    console.log("currEditArray",currEditArray);
                    param00label = ["Foto", "id Motor", "No Pol", "Merek", "Tipe/Model", "Periode STNK",
                    "Transmisi", "cc", "Tahun", "Odometer", "", "", "", "", ""];
                    if (newValue === "tambah baru") { 
                        currEditIndex = -1; 
                        resetParam(); 
                        setInput05(initDate);
                        paramKhusus='baru'; 
                        setEditableDialog(true);
                        usep01 = true; usep10 = true;
                    } 
                    else if ( currEditUser.motor.length > 0 ) {  
                        console.log("currEditUser= ",currEditUser);
                        { currEditUser.motor.map((u,i)=> {
                        if(u.nopol === newValue) { currEditIndex = i }; }); };
                        console.log("currEditIndex= ",currEditIndex);
                        setInput00(currEditArray[currEditIndex].motorURL);
                        setInput01(currEditArray[currEditIndex].id);
                        setInput02(currEditArray[currEditIndex].nopol);
                        setInput03(currEditArray[currEditIndex].bikeCompany);
                        setInput04(currEditArray[currEditIndex].model);
                        setInput05(currEditArray[currEditIndex].periodeSTNK);
                        setInput06(currEditArray[currEditIndex].transmisi);
                        setInput07(currEditArray[currEditIndex].cc);
                        setInput08(currEditArray[currEditIndex].tahun);
                        setInput09(currEditArray[currEditIndex].odometer);
                        setInput10(initTime);
                        usep01 = true; usep10 = true;
                    };
                    setShowPopup(true); 
                    break;
                case 'fakeServis' : 
                    console.log("newValue= ",newValue);
                    currEditKey = 'servis';
                    currEditArray = currEditUser.servis;
                    console.log("currEditArray",currEditArray);
                    param00label = ["Foto", "Untuk Motor", "Tempat", "Alamat", "Kota", "Tgl Servis",  
                                    "Ganti Oli", "Tune Up", "Servis Lain", "Harga Perkiraan", "Jam Servis",, "", "", "", "" ];
                    if (newValue === "tambah baru") { 
                        currEditIndex = -1; 
                        resetParam(); 
                        setInput05(initDate);
                        setInput10(initTime);
                        paramKhusus='baru rencana'; 
                        setEditableDialog(true);
                        usep01 = false;
                    } 
                    else if ( currEditUser.servis.length > 0 ) { 
                        console.log("currEditUser= ",currEditUser);
                        { currEditUser.servis.map((u,i)=> {
                        if(u.id === newValue) { currEditIndex = i }; }); };
                        console.log("currEditIndex= ",currEditIndex);
                        setInput00(currEditArray[currEditIndex].servisURL);
                        setInput01(currEditArray[currEditIndex].id);
                        setInput02(currEditArray[currEditIndex].alamatId);
                        setInput03(currEditArray[currEditIndex].alamat1);
                        setInput04(currEditArray[currEditIndex].kotakab);
                        setInput05(currEditArray[currEditIndex].serviceDate);  
                        setInput06(currEditArray[currEditIndex].gantiOli);
                        setInput07(currEditArray[currEditIndex].tuneUp);
                        setInput08(currEditArray[currEditIndex].lain_lain);
                        setInput09(currEditArray[currEditIndex].price);
                        setInput10(currEditArray[currEditIndex].serviceTime);        
                        usep01 = true;     
                    };
                    setShowPopup(true);
                    break;
                case 'photoURL' : 
                    console.log("newValue= ",newValue);
                    currEditKey = 'profile';
                    currEditArray = [];
                    console.log("currEditArray",currEditArray);
                    param00label = ["Foto", "Email", "Nama", "No Hp", "Role", "Periode SIM",  
                                    "Jenis Kelamin", "Status", "Pekerjaan", "Kantor", "",, "", "", "", "" ];
                    resetParam(); 
                    setInput10(initTime);
                    paramKhusus=''; 
                    setEditableDialog(false);
                    usep01 = true; usep10 = true;

                    setInput00(currEditUser.photoURL);
                    setInput01(currEditUser.email);
                    setInput02(currEditUser.userName);
                    setInput03(currEditUser.mobile);
                    setInput04(currEditUser.role);
                    setInput05(currEditUser.periodeSIM);  
                    setInput06(currEditUser.gender);
                    setInput07(currEditUser.status);
                    setInput08(currEditUser.pekerjaan);
                    setInput09(currEditUser.kantor);              

                    setShowPopup(true);
                    break;
                default : 
                    console.log("row = ", row);
                    updateUser(column.dataField, row); 
                    break;
            }    
        }
    };

    function handleFileChange(e) {
        setFileImage(e.target.files[0]);
        setDisableBtnUpload(false);  
    };
    async function updatePic() {
        if (!currEditKey || currEditKey === 'profile') { currEditKey = 'photoURL' };
        console.log("currEditKey", currEditKey);
        const formData = new FormData()
        formData.append('file', fileImage)
        try {
          await axios.post(`${url}/image/update/picture/${currEditKey}`, formData)
          .then(res => { 
            setInput00(res.data.keyURL);
            setDisableBtnUpload(true);
        })
          .catch(error => toast.error(error.response.data.message));    } 
        catch (error) { toast.error(error.response.data.message) };  
    };

    function simpanData() {
        setShowPopup(false);
        console.log("currEditKey= ",currEditKey);
        let newJson = {};
        switch (currEditKey) {
            case 'alamat' :
                if (currEditIndex == -1) { 
                    newJson = {
                        alamatURL: input00, id: input01, alamat1: input02, alamat2: input03, kelurahan: input04,
                        kecamatan: input06, kotakab: input07, propinsi: input08, kodepos: input09, periodeSIM: input05
                    };
                    currEditUser.alamat.push(newJson); 
                }
                else { 
                    currEditArray[currEditIndex].alamatURL = input00;
                    currEditArray[currEditIndex].id = input01;
                    currEditArray[currEditIndex].alamat1 = input02;
                    currEditArray[currEditIndex].alamat2 = input03;
                    currEditArray[currEditIndex].kelurahan = input04;
                    currEditArray[currEditIndex].periodeSIM = input05;
                    currEditArray[currEditIndex].kecamatan = input06;
                    currEditArray[currEditIndex].kotakab = input07;
                    currEditArray[currEditIndex].propinsi = input08;
                    currEditArray[currEditIndex].kodepos = input09; 
                    currEditUser.alamat = currEditArray;
                };
                break;
            case 'motor' :
                setInput01(input02.replace(/ /g, '').toLoweCase());
                if (currEditIndex == -1) { 
                    newJson = {
                        motorURL: input00, id: input01, nopol: input02, bikeCompany: input03, model: input04,
                        transmisi: input06, cc: input07, tahun: input08, odometer: input09, periodeSTNK: input05
                    };
                    currEditUser.servis.push(newJson); 
                }
                else { 
                    currEditArray[currEditIndex].motorURL = input00;
                    currEditArray[currEditIndex].id = input01;
                    currEditArray[currEditIndex].nopol = input02;
                    currEditArray[currEditIndex].bikeCompany = input03;
                    currEditArray[currEditIndex].model = input04;
                    currEditArray[currEditIndex].periodeSTNK = input05;
                    currEditArray[currEditIndex].transmisi = input06;
                    currEditArray[currEditIndex].cc = input07;
                    currEditArray[currEditIndex].tahun = input08;
                    currEditArray[currEditIndex].odometer = input09;
                    currEditUser.alamat = currEditArray;
                 };
                break;
            case 'servis' :
                if (currEditIndex == -1) { 
                    newJson = {
                        servisURL: input00, id: input01, alamatId: input02, alamat1: input03, kotakab: input04, serviceDate: input05, 
                        gantiOli: input06, tuneUp: input07, lain_lain: input08, price: input09, serviceTime: input10
                    };
                    currEditUser.servis.push(newJson); 
                }
                else { 
                    currEditArray[currEditIndex].servisURL = input00;
                    currEditArray[currEditIndex].id = input01;
                    currEditArray[currEditIndex].alamatId = input02;
                    currEditArray[currEditIndex].alamat1 = input03;
                    currEditArray[currEditIndex].kotakab = input04;
                    currEditArray[currEditIndex].serviceDate = input05;
                    currEditArray[currEditIndex].gantiOli = input06;
                    currEditArray[currEditIndex].tuneUp = input07;
                    currEditArray[currEditIndex].lain_lain = input08;
                    currEditArray[currEditIndex].price = input09;
                    currEditArray[currEditIndex].serviceTime = input10;
                    currEditUser.alamat = currEditArray;
                };
                break;
            default :
                currEditUser.photoURL = input00;
                currEditUser.email = input01;
                currEditUser.userName = input02;
                currEditUser.mobile = input03;
                currEditUser.role = input04;
                currEditUser.periodeSIM = input05;
                currEditUser.gender = input06;
                currEditUser.status = input07;
                currEditUser.pekerjaan = input08;
                currEditUser.kantor = input09;
                break;
        }
        console.log("newJson = ", newJson);
        console.log("currEditArray = ", currEditArray);
        console.log("currEditUser = ", currEditUser);
        updateUser(currEditKey, currEditUser);
    };

    async function updateUser(key, upData) {
        try {
            await axios.post(`${url}/users/update/userinfo/${key}`, upData)
            .then( res => { currEditUser =  res.data.userdata; console.log("Updated user = ", currEditUser);})
            .catch(error => toast.error(error.response.data.message));
          }
          catch (error) { toast.error(error.response.data.message) };
    };

    function formatDateData(value) { console.log("date = ", value); setInput05(value); }
    function formatTimeData(value) { console.log("time = ", value); setInput10(value); }
    const CaptionElement = () => <p style={{ textAlign: 'center', color: 'purple' }}>Klik 2x untuk edit Nama, Hp, Role dan Periode SIM</p>;

    useEffect(() => { if(JSON.parse(localStorage.getItem('user')).role !== 'admin'){ navigate('/') } }, []);

    return (
        <div >
            <AdminNav />
            <div className="row-for-two-cols">
            <section className="container-for-two-cols">
                <div className="left-half">
                    <h3 style={{margin:'10px', textAlign:'left'}}>Daftar Semua User</h3>
                </div> 
                <div className="right-half">
                    <button className="button button1" onClick={gotoAddUser}>Tambah User</button>
                </div>
            </section>
            </div> 
            <div className="my-3 col-12 col-lg-12 d-flex flex-lg-column">
                <ToolkitProvider bootstrap4 keyField="_id" data={baris} columns={kolom} search color='#c8e6c9'
                    exportCSV={{ fileName: "GnG_DaftarUsers.csv" }} >
                    { props => (
                        <div>
                            <SearchBar { ...props.searchProps } style={{ width:"400px", height:"40px", margin:'0px' }} />
                            <ClearButton { ...props.searchProps } clearAllFilter={clearAllFilter} />
                            <hr />
                            <BootstrapTable {...props.baseProps} bootstrap4 keyField='_id' data={baris} columns={kolom} 
                                defaultSorted={defaultSorted} filter={filterFactory()} striped condensed hover
                                cellEdit={ cellEditFactory({ mode:'dbclick', blurToSave:true,
                                afterSaveCell: (oldValue, newValue, row, column) => { handleAfterSave(oldValue, newValue, row, column); } 
                            })  }
                                caption={<CaptionElement headerClasses="header-class" />} />
                            <MyExportCSV  { ...props.csvProps } >Export ke file CSV</MyExportCSV >
                        </div>
                        )
                    }
                </ToolkitProvider>
            </div>
            <Footer />
            <React.Fragment>
                <Dialog fullWidth={true} maxWidth={'xl'} open={showPopup} onClose={closePopUp} scroll={'paper'}
                    aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" >
                <DialogTitle id="scroll-dialog-title">Data {paramKhusus} {currEditKey} {input01}</DialogTitle>
                <DialogContent dividers={true}>
                    <Box noValidate component="form"  autoComplete="off" sx={{ display:'flex', flexDirection:'column', m:'auto', 
                        width:'100%', height:'40vh', border:'solid', borderRadius:'8px', marginBottom:'10px',
                        paddingLeft:'10px', paddingRight:'10px' }}>
                        <div className='options row w-100' >
                        <div className='first-service col-12 col-md-4 flex-fill' >
                            <img src={input00} alt="gambar" />
                        </div><div className='second-service col-12 col-md-4 flex-fill' >
                            <Autocomplete id="controlled-1" fullWidth options={autoParam01} renderInput={(params) => 
                                <TextField {...params} className="mb-1 mt-1" label={param00label[1]} variant="outlined" fullWidth />}
                                 readOnly={usep01} inputValue={input01} onInputChange={(e,v) => {setInput01(v)}} freeSolo />
                            <Autocomplete id="controlled-2" fullWidth options={autoParam02} renderInput={(params) => 
                                <TextField {...params} className="mb-1 mt-1" label={param00label[2]} variant="outlined" fullWidth />} 
                                readOnly={!editableDialog} inputValue={input02} onInputChange={(e,v) => {setInput02(v)}} freeSolo />
                            <Autocomplete id="controlled-3" fullWidth options={autoParam03} renderInput={(params) => 
                                <TextField {...params} className="mb-1 mt-1" label={param00label[3]} variant="outlined" fullWidth />} 
                                readOnly={!editableDialog} inputValue={input03} onInputChange={(e,v) => {setInput03(v)}} freeSolo />
                            <Autocomplete id="controlled-4" fullWidth options={autoParam04} renderInput={(params) => 
                                <TextField {...params} className="mb-1 mt-1" label={param00label[4]} variant="outlined" fullWidth />} 
                                readOnly={!editableDialog} inputValue={input04} onInputChange={(e,v) => {setInput04(v)}} freeSolo />
                            <DatePicker id="controlled-5" value={input05} onChange={formatDateData} label={param00label[5]} locale={locale.name}
                                formatStyle="large" readOnly={!editableDialog} borderRadius="semi-square" placeholder={param00label[5]} />
                        </div><div className='third-service col-12 col-md-4 flex-fill'>
                            <Autocomplete id="controlled-6" fullWidth options={autoParam06} renderInput={(params) => 
                                <TextField {...params} className="mb-1 mt-1" label={param00label[6]} variant="outlined" fullWidth />} 
                                readOnly={!editableDialog} inputValue={input06} onInputChange={(e,v) => {setInput06(v)}} freeSolo />
                            <Autocomplete id="controlled-7" fullWidth options={autoParam07} renderInput={(params) => 
                                <TextField {...params} className="mb-1 mt-1" label={param00label[7]} variant="outlined" fullWidth />} 
                                readOnly={!editableDialog} inputValue={input07} onInputChange={(e,v) => {setInput07(v)}} freeSolo />
                            <Autocomplete id="controlled-8" fullWidth options={autoParam08} renderInput={(params) => 
                                <TextField {...params} className="mb-1 mt-1" label={param00label[8]} variant="outlined" fullWidth />} 
                                readOnly={!editableDialog} inputValue={input08} onInputChange={(e,v) => {setInput08(v)}} freeSolo />
                            <Autocomplete id="controlled-9" fullWidth options={autoParam09} renderInput={(params) => 
                                <TextField {...params} className="mb-1 mt-1" label={param00label[9]} variant="outlined" fullWidth />} 
                                readOnly={!editableDialog} inputValue={input09} onInputChange={(e,v) => {setInput09(v)}} freeSolo />
                            <TimePicker id="controlled-10" value={input10} onChange={formatTimeData} label={param00label[10]} hour24 disabled={usep10}
                                formatStyle="large" readOnly={!editableDialog} borderRadius="semi-square" placeholder={param00label[10]}  />
                        </div></div>
                    </Box>
                </DialogContent>
                <DialogActions> 
                    <FormControlLabel value="end" onChange={handleCheckBox} control={
                        <Checkbox inputProps={{ 'aria-label': 'controlled' }} checked={editableDialog}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}  label={`Edit data ${paramKhusus} ${currEditKey} ${input01}`} />} 
                        label={`Edit data ${paramKhusus} ${currEditKey} ${input01}`} labelPlacement="end" />
                    <button onClick={updatePic} className='btn btn-success m-2' disabled={disableBtnUpload}> 
                    Upload Foto  </button>
                    <input type='file' onChange={handleFileChange} /> <br />
                    <Button disabled={!editableDialog} onClick={simpanData}>Simpan data {paramKhusus} {currEditKey} {input01} </Button>
                    <Button onClick={closePopUp}>Selesai</Button> 
                </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}

export default AdminUsers