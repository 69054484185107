// services list
import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer/Footer'
import '../admin/Admin.css'
import Table from 'react-bootstrap/Table';
import Adminnav from './Adminnav'
import { useNavigate } from 'react-router-dom'
import { AllStates } from '../../context/Context';
import Loading from '../../components/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSort } from '@fortawesome/free-solid-svg-icons';

const AdminServices = () => {
    const navigate = useNavigate()
    const{services} = AllStates();
    var serial_number = 0; // serial number for table
    const [psearch, setPsearch] = useState('');
    const [sortCriteria, setSortCriteria] = useState('_id');
    const [isDescending, setIsDescending] = useState(true);
    function gotoAddService() { navigate('/admin/add/service') };
    function gotoUpdateHarga() { navigate('/admin/update/price')};

    async function handleRowUpdate(e, data) {
        e.persist();
        localStorage.setItem('serviceData', JSON.stringify(data) );
        console.log("Service Data to be updated = ", localStorage.getItem('serviceData'));
        gotoUpdateHarga();
    }

    useEffect(() => {        
        if(JSON.parse(localStorage.getItem('user')).role !== 'admin'){ navigate('/') }  
    }, [])

    return (
        <div >
            <Adminnav />
            <div> <h3 style={{margin:'10px'}}>Daftar Servis dan Harga</h3> </div>
            <div className="row-for-two-cols">
            <section className="container-for-two-cols">
                <div className="left-half">
                    <input type='search' placeholder='Cari...' value={psearch} onChange={e => setPsearch(e.target.value)} /> 
                </div> 
                <div className="right-half">
                    <button className="button button1" onClick={gotoAddService}>Tambah Servis</button>
                </div>
            </section>
            </div> 
            <div>   
                {/* services list */}
                { services ? (  <Table striped className='mb-3 mt-3' variant='info' hover responsive>
                    <thead >
                        <tr>
                            <th className={"w-3"}>No</th>
                            <th  className={"w-20"}><button key='serviceType' className='button-transparent'  
                                onClick={() => { setSortCriteria('serviceType'); setIsDescending(!isDescending); }}>
                                Nama Servis  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                            <th className={"w-12"}>Gambar</th>
                            <th className={"w-10"}><button key='serviceCategory' className='button-transparent' 
                                onClick={() => { setSortCriteria('serviceCategory'); setIsDescending(!isDescending); }}>
                                Kategori  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                            <th className={"w-10"}><button key='price' className='button-transparent' 
                                onClick={() => { setSortCriteria('price'); setIsDescending(!isDescending); }}>
                                Harga  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                            <th className={"w-10"}><button key='bikeCompany' className='button-transparent' 
                                onClick={() => { setSortCriteria('bikeCompany'); setIsDescending(!isDescending); }}>
                                Motor  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                            <th className={"w-5"}><button key='transmisi' className='button-transparent' 
                                onClick={() => { setSortCriteria('transmisi'); setIsDescending(!isDescending); }}>
                                Transmisi  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                            <th className={"w-10"}><button key='cc' className='button-transparent' 
                                onClick={() => { setSortCriteria('cc'); setIsDescending(!isDescending); }}>
                                cc  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                            <th className={"w-10"}><button key='model' className='button-transparent' 
                                onClick={() => { setSortCriteria('model'); setIsDescending(!isDescending); }}>
                                Tipe/Model  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                            <th className={"w-10"}>Command</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* sort date */}
                        {services && services.sort(function (a, b) {
                            if (isDescending) {
                                if ( a[sortCriteria] < b[sortCriteria] ) return 1;
                                if ( a[sortCriteria] > b[sortCriteria] ) return -1;
                            } else {
                                if ( a[sortCriteria] < b[sortCriteria] ) return -1;
                                if ( a[sortCriteria] > b[sortCriteria] ) return 1;
                            }                           
                            return 0;
                    })
                        .filter(item => {
                            if (psearch === '') { return item } 
                            else if (item.bikeCompany.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                            else if (item.transmisi.toLowerCase().includes(psearch.toLowerCase())) { return item }
                            else if (item.model.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                            else if (item.cc.toLowerCase().includes(psearch.toLowerCase())) { return item }
                            else if (item.serviceType.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                            else if (item.serviceCategory.toLowerCase().includes(psearch.toLowerCase())) { return item }
                            else if (item.price.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                        }).map((item, index) => {
                                return (
                                    <tr key={item._id} >
                                        <td className="text-center"> {serial_number = serial_number + 1}</td>
                                        <td>{item.serviceType}</td>
                                        <td><img src={item.serviceURL} alt='gambar' height='40px' /></td>
                                        <td className="text-center">{item.serviceCategory}</td>
                                        <td className="text-right">{item.price}</td>
                                        <td className="text-center">{item.bikeCompany}</td>
                                        <td className="text-center">{item.transmisi}</td>
                                        <td className="text-center">{item.cc}</td>
                                        <td className="text-center">{item.model}</td>
                                        <td className="text-center"><button key={item._id} className='button-transparent' 
                                            onClick={(e) => handleRowUpdate(e, item)}>Edit 
                                            <span><FontAwesomeIcon icon={faPen} /></span> </button> </td>
                                    </tr>
                                )
                        })}
                    </tbody>
                </Table> ) : <Loading/> }
            </div>
            <Footer />
        </div>
    )
}

export default AdminServices