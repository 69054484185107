import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import dayjs from 'dayjs'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './bigCalendar.css';

const localizer = dayjsLocalizer(dayjs)
const dummyEvents = [
    {
      allDay: false,
      selesai: new Date('January 1, 2024 12:00:00'),
      mulai: new Date('January 1, 2024 10:00:00'),
      title: 'Servis Motor di Bekasi',
    },
    {
      allDay: false,
      selesai: new Date('January 2, 2024 17:00:00'),
      mulai: new Date('January 2, 2024 15:00:00'),
      title: 'Servis Motor di Bogor',
    },
    {
      allDay: false,
      selesai: new Date('December 29, 2023 10:00:00'),
      mulai: new Date('December 29, 2023 08:00:00'),
      title: 'Servis Motor di Jakarta',
    },
    {
      allDay: false,
      selesai: new Date('December 30, 2024 11:00:00'),
      mulai: new Date('December 30, 2024 09:00:00'),
      title: 'Servis Motor di Bintaro',
    },
  ];

const JadwalServis = (props) => (
  <div>
    <Calendar
      localizer={localizer}
      events={dummyEvents}
      startAccessor="mulai"
      endAccessor="selesai"
      style={{ height: 500 }}
    />
  </div>
)

export default JadwalServis