import React from 'react'
import { useCallback, useRef } from "react";
import '../homepage/Homepage.css'
import Carousell from '../../components/Carousel/Carousel'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from "../../components/Navbar/Nav"
import { MDBContainer } from "mdb-react-ui-kit";
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import PerawatanRutinImage from './images/perawatan_rutin.jpg';
import GeneralImage from './images/general.jpg';
import RepairImage from './images/repair.jpeg';
import ImageKiri from './images/kiri.png';
//import ImageKanan from './images/kanan.png';

const Homepage = () => {
  const url =  "https://gngmotorservice.com"
  const videoRef = useRef(null);
  var storageSupport = false; var user = {};
  try { storageSupport = (window.localStorage && true); }
  catch(e) {}

  if (storageSupport) { user = localStorage.getItem('user'); }
  const navigate = useNavigate();
  function gotoPerawatanRutin() { user ? navigate('/perawatan_rutin') : alert('Silahkan Login dulu') }
  function gotoGeneral() { user ? navigate('/service') : alert('Silahkan Login dulu') }
  function gotoRepair() { user ? navigate('/repair') : alert('Silahkan Login dulu') }
  function gotoTesCalendar() { navigate('/testingCalendar')};
  const handleMouseEnter = useCallback(() => { videoRef.current.play(); videoRef.current.muted = false }, []);
  const handleMouseLeave = useCallback(() => { videoRef.current.pause(); videoRef.current.muted = true}, []);

  return (
    <div className='homepage'>
      <Nav /><div>  <Carousell /></div>
      <div><br></br></div>
      <div className='options row'>
      <div className='first-service col-12 col-md-4'>
        <Card className="flex-fill customCard1" key="blog_1a">
        <MDBContainer><video onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
          ref={videoRef} src="https://gngmotorservice.com/videos/blog/blog_3.mp4"
          type="video/mp4" loop className="w-100" muted></video></MDBContainer>
        <Card.Body>
          <Card.Title>FYI</Card.Title>
          <Card.Text>
            Gaess .. Cek this out !
          </Card.Text>
          <Button variant="primary" className='btcard'>Selengkapnya</Button>
        </Card.Body>
        </Card>
        </div><div className='second-service col-12 col-md-4'>
        <Card className="flex-fill customCard2" key="blog_1b">
        <Card.Img variant="top" src={url+"/images/blog/blok_1b.jpg"} />
        <Card.Body>
          <Card.Title>GnG Soft Launching</Card.Title>
          <Card.Text>
            Di awal tahun baru 2024 ini, soft launching GnG motor service
            untuk melayani perawatan rutin motor anda untuk wilayah Jabodetabek.
          </Card.Text>
          <Button variant="primary" className='btcard'>Selengkapnya</Button>
        </Card.Body>
        </Card>
        </div><div className='third-service col-12 col-md-4'>
        <Card className="flex-fill customCard3" key="blog_2">
          <Card.Img variant="top" src={url+"/images/blog/blog_1a.jpg"} />
        <Card.Body>
          <Card.Title>GnG Motor Service</Card.Title>
          <Card.Text>
            Siap melayani anda
          </Card.Text>
          <Button variant="primary" className='btcard'>Selengkapnya</Button>
        </Card.Body>
        </Card>
      </div>
      </div>

      <div className='options row'>
        {/* perawatan rutin service */}
        <div className='first-service col-11 col-md-4'>
          <div><p className='service-name'>SERVIS RINGAN / RUTIN</p></div>
          <div className='service-picture col-10'><img src={PerawatanRutinImage} alt='first' /></div>
          <div><button className='btn btn-warning service-btn' onClick={gotoPerawatanRutin}> KLIK </button></div>
        </div>
        {/* general service */}
        <div className='second-service col-11 col-md-4'>
          <div><p className='service-name'>SERVIS BERAT</p></div>
          <div className='service-picture col-10'>
            <img src={GeneralImage} alt='first' />
          </div>
          <div><button className='btn btn-warning service-btn' onClick={gotoGeneral}> KLIK </button></div>
        </div>
        {/* repair */}
        <div className='third-service col-11 col-md-4'>
          <div><p className='service-name'>OVERHAUL</p>
          </div><div className='service-picture col-10'>
            <img src={RepairImage} alt='first' />
          </div>
          <div><button className='btn btn-warning service-btn' onClick={gotoRepair}> KLIK </button></div>
        </div>
      </div>

      {/* card */}
      <div className='options row'>
      <div className='first-service col-11 col-md-4'>
        <div className='about-card'><p className='about-title'> JAMINAN MUTU </p>
          <p className='about-text'>  Servis motor terbaik sampai di depan rumah anda. </p>
          <ul>
            <li> Servis Motor Keliling </li>
            <li> Suku Cadang Asli </li>
            <li> Mekanik yg trampil </li>
            <li> Garansi service 7 hari </li>
          </ul>
        </div>
      </div>

      <div className='second-service col-11 col-md-4'>
        <div className='service-picture col-10'>
          <img src={require("./mechanics.webp")} alt="mechanics" />
        </div>
      </div>

      <div className='third-service col-11 col-md-4'>
        <div className='photo col-10'>
          <img src={ImageKiri} alt='kiri' />
        </div>
      </div>
      
      </div>
    
      <Footer />
    </div>
  )
}

export default Homepage
