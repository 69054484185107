// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sliding-img{ height:45vh; display: flex; justify-content: center; margin-left: auto; margin-right: auto; }
.sliding-img > img { height:100%; width:100%; object-fit: cover; }
.carousel{ background-color: bisque; }
.caption{ color:White; }

`, "",{"version":3,"sources":["webpack://./src/components/Carousel/Carousel.css"],"names":[],"mappings":"AAAA,cAAc,WAAW,EAAE,aAAa,EAAE,uBAAuB,EAAE,iBAAiB,EAAE,kBAAkB,EAAE;AAC1G,qBAAqB,WAAW,EAAE,UAAU,EAAE,iBAAiB,EAAE;AACjE,WAAW,wBAAwB,EAAE;AACrC,UAAU,WAAW,EAAE","sourcesContent":[".sliding-img{ height:45vh; display: flex; justify-content: center; margin-left: auto; margin-right: auto; }\r\n.sliding-img > img { height:100%; width:100%; object-fit: cover; }\r\n.carousel{ background-color: bisque; }\r\n.caption{ color:White; }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
