import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../components/Booking/Booking.css";
import "../../routes/profile/Profile";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPen } from '@fortawesome/free-solid-svg-icons';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu'; 
import MenuItem from '@mui/material/MenuItem'; 
import TextField from '@mui/material/TextField';
import * as yup from 'yup'; 
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import { AllStates } from '../../context/Context';
import Adminnav from './Adminnav'
import "./v2Admin.css";
import { Application, DatePicker, TimePicker } from "react-rainbow-components";

const initTime   = "12:00";
const initDate   = "2024-01-01T12:00:00.000Z";
const locale = { name: 'id', label: 'Indonesian' };

var namaProvinsi  = [];  var kodeProvinsi  = [];
var currEditUser  = {};  var currEditArray = [];
var currEditKey   = '';  var currEditIndex = -1; 
var paramKhusus   = '';  var usep01 = false;
var param00label  = [];  var usep02 = false;
var autoParam01   = [];  var usep03 = false;
var autoParam02   = [];  var usep04 = false;
var autoParam03   = [];  var usep05 = false;
var autoParam04   = [];  var usep06 = false;
var autoParam05   = [];  var usep07 = false;
var autoParam06   = [];  var usep08 = false;
var autoParam07   = [];  var usep09 = false;
var autoParam08   = [];  var usep10 = false;
var autoParam09   = [];  var selectedAlamat = "";
var selectedMotor = "";  var selectedServis = "";

var regSchemaValidation = yup.object({
  userName: yup.string().required("Ketik nama"),
  password: yup.string().required("Ketik password)"),
  email: yup.string().required("Ketik email)"),
  mobile: yup.string().matches(/^[0-9]*$/).required("Ketik No Hp"),
  role: yup.string().required("Pilih role"),
});

const AdminAddUser = () => {
  const { bikes, propinsi, url } = AllStates();
  const [disableBtnUpload, setDisableBtnUpload] = useState(true);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(true);
  const [photoURL, setPhotoURL] = useState(url+"/images/add_photo.png");
  const [fileImageUser, setFileImageUser] = useState();
  const [fileImage, setFileImage] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [input00, setInput00] = useState(url+"/images/add_photo.png");
  const [input01, setInput01] = useState('--pilih--');
  const [input02, setInput02] = useState();
  const [input03, setInput03] = useState();
  const [input04, setInput04] = useState();
  const [input05, setInput05] = useState(initDate);
  const [input06, setInput06] = useState();
  const [input07, setInput07] = useState();
  const [input08, setInput08] = useState();
  const [input09, setInput09] = useState();
  const [input10, setInput10] = useState();
  const [nAlamat, setnAlamat] = useState(['--pilih--', 'tambah baru']);
  const [nMotor, setnMotor] = useState(['--pilih--', 'tambah baru']);
  const [nServis, setnServis] = useState(['--pilih--', 'tambah baru']);
  const roleArray = ['user','mekanik','manager','partner','admin'];
  const genderArray = ['laki-laki','perempuan'];
  const statusArray = ['menikah','duda/janda','single'];
  const pekerjaanArray = ['karyawan','wirausaha','PNS','TNI/Polri','lainnya'];
  const bikeAry = [];  let bikeArray = [];
  const transmisiArray = ['manual', 'matic'];
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  function gotoAdminUsers() { navigate('/admin/users') };
  function gotoHomePage() { navigate('/') };
  function closePopUp() { setShowPopup(false); };
  function formatDateData(value) { setInput05(value); }
  function formatTimeData(value) { setInput10(value); }
  function unique(array) { return array.filter((item, index) => array.indexOf(item) === index) };
  bikes && bikes.map((i) => bikeAry.push(i.bikeCompany)); bikeArray = unique(bikeAry);

  if (propinsi) { 
    kodeProvinsi = Object.keys(propinsi);
    namaProvinsi = Object.values(propinsi);
    //console.log(kodeProvinsi[namaProvinsi.indexOf('DKI Jakarta')]);
  };

  // formik function
  const { values, setFieldValue, handleSubmit, handleBlur, errors, touched } = useFormik({
      initialValues: {
          userName: '', email: '', mobile: '', role: '', periodeSIM: '',
          gender: '', status: '', pekerjaan: '', kantor: '', password: '',
          alamat: [], motor: [], servis: [],
      },
      validationSchema: regSchemaValidation,
      onSubmit: (userData, onSubmitProps) => {
          console.log("OnSubmit Trying to submit data");
          sendData(userData); 
          onSubmitProps.resetForm();
          setDisableBtnUpdate(true)
      }
  });

  function formatDate(value) { setInput05(value); values.periodeSIM = value; }

  async function sendData(userData) {
      console.log("Function sendData Sending user data = ", userData);
      try {
          const newOrder = await axios.post(`${url}/users/signup`, userData)
          .then( res => { gotoAdminUsers(); window.location.reload(); })
          .catch(error => toast.error(error.response.data.message));
      }
      catch (error) { toast.error(error.response.data.message) };
  };

  function handleOnChange(e) {
    const key = e.target.name;
    const val = e.target.value || "";

    setDisableBtnUpdate(false);
    setFieldValue(key, val);
  };

  function handleFileUserChange(e) {
    console.log("e.target = ",e.target);
    setFileImageUser(e.target.files[0]);
    setDisableBtnUpload(false);  
  };
  async function updatePicUser() {
      console.log("fileImageUser = ",fileImageUser);
      const formData = new FormData()
      formData.append('file', fileImageUser)

      try {
        await axios.post(`${url}/images/new/imagefor/users`, formData)
        .then(res => { 
          setPhotoURL(res.data.keyURL); 
          setFieldValue('photoURL', res.data.keyURL);
          setDisableBtnUpload(true);
        }).catch(error => toast.error(error.response.data.message));    } 
      catch (error) { toast.error(error.response.data.message) };  
  };

  function resetAutoParam() {
    paramKhusus   = ''; param00label   = [];
    autoParam01   = []; autoParam02    = [];
    autoParam03   = []; autoParam04    = [];
    autoParam05   = []; autoParam06    = [];
    autoParam07   = []; autoParam08    = [];
    autoParam09   = []; selectedAlamat = '';
    selectedMotor = ''; selectedServis = '';
};

function resetParam() {
    currEditArray = []; usep01 = false;
    usep02 = false; usep03 = false;
    usep04 = false; usep05 = false;
    usep06 = false; usep07 = false;
    usep08 = false; usep09 = false;
    setInput00(''); setInput01('');
    setInput02(''); setInput03('');
    setInput04(''); setInput05('');
    setInput06(''); setInput07('');
    setInput08(''); setInput09('');
    setInput10('');        
  };

  function handleFileChange(e) {
    setFileImage(e.target.files[0]);
    setDisableBtnUpload(false);  
  };
  async function updatePic() {
    if (!currEditKey || currEditKey === 'profile') { currEditKey = 'photoURL' };
    console.log("currEditKey", currEditKey);
    const formData = new FormData()
    formData.append('file', fileImage)
    try {
      await axios.post(`${url}/image/update/picture/${currEditKey}`, formData)
      .then(res => { 
        setInput00(res.data.keyURL);
        setDisableBtnUpload(true);
    })
      .catch(error => toast.error(error.response.data.message));    } 
    catch (error) { toast.error(error.response.data.message) };  
  };

  function simpanData() {
    setShowPopup(false);
    console.log("currEditKey= ",currEditKey);
    let newJson = {};
    switch (currEditKey) {
        case 'alamat' :
            if (currEditIndex == -1) { 
                newJson = {
                    alamatURL: input00, id: input01, alamat1: input02, alamat2: input03, kelurahan: input04,
                    kecamatan: input06, kotakab: input07, propinsi: input08, kodepos: input09,
                };
                setnAlamat((current) => [...current, input01, ]);
                values.alamat.push(newJson);
            }
            else { 
                currEditArray[currEditIndex].alamatURL = input00;
                currEditArray[currEditIndex].id = input01;
                currEditArray[currEditIndex].alamat1 = input02;
                currEditArray[currEditIndex].alamat2 = input03;
                currEditArray[currEditIndex].kelurahan = input04;
                currEditArray[currEditIndex].periodeSIM = input05;
                currEditArray[currEditIndex].kecamatan = input06;
                currEditArray[currEditIndex].kotakab = input07;
                currEditArray[currEditIndex].propinsi = input08;
                currEditArray[currEditIndex].kodepos = input09; 
                currEditUser.alamat = currEditArray;
            };
            break;
        case 'motor' :
            const idMotor = input02.replace(/\s/g, "").toLowerCase();
            setInput01(idMotor);
            console.log("currEditIndex = ", currEditIndex)
            if (currEditIndex == -1) { 
                newJson = {
                    motorURL: input00, id: idMotor, nopol: input02, bikeCompany: input03, model: input04,
                    transmisi: input06, cc: input07, tahun: input08, odometer: input09, periodeSTNK: input05
                };
                setnMotor((current) => [...current, input02, ]);
                values.motor.push(newJson); 
            }
            else { 
                currEditArray[currEditIndex].motorURL = input00;
                currEditArray[currEditIndex].id = idMotor;
                currEditArray[currEditIndex].nopol = input02;
                currEditArray[currEditIndex].bikeCompany = input03;
                currEditArray[currEditIndex].model = input04;
                currEditArray[currEditIndex].periodeSTNK = input05;
                currEditArray[currEditIndex].transmisi = input06;
                currEditArray[currEditIndex].cc = input07;
                currEditArray[currEditIndex].tahun = input08;
                currEditArray[currEditIndex].odometer = input09;
                currEditUser.motor = currEditArray;
             };
            break;
        case 'servis' :
            if (currEditIndex == -1) { 
                newJson = {
                    servisURL: input00, id: input01, motorId: input02, alamatId: input03, alamat: input04, serviceDate: input05, 
                    gantiOli: input06, tuneUp: input07, lain_lain: input08, price: input09, serviceTime: input10
                };
                setnServis((current) => [...current, input01, ]);
                values.servis.push(newJson); 
            }
            else { 
                currEditArray[currEditIndex].servisURL = input00;
                currEditArray[currEditIndex].id = input01;
                currEditArray[currEditIndex].motorId = input02;
                currEditArray[currEditIndex].alamatId = input03;
                currEditArray[currEditIndex].alamat = input04;
                currEditArray[currEditIndex].serviceDate = input05;
                currEditArray[currEditIndex].gantiOli = input06;
                currEditArray[currEditIndex].tuneUp = input07;
                currEditArray[currEditIndex].lain_lain = input08;
                currEditArray[currEditIndex].price = input09;
                currEditArray[currEditIndex].serviceTime = input10;
                currEditUser.servis = currEditArray;
            };
            break;
        default : break;
    }
    console.log("newJson = ", newJson);
    console.log("currEditArray = ", currEditArray);
    console.log("currEditUser = ", currEditUser);
  };

  function handleSelection(e, c) {
    console.log("e.target = ", e.target);
    const newValue = e.target.value;
    currEditKey = e.target.name;
    console.log("selected ", currEditKey, " = ", newValue);
    resetAutoParam();
    switch (currEditKey) {
      case 'alamat' :
          currEditArray = values.alamat;
          autoParam08 = namaProvinsi;
          autoParam01 = ["rumah","kantor","kontrakan","apartemen"];
          param00label = ["Foto", "Label Alamat", "Alamat1", "Alamat2", "Kelurahan", "Periode SIM",
                        "Kecamatan", "Kota/Kabupaten", "Propinsi", "Kode Pos", "", "", "", "", ""];
          if ( (newValue === '') || (newValue === '--pilih--') ) { setShowPopup(false); }
          else if (newValue === "tambah baru") { 
              currEditIndex = -1; 
              resetParam(); 
              setInput05(initDate);
              paramKhusus='baru'; 
              usep01 = false; usep05 = true; usep10 = true;
              setShowPopup(true);
          } 
          else {
              { currEditArray.map((u,i)=> {
              if(u.id === newValue) { currEditIndex = i }}); };
              console.log("currEditArray = ", currEditArray);
              console.log("currEditIndex = ", currEditIndex);
              setInput00(currEditArray[currEditIndex].alamatURL);
              setInput01(currEditArray[currEditIndex].id);
              setInput02(currEditArray[currEditIndex].alamat1);
              setInput03(currEditArray[currEditIndex].alamat2);
              setInput04(currEditArray[currEditIndex].kelurahan);
              setInput05(currEditUser.periodeSIM);
              setInput06(currEditArray[currEditIndex].kecamatan);
              setInput07(currEditArray[currEditIndex].kotakab);
              setInput08(currEditArray[currEditIndex].propinsi);
              setInput09(currEditArray[currEditIndex].kodepos);
              setInput10(initTime);
              usep01 = true; usep10 = true;
              setShowPopup(true);
          };
          
          break;
      case 'motor'  :
          autoParam03 = bikeArray;
          autoParam06 = ['manual', 'matic'];
          currEditArray = values.motor;
          param00label = ["Foto", "id Motor", "No Pol", "Merek", "Tipe/Model", "Periode STNK",
                    "Transmisi", "cc", "Tahun", "Odometer", "", "", "", "", ""];
          if ( (newValue === '') || (newValue === '--pilih--') ) { setShowPopup(false); }
          else if (newValue === "tambah baru") { 
              currEditIndex = -1; 
              resetParam(); 
              setInput05(initDate);
              paramKhusus='baru'; 
              usep01 = true; usep10 = true;
              setShowPopup(true);
          } 
          else {  
              { currEditArray.map((u,i)=> {
              if(u.nopol === newValue) { currEditIndex = i }; }); };
              console.log("currEditIndex= ",currEditIndex);
              setInput00(currEditArray[currEditIndex].motorURL);
              setInput01(currEditArray[currEditIndex].id);
              setInput02(currEditArray[currEditIndex].nopol);
              setInput03(currEditArray[currEditIndex].bikeCompany);
              setInput04(currEditArray[currEditIndex].model);
              setInput05(currEditArray[currEditIndex].periodeSTNK);
              setInput06(currEditArray[currEditIndex].transmisi);
              setInput07(currEditArray[currEditIndex].cc);
              setInput08(currEditArray[currEditIndex].tahun);
              setInput09(currEditArray[currEditIndex].odometer);
              setInput10(initTime);
              usep01 = true; usep10 = true;
              setShowPopup(true);
          };
          
          break;
      case 'servis' :
          paramKhusus = 'rencana';
          currEditArray = values.servis;
          { values.alamat.map((u,i)=> { 
            autoParam03.push(u.id); 
            autoParam04.push(u.alamat1+", "+u.alamat2+", "+u.kelurahan+", "+u.kecamatan+", "+u.kotakab+" "+u.kodepos+" "+u.propinsi);
          }); };
          { values.motor.map((u,i)=> { 
            autoParam01.push(u.nopol); 
            autoParam02.push(u.bikeCompany+" "+u.model+" "+u.transmisi+" "+u.cc+"cc th "+u.tahun);
          }); };
          param00label = ["Foto", "Untuk Motor", "Merek / Tipe Motor", "Tempat Servis", "Alamat", "Tgl Servis",  
                                    "Ganti Oli", "Tune Up", "Servis Lain", "Harga Perkiraan", "Jam Servis",, "", "", "", "" ];
          if ( (newValue === '') || (newValue === '--pilih--') ) { setShowPopup(false); }
          else if (newValue === "tambah baru") { 
              currEditIndex = -1; 
              resetParam(); 
              setInput05(initDate);
              setInput10(initTime);
              paramKhusus='baru rencana'; 
              usep01 = false;
              setShowPopup(true);
          } 
          else { 
              { currEditArray.map((u,i)=> {
              if(u.id === newValue) { currEditIndex = i }; }); };
              console.log("currEditIndex= ",currEditIndex);
              setInput00(currEditArray[currEditIndex].servisURL);
              setInput01(currEditArray[currEditIndex].id);
              setInput02(currEditArray[currEditIndex].motorId);
              setInput03(currEditArray[currEditIndex].alamatId);
              setInput04(currEditArray[currEditIndex].alamat);
              setInput05(currEditArray[currEditIndex].serviceDate);  
              setInput06(currEditArray[currEditIndex].gantiOli);
              setInput07(currEditArray[currEditIndex].tuneUp);
              setInput08(currEditArray[currEditIndex].lain_lain);
              setInput09(currEditArray[currEditIndex].price);
              setInput10(currEditArray[currEditIndex].serviceTime);        
              usep01 = true;   
              setShowPopup(true);  
          };
          
          break;
      default : break;
    }

  }

  function handleClick(e) {
    console.log("e.target = ", e.target);
  }

  const theme = { rainbow: { palette: { mainBackground: 'rgba(0,0,0,0.0)', }, },};
  useEffect(() => { if (!user) { gotoHomePage() }; }, [user])

  return (
    <div className='set-price-page'><Adminnav />
    <div className='mt-3' >
      <div> <h3 style={{margin:'10px'}}>Tambah User</h3> </div>
      <div className='profile-picture-container col-12'>
          <div className='picture col-11 col-md-4'><img src={photoURL} alt="service profile" height='100%'/></div>
          <div className='upload-button m-3'>
              <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>GANTI FOTO 
              <span><FontAwesomeIcon icon={faPen} /></span> </p></div> 
              <div>
                <input type='file' onChange={handleFileUserChange} /> <br />
                <button onClick={updatePicUser} className='btn btn-success m-2' disabled={disableBtnUpload}> UPLOAD 
                  <span><FontAwesomeIcon icon={faUpload} /></span>
                </button>
              </div>
            </div>
        </div>
        <div className='bookings-form'>
          <form onSubmit={handleSubmit} style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div className='form-filling row col-12'>
              <div className='left col-12 col-md-4' style={{padding:'10px'}}>
                  <TextField id="outlined-uncontrolled" required label="Nama User" onBlur={handleBlur} variant="outlined" key="username"
                      fullWidth margin="normal" name="userName" value={values.userName} onChange={handleOnChange} />
                      {touched.userName && errors.userName ? <p className="error-msg" style={{ color: "red" }}>{errors.userName}</p> : ""}
                  <TextField id="outlined-uncontrolled" label="Password" onBlur={handleBlur} key="password"
                      fullWidth margin="normal" name="password" value={values.password} onChange={handleOnChange}
                      error={touched.password && Boolean(errors.password)} helperText={touched.password && errors.password} />
                  <TextField id="outlined-uncontrolled" required label="Email" onBlur={handleBlur} key="email"
                      fullWidth margin="normal" name="email" value={values.email} onChange={handleOnChange} 
                      error={touched.email && Boolean(errors.email)} helperText={touched.email && errors.email} />
                  <TextField id="outlined-uncontrolled" required label="No Hp" onBlur={handleBlur} key="mobile"
                      fullWidth margin="normal" name="mobile" value={values.mobile} onChange={handleOnChange} />
                      {touched.mobile && errors.mobile ? <p className="error-msg" style={{ color: "red" }}>{errors.mobile}</p> : ""}

                  <DatePicker id="controlled-5" value={input05} onChange={formatDate} label='Periode SIM' 
                      locale={locale.name} formatStyle="large" borderRadius="semi-square" placeholder='Periode SIM'
                       />

                </div>
                <div className='right col-12 col-md-4' style={{margin:'0' , padding:'10px'}}>
                  <TextField select id="outlined-uncontrolled" label="Role" onBlur={handleBlur} key="role"
                      fullWidth margin="normal" name="role" value={values.role} onChange={handleOnChange}
                      error={touched.role && Boolean(errors.role)} helperText={touched.role && errors.role} >
                      {roleArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                  </TextField>
                  <TextField select id="outlined-uncontrolled" label="Jenis Kelamin" onBlur={handleBlur} key="gender"
                      fullWidth margin="normal" name="gender" value={values.gender} onChange={handleOnChange}
                      error={touched.gender && Boolean(errors.gender)} helperText={touched.gender && errors.gender} >
                      {genderArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                  </TextField>
                  <TextField select id="outlined-uncontrolled" label="Status" onBlur={handleBlur} key="status"
                      fullWidth margin="normal" name="status" value={values.status} onChange={handleOnChange}
                      error={touched.status && Boolean(errors.status)} helperText={touched.status && errors.status} >
                      {statusArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                  </TextField>
                  <TextField select id="outlined-uncontrolled" label="Pekerjaan" onBlur={handleBlur} key="pekerjaan"
                      fullWidth margin="normal" name="pekerjaan" value={values.pekerjaan} onChange={handleOnChange}
                      error={touched.pekerjaan && Boolean(errors.pekerjaan)} helperText={touched.pekerjaan && errors.pekerjaan} >
                      {pekerjaanArray.map((i) => <MenuItem value={i} key={i}> {i} </MenuItem>)}
                  </TextField>
                
                  <div className='button-list'>
                      <TextField select id="outlined" label={"Alamat ("+(nAlamat.length -2)+")"} onBlur={handleBlur} key="nAlamat"
                          fullWidth margin="normal" name="alamat" value={selectedAlamat} onChange={handleSelection}
                          error={touched.nAlamat && Boolean(errors.nAlamat)} helperText={touched.nAlamat && errors.nAlamat} >
                          {nAlamat.map((i) => <MenuItem value={i} key={i} >  {i} </MenuItem>)}
                      </TextField>
                      <TextField select id="outlined" label={"Motor ("+(nMotor.length -2)+")"} onBlur={handleBlur} key="nMotor"
                          fullWidth margin="normal" name="motor" value={selectedMotor} onChange={handleSelection}
                          error={touched.nMotor && Boolean(errors.nMotor)} helperText={touched.nMotor && errors.nMotor} >
                          {nMotor.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                      </TextField>
                      <TextField select id="outlined" label={"Servis ("+(nServis.length -2)+")"} onBlur={handleBlur} key="nServis"
                          fullWidth margin="normal" name="servis" value={selectedServis} onChange={handleSelection}
                          error={touched.nServis && Boolean(errors.nServis)} helperText={touched.nServis && errors.nServis} >
                          {nServis.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                      </TextField> 
                  </div>                
                </div>
                <div className='container-set-harga' ><div className='form-btn'>
                    <button className='btn btn-primary mb-3 register-btn' type='submit'  
                    disabled={disableBtnUpdate}  >
                      TAMBAH USER</button>
                </div></div>
            </div>
          </form>
          </div>
        </div> 
        <React.Fragment>
            <Dialog fullWidth={true} maxWidth={'xl'} open={showPopup} onClose={closePopUp} scroll={'paper'}
                aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" >
            <DialogTitle id="scroll-dialog-title">Data {paramKhusus} {currEditKey} {input01}</DialogTitle>
            <DialogContent dividers={true}>
                <Box noValidate component="form"  autoComplete="off" sx={{ display:'flex', flexDirection:'column', m:'auto', 
                    width:'100%', height:'40vh', border:'solid', borderRadius:'8px', marginBottom:'10px',
                    paddingLeft:'10px', paddingRight:'10px' }}>
                    <div className='options row w-100' >
                    <div className='first-service col-12 col-md-4 flex-fill' >
                        <img src={input00} alt="gambar" />
                    </div><div className='second-service col-12 col-md-4 flex-fill' >
                        <Autocomplete id="controlled-1" fullWidth options={autoParam01} renderInput={(params) => 
                            <TextField {...params} className="mb-1 mt-1" label={param00label[1]} variant="outlined" fullWidth />}
                            readOnly={usep01} inputValue={input01} onInputChange={(e,v) => {setInput01(v)}} freeSolo />
                        <Autocomplete id="controlled-2" fullWidth options={autoParam02} renderInput={(params) => 
                            <TextField {...params} className="mb-1 mt-1" label={param00label[2]} variant="outlined" fullWidth />} 
                            inputValue={input02} onInputChange={(e,v) => {setInput02(v)}} freeSolo />
                        <Autocomplete id="controlled-3" fullWidth options={autoParam03} renderInput={(params) => 
                            <TextField {...params} className="mb-1 mt-1" label={param00label[3]} variant="outlined" fullWidth />} 
                            inputValue={input03} onInputChange={(e,v) => {setInput03(v)}} freeSolo />
                        <Autocomplete id="controlled-4" fullWidth options={autoParam04} renderInput={(params) => 
                            <TextField {...params} className="mb-1 mt-1" label={param00label[4]} variant="outlined" fullWidth />} 
                            inputValue={input04} onInputChange={(e,v) => {setInput04(v)}} freeSolo />
                        <DatePicker id="controlled-5" value={input05} onChange={formatDateData} label={param00label[5]} locale={locale.name}
                            formatStyle="large" borderRadius="semi-square" placeholder={param00label[5]} />
                    </div><div className='third-service col-12 col-md-4 flex-fill'>
                        <Autocomplete id="controlled-6" fullWidth options={autoParam06} renderInput={(params) => 
                            <TextField {...params} className="mb-1 mt-1" label={param00label[6]} variant="outlined" fullWidth />} 
                            inputValue={input06} onInputChange={(e,v) => {setInput06(v)}} freeSolo />
                        <Autocomplete id="controlled-7" fullWidth options={autoParam07} renderInput={(params) => 
                            <TextField {...params} className="mb-1 mt-1" label={param00label[7]} variant="outlined" fullWidth />} 
                            inputValue={input07} onInputChange={(e,v) => {setInput07(v)}} freeSolo />
                        <Autocomplete id="controlled-8" fullWidth options={autoParam08} renderInput={(params) => 
                            <TextField {...params} className="mb-1 mt-1" label={param00label[8]} variant="outlined" fullWidth />} 
                            inputValue={input08} onInputChange={(e,v) => {setInput08(v)}} freeSolo />
                        <Autocomplete id="controlled-9" fullWidth options={autoParam09} renderInput={(params) => 
                            <TextField {...params} className="mb-1 mt-1" label={param00label[9]} variant="outlined" fullWidth />} 
                            inputValue={input09} onInputChange={(e,v) => {setInput09(v)}} freeSolo />
                        <TimePicker id="controlled-10" value={input10} onChange={formatTimeData} label={param00label[10]} hour24 disabled={usep10}
                            formatStyle="large" borderRadius="semi-square" placeholder={param00label[10]}  />
                    </div></div>
                </Box>
            </DialogContent>
            <DialogActions> 
                <button onClick={updatePic} className='btn btn-success m-2' disabled={disableBtnUpload}> 
                Upload Foto  </button>
                <input type='file' onChange={handleFileChange} /> <br />
                <Button variant="contained" onClick={simpanData}>Simpan data {paramKhusus} {currEditKey} {input01} </Button>
                <Button variant="contained" onClick={closePopUp}>Selesai</Button> 
            </DialogActions>
            </Dialog>
                </React.Fragment>
      </div>
    )
}

export default AdminAddUser