import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import "../../components/Booking/Booking.css";
import "../../routes/profile/Profile";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPen } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as yup from 'yup'; 
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import { AllStates } from '../../context/Context';
import MenuItem from '@mui/material/MenuItem'; 
import Adminnav from './Adminnav'
import "./Admin.css";

// Service at home
var SchemaValidation = yup.object({
    bikeCompany: yup.string().required("Pilih merek motor"),
    model: yup.string().required("Pilih model/tipe motor"),
    transmisi: yup.string().required("Pilih manual / matic"),
    cc: yup.string().required("Ketik cc motor"),
    tahun: yup.string().required('ketik tahun beli motor'),
    warna: yup.string().required('ketikwarna motor'),
});

const AdminUpdateBike = () => {
  const [disableBtnUpload, setDisableBtnUpload] = useState(true);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(true);
  const [fileImage, setFileImage] = useState();
  let dataMotor = JSON.parse(localStorage.getItem('bikeData'));
  const { bikes, url } = AllStates();
  const bikeAry = [];  let brand = [];
  const transmisiArray = ['manual', 'matic']
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  function gotoMotor() { navigate('/admin/bikes') };
  function gotoLogin() { navigate('/login')};

  console.log("Data motor got from local storage = ", dataMotor);
  console.log("id motor = ", dataMotor._id);

  // formik function
  const { values, setFieldValue, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
      initialValues: {
          _id: dataMotor._id, bikeCompany: dataMotor.bikeCompany, model: dataMotor.model, image: dataMotor.image,
          transmisi: dataMotor.transmisi, cc: dataMotor.cc, tahun: dataMotor.tahun, warna: dataMotor.warna, 
      },
      validationSchema: SchemaValidation,
      onSubmit: (mtrData, onSubmitProps) => {
          sendData(mtrData); 
          onSubmitProps.resetForm();
          setDisableBtnUpdate(true)
      }
  });

  function unique(array) { return array.filter((item, index) => array.indexOf(item) === index) };
  bikes && bikes.map((i) => bikeAry.push(i.bikeCompany)); brand = unique(bikeAry);

  async function sendData(mtrData) {
      console.log("Function sendData Sending motor data = ", mtrData);
      try {
          await axios.post(`${url}/bikes/update/bikeInfo`, mtrData)
          .then( res => { 
            gotoMotor(); 
            localStorage.removeItem('motorData'); 
            window.location.reload();  })
          .catch(error => toast.error(error.response.data.message));
      } catch (error) { toast.error(error.response.data.message) };
  };

  function handleOnChange(e) {
    const key = e.target.name;
    const value = e.target.value || "";

    setDisableBtnUpdate(false);
    switch (key) {
      case 'transmisi' : setFieldValue('transmisi', value.toLowerCase()); return;
      default : setFieldValue(key, value); return;
    }
  };
  
  function handlePicChange(e) {
    setFileImage(e.target.files[0]);
    setDisableBtnUpload(false);  
  };
  async function updatePic() {
      const formData = new FormData()
      formData.append('file', fileImage)

      try {
        await axios.post(`${url}/image/new/picture/motor`, formData)
        .then(res => { setFieldValue('image', res.data.motorURL)})
        .catch(error => toast.error(error.response.data.message));    } 
      catch (error) { toast.error(error.response.data.message) };  
  };

  useEffect(() => { if (!user) { gotoLogin() }; }, [user]);
  return (
    <div className='set-harga-page'><Adminnav />
    <div className='mt-3' >
      <div> <h3 style={{margin:'10px'}}>Update Data Motor</h3> </div>
      <div className='profile-picture-container col-12'>
          <div className='picture col-11 col-md-4'>
            <img src={dataMotor.image} alt="motor profile" height='100%'/>
          </div>
          <div className='upload-button m-3'>
              <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>GANTI FOTO MOTOR 
              <span><FontAwesomeIcon icon={faPen} /></span> </p></div> 
              <div>
                <input type='file' onChange={handlePicChange} /> <br />
                <button onClick={updatePic} className='btn btn-success m-2' disabled={disableBtnUpload}>
                   UPLOAD <span><FontAwesomeIcon icon={faUpload} /></span>
                </button>
              </div>
            </div>
        </div>
        <div className='bookings-form'>
          <form onSubmit={handleSubmit} style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div className='form-filling row col-12'>
              <div className='left col-12 col-md-4' style={{padding:'10px'}}>
                  <TextField select id="bikeCompany" label="Merek" variant="outlined" onBlur={handleBlur} key="Merek"
                      fullWidth margin="normal" name="bikeCompany" value={values.bikeCompany} onChange={handleOnChange}
                      error={touched.bikeCompany && Boolean(errors.bikeCompany)} helperText={touched.bikeCompany && errors.bikeCompany} >
                      <MenuItem key={"1"} value={""} disabled> --Pilih merk motor--  </MenuItem>
                      {brand.map((i) => <MenuItem value={i} key={i._id} > {i} </MenuItem>)}
                  </TextField>
                  <TextField select id="model" label="Tipe" variant="outlined" onBlur={handleBlur} key="Tipe"
                      fullWidth margin="normal" name="model" value={values.model} onChange={handleOnChange}
                      error={touched.model && Boolean(errors.model)} helperText={touched.model && errors.model} >
                      <MenuItem key={"2"} value={""} disabled> --pilih tipe motor--  </MenuItem>
                      {bikes && bikes.map((i) => values.bikeCompany && values.bikeCompany === i.bikeCompany ? 
                      <MenuItem value={i.model} key={i._id}> {i.model} </MenuItem> : '')}
                  </TextField>
                  <TextField select id="transmisi" label="Trasnmisi" variant="outlined" onBlur={handleBlur} key="Transmisi"
                      fullWidth margin="normal" name="transmisi" value={values.transmisi} onChange={handleOnChange}
                      error={touched.transmisi && Boolean(errors.transmisi)} helperText={touched.transmisi && errors.transmisi} >
                      <MenuItem key={"10"} value={""} disabled> --Pilih manual/matic--  </MenuItem>
                      {transmisiArray.map((i) => <MenuItem value={i} key={i._id} > {i} </MenuItem>)}
                  </TextField>
                </div>
                <div className='right col-12 col-md-4' style={{margin:'0' , padding:'10px'}}>
                  <TextField id="cc" required label="cc" variant="outlined" onBlur={handleBlur} key="cc"
                      fullWidth margin="normal" name="cc" value={values.cc} onChange={handleOnChange} />
                      {touched.cc && errors.cc ? <p className="error-msg" style={{ color: "red" }}>{errors.cc}</p> : ""}
                  <TextField id="tahun" required label="Tahun" variant="outlined" onBlur={handleBlur} key="tahun"
                      fullWidth margin="normal" name="tahun" value={values.tahun} onChange={handleOnChange} />
                      {touched.tahun && errors.tahun ? <p className="error-msg" style={{ color: "red" }}>{errors.tahun}</p> : ""}
                  <TextField id="warna" required label="Warna" variant="outlined" onBlur={handleBlur} key="warna"
                      fullWidth margin="normal" name="warna" value={values.warna} onChange={handleOnChange} />
                      {touched.warna && errors.warna ? <p className="error-msg" style={{ color: "red" }}>{errors.warna}</p> : ""}
                </div>
                <div className='container-set-harga' ><div className='form-btn'>
                    <button className='btn btn-primary mb-3 register-btn' type='submit' disabled={disableBtnUpdate}>
                      UPDATE MOTOR INFO</button>
                </div></div>
            </div>
          </form>
          </div>
        </div>
      </div>
    )
}

export default AdminUpdateBike