// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left , .right{ margin-top: 10px; padding: 10px; }
.form-filling{ height:auto; }
.error-msg{ margin-bottom: 0; }
form{ background-color: transparent !important; }
.row{ --bs-gutter-x: 0rem !important; }
.ant-select-selection { background-color: transparent; }`, "",{"version":3,"sources":["webpack://./src/components/Booking/Booking.css"],"names":[],"mappings":"AAAA,gBAAgB,gBAAgB,EAAE,aAAa,EAAE;AACjD,eAAe,WAAW,EAAE;AAC5B,YAAY,gBAAgB,EAAE;AAC9B,MAAM,wCAAwC,EAAE;AAChD,MAAM,8BAA8B,EAAE;AACtC,wBAAwB,6BAA6B,EAAE","sourcesContent":[".left , .right{ margin-top: 10px; padding: 10px; }\r\n.form-filling{ height:auto; }\r\n.error-msg{ margin-bottom: 0; }\r\nform{ background-color: transparent !important; }\r\n.row{ --bs-gutter-x: 0rem !important; }\r\n.ant-select-selection { background-color: transparent; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
