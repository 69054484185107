import {BrowserRouter , Route , Routes} from 'react-router-dom' ;
import Errorpage from './routes/errorPage/Errorpage';
import Homepage from './routes/homepage/Homepage';
import Scheduling from './routes/Scheduling/Scheduling';
import Contact from './routes/Contact/Contact';
import Register from './routes/Register/Register';
import Login from './routes/login/Login';
import ResetPassword from './routes/forgotPassword/ResetPassword';
import PasswordRequest from './routes/forgotPassword/PasswordRequest';
import Profile from './routes/profile/Profile';
import Perawatan_Rutin from './routes/perawatan_rutin/Perawatan_Rutin';
import Services from './routes/Service/Services';
import Repair from './routes/repair/Repair';
import Dashboard from './routes/admin/Dashboard';
import AdminUsers from './routes/admin/v2AdminUsers';
import AdminBikes from './routes/admin/AdminBikes';
import AdminServices from './routes/admin/AdminServices';
import AdminSetGambar from './routes/admin/AdminSetGambar';
import AdminAddUser from './routes/admin/AdminAddUser';
import AdminAddBikes from './routes/admin/AdminAddBikes';
import AdminAddService from './routes/admin/AdminAddService';
import AdminAddServiceItem from './routes/admin/AdminAddServItem';
import AdminUpdateBike from './routes/admin/AdminUpdateBike';
import AdminUpdatePrice from './routes/admin/AdminUpdatePrice';
import AdminUpdateServiceItem from './routes/admin/AdminUpdateServItem';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter> <Routes>
      <Route path='*' element={<Errorpage />} />
      <Route path='/' element={< Homepage />} />
      <Route path='/jadwal-servis' element={<Scheduling />} />
      <Route path='/signup' element={<Register />} />
      <Route path='/login' element={<Login />} />
      <Route path='/reset_password' element={<ResetPassword />} />
      <Route path='/forgot_password' element={<PasswordRequest />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/perawatan_rutin' element={<Perawatan_Rutin />} />
      <Route path='/service' element={<Services />} />
      <Route path='/repair' element={<Repair />} />
      <Route path='/admin/dashboard' element={<Dashboard />} />
      <Route path='/admin/users' element={<AdminUsers />} />
      <Route path='/admin/bikes' element={<AdminBikes />} />
      <Route path='/admin/services' element={<AdminServices />} />
      <Route path='/admin/set/gambar' element={<AdminSetGambar />} />
      <Route path='/admin/add/user' element={<AdminAddUser />} />
      <Route path='/admin/add/bike' element={<AdminAddBikes />} />
      <Route path='/admin/add/service' element={<AdminAddService />} />
      <Route path='/admin/add/service/item' element={<AdminAddServiceItem />} />
      <Route path='/admin/update/bike' element={<AdminUpdateBike />} />
      <Route path='/admin/update/price' element={<AdminUpdatePrice />} />
      <Route path='/admin/update/service/item' element={<AdminUpdateServiceItem />} />
      </Routes> </BrowserRouter>
    </div>
  );
}
export default App;