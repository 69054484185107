import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import "../../components/Booking/Booking.css";
import "../../routes/profile/Profile";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPen } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as yup from 'yup'; 
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import { AllStates } from '../../context/Context';
import MenuItem from '@mui/material/MenuItem'; 
import Adminnav from './Adminnav'
import "./Admin.css";

// Service at home
var HomeServiceSchemaValidation = yup.object({
    serviceType: yup.string().required("Ketik nama / tipe servis"),
    serviceCategory: yup.string().required("Ketik kategori servis (Ringan/Berat/Overhaul)"),
    harga: yup.string().matches(/^[0-9]*$/).required("Ketik harga (angkanya saja)"),
    bikeCompany: yup.string().required("Ketik merek motor yg bisa diservis"),
    cc: yup.string().required('ketik cc motor'),
    model: yup.string().required('ketik tipe / model motor'),
});

// Service at garage
var regSchemaValidation = yup.object({
  serviceType: yup.string().required("Ketik nama / tipe servis"),
  serviceCategory: yup.string().required("Ketik kategori servis (Ringan/Berat/Overhaul)"),
  harga: yup.string().matches(/^[0-9]*$/).required("Ketik harga (angkanya saja)"),
  bikeCompany: yup.string().required("Ketik merek motor yg bisa diservis"),
  cc: yup.string().required('ketik cc motor'),
  model: yup.string().required('ketik tipe / model motor'),
});

const AdminUpdatePrice = () => {
  const [checked, setChecked] = useState(true);
  const [disable, setDisable] = useState(false);
  let dataServis = JSON.parse(localStorage.getItem('serviceData'));
  const { bikes, services, url } = AllStates();
  const bikeAry = [];  let brand = [];
  let intHarga = 0; intHarga = parseInt(dataServis.price);
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  function gotoServices() { navigate('/admin/services') };
  function gotoLogin() { navigate('/login')};

  console.log("Data Servis got from local storage = ", dataServis);
  console.log("id servis = ", dataServis._id);
  console.log("Harga = ", parseInt(dataServis.price) );
  // formik function
  const { values, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
      initialValues: {
          _id: dataServis._id, serviceType: dataServis.serviceType, serviceCategory: dataServis.serviceCategory, 
          harga: intHarga, bikeCompany: '', model: '', cc: dataServis.cc,
      },
      validationSchema: checked ? HomeServiceSchemaValidation : regSchemaValidation,
      onSubmit: (priceData, onSubmitProps) => {
          let hargaStr = priceData.harga.toString();
          priceData["price"] = hargaStr;
          console.log("OnSubmit Trying to submit data");
          sendData(priceData); 
          onSubmitProps.resetForm();
          setDisable(false)
      }
  });

  function unique(array) { return array.filter((item, index) => array.indexOf(item) === index) };
  function checkbox(val) { setChecked(val) };
  bikes && bikes.map((i) => bikeAry.push(i.bikeCompany)); brand = unique(bikeAry);

  async function sendData(priceData) {
      console.log("Function sendData Sending price data = ", priceData);
      try {
          await axios.post(`${url}/services/updateService`, priceData)
          .then( res => { 
            gotoServices(); 
            localStorage.removeItem('serviceData'); 
            window.location.reload(); 
            })
          .catch(error => toast.error(error.response.data.message));
      }
      catch (error) { toast.error(error.response.data.message) };
  };
  
  async function updatePicService() {
    console.log("todo update Pic Service")
  }

  useEffect(() => {
      if (!user) { gotoLogin() }; }, [user])

  return (
    <div className='set-harga-page'><Adminnav />
    <div className='mt-3' >
      <div> <h3 style={{margin:'10px'}}>Update Servis dan Harga</h3> </div>
      <div className='profile-picture-container col-12'>
          <div className='picture col-11 col-md-4'>
            {/*<img src={`${services.serviceURL}`} alt="service profile" />*/}
            <img src={dataServis.serviceURL} alt="service profile" height='100%'/>
          </div>
          <div className='upload-button m-3'>
              <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>GANTI FOTO 
              <span><FontAwesomeIcon icon={faPen} /></span> </p></div> 
              <div>
                <input type='file' onChange={handleChange} /> <br />
                <button onClick={updatePicService} className='btn btn-success m-2' disabled={disable}> UPLOAD 
                  <span><FontAwesomeIcon icon={faUpload} /></span>
                </button>
              </div>
            </div>
        </div>
        <div className='bookings-form'>
          <form onSubmit={handleSubmit} style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div className='form-filling row col-12'>
              <div className='left col-12 col-md-4' style={{padding:'10px'}}>
                  <TextField id="tipeServis" required label="Tipe Servis" onBlur={handleBlur} variant="outlined" key="tipe-servis"
                      fullWidth margin="normal" name="serviceType" value={values.serviceType} onChange={handleChange} />
                      {touched.serviceType && errors.serviceType ? <p className="error-msg" style={{ color: "red" }}>{errors.serviceType}</p> : ""}
                  <TextField select id="kategoriServis" label="Kategori Servis" variant="outlined" onBlur={handleBlur} key="kategori-servis"
                      fullWidth margin="normal" name="serviceCategory" value={values.serviceCategory} onChange={handleChange}
                      error={touched.serviceCategory && Boolean(errors.serviceCategory)} helperText={touched.serviceCategory && errors.serviceCategory} >
                      <MenuItem key={"3"} value={"servis-ringan"}> --select kategori servis--  </MenuItem>
                      {["servis-ringan","service-berat","overhaul"].map((i) => <MenuItem value={i} key={i}> {i} </MenuItem>)}
                  </TextField>
                  <TextField id="hargaServis" required label="Harga Servis" variant="outlined" onBlur={handleBlur} key="harga-servis"
                      fullWidth margin="normal" name="harga" value={values.harga} onChange={handleChange} />
                      {touched.harga && errors.harga ? <p className="error-msg" style={{ color: "red" }}>{errors.harga}</p> : ""}
                </div>
                <div className='right col-12 col-md-4' style={{margin:'0' , padding:'10px'}}>
                  <TextField select id="bikeCompany" label="Merek" variant="outlined" onBlur={handleBlur} key="Merek"
                      fullWidth margin="normal" name="bikeCompany" value={values.bikeCompany} onChange={handleChange}
                      error={touched.bikeCompany && Boolean(errors.bikeCompany)} helperText={touched.bikeCompany && errors.bikeCompany} disabled={disable}  >
                      <MenuItem key={"1"} value={""} disabled> --Pilih merk motor--  </MenuItem>
                      {brand.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                  </TextField>
                  <TextField select id="model" label="Tipe" variant="outlined" onBlur={handleBlur} key="Tipe"
                      fullWidth margin="normal" name="model" value={values.model} onChange={handleChange}
                      error={touched.model && Boolean(errors.model)} helperText={touched.model && errors.model} >
                      <MenuItem key={"2"} value={""} disabled> --pilih tipe motor--  </MenuItem>
                      {bikes && bikes.map((i) => values.bikeCompany && values.bikeCompany === i.bikeCompany ? 
                      <MenuItem value={i.model} key={i}> {i.model} </MenuItem> : '')}
                  </TextField>
                  <TextField id="outlined-basic2" required label="cc" variant="outlined" onBlur={handleBlur} key="cc"
                      fullWidth margin="normal" name="cc" value={values.cc} onChange={handleChange} disabled={!checked} />
                      {touched.cc && errors.cc ? <p className="error-msg" style={{ color: "red" }}>{errors.cc}</p> : ""}
                </div>
                <div className='container-set-harga' >
                    <FormControlLabel control={<Checkbox onChange={(e) => checkbox(e.target.checked)} checked={checked} />} label="Home Service" />
                </div>
                <div className='container-set-harga' ><div className='form-btn'>
                    <button className='btn btn-primary mb-3 register-btn' type='submit' >UPDATE SERVIS</button>
                </div></div>
            </div>
          </form>
          </div>
        </div>
      </div>
    )
}

export default AdminUpdatePrice