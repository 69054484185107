import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import '../Register/Register.css'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup'; // for form validation schema
import { useFormik } from 'formik';
import { AllStates } from '../../context/Context';

const registerSchemaValidation = yup.object({
  userName: yup.string().min(3 , 'Nama 1 kata dan lebih dari 3 huruf').required("Ketik Nama Anda"),
  email: yup.string().email().required("Ketik Email"),
  mobile: yup.string().matches(/^[0-9]{10}/ , "Nomor Handphone Anda ").required("Ketik No Hp"),
  password: yup.string().min(8, 'Minimal 8 karakter').required('tidak valid'),
  confirmpassword: yup.string().min(8, 'Minimal 8 karakter').oneOf([yup.ref('password')] , "Password tidak sama").required('Ketik Password lagi')
})

const Register = () => {
  const navigate = useNavigate();
  const {url} = AllStates();
  const [image , setImage] = useState();
  const [disable , setDisable] = useState(true);
  const { values, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
    initialValues: { userName: "", email: "", mobile: "", password: "", confirmpassword: "" },
    validationSchema: registerSchemaValidation,
    onSubmit : (newuser) => { updatePic(newuser.email); signup(newuser) }
  })

  async function updatePic(mail) {
    const formData = new FormData(); formData.append('image', image); formData.append('email', mail)
    await axios.post(`${url}/image/upload/image`, formData)
  }

  function handleChanges(e) { setImage(e.target.files[0]); setDisable(false) }
  async function signup(newuser) {
      try {
        if ((values.userName && values.email && values.mobile && values.password && values.confirmpassword) !== '') {
            console.log('Signing up to ', `${url}/users/signup`)
            await axios.post(`${url}/users/signup`,newuser).then((res) =>{ toast.success(res.data.message) ; navigate('/login')})
            .catch(error => toast.error(error.response.data.message))
          toast.success('Pendaftaran Berhasil'); navigate('/login') } 
        else { toast.error('Mohon di isi semua ya');  }
      } catch (error) { toast.error('Coba lagi nanti ya')  }
  }
  
  useEffect(() => { if(localStorage.getItem('user')){ navigate('/'); toast.warning('Logout dan coba lagi')  } })
  return (
    <div className='signup-registration row col-12' > 
      <div className='form col-md-6 bs' style={{ borderRadius: "5px" }} >
        <h1 style={{ textAlign: "center" }}>DAFTAR DI SINI</h1>
        <form onSubmit={handleSubmit}> 
           <TextField id="outlined-basic1" required label="NAMA USER" onBlur={handleBlur} variant="outlined" fullWidth margin="normal" name="userName" value={values.userName} onChange={handleChange} /> <br />
           {touched.userName && errors.userName ? <p style={{color:"red"}}>{errors.userName}</p> : "" }
          <TextField id="outlined-basic2" required label="EMAIL" variant="outlined" onBlur={handleBlur} fullWidth margin="normal" name="email" value={values.email} onChange={handleChange} /> <br />
          {touched.email && errors.email ? <p style={{color:"red"}}>{errors.email}</p> : "" }
          <TextField id="outlined-basic3" required label="No HP" variant="outlined"  onBlur={handleBlur} fullWidth margin="normal" name="mobile" value={values.mobile} onChange={handleChange} /> <br />
          {touched.mobile && errors.mobile ? <p style={{color:"red"}}>{errors.mobile}</p> : "" }
          <TextField id="outlined-basic4" required label="PASSWORD" variant="outlined" onBlur={handleBlur} fullWidth margin="normal" name="password" value={values.password} onChange={handleChange} /> <br />
          {touched.password && errors.password ? <p style={{color:"red"}}>{errors.password}</p> : "" }
          <TextField id="outlined-basic5" required label="CONFIRM PASSWORD" variant="outlined" onBlur={handleBlur} fullWidth margin="normal" name="confirmpassword" value={values.confirmpassword} onChange={handleChange} />
          {touched.confirmpassword && errors.confirmpassword ? <p style={{color:"red"}}>{errors.confirmpassword}</p> : "" } <br/>
          <div><p style={{fontWeight:'bold' , display:'inline-block'}}>PILIH FOTO PROFIL</p> &nbsp; &nbsp; 
            <input type='file' onChange={handleChanges} required /> <br /> </div>
          <button className='btn btn-primary mb-3 register-btn' type='submit'>DAFTAR</button> 
        </form>
      </div>
    </div>
  )
}
export default Register