import { React, PropTypes } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import "./Booking.css";
import axios from 'axios';
import { Checkbox, TextField } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as yup from 'yup'; 
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import { AllStates } from '../../context/Context';
//import InfoIcon from '@mui/icons-material/Info';
//import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import { Select, Tag } from 'antd';
import { DatePicker } from "react-rainbow-components";
import { TimePicker } from "react-rainbow-components";

// Service at home
var HomeServiceRegisterSchemaValidation = yup.object({
    userName: yup.string().min(3, 'nama minimal 3 huruf').required("Ketik nama anda"),
    mobile: yup.string().matches(/^[0-9]{10}/, "Ketik No HP yg masih berlaku").required("Ketik No HP"),
    email: yup.string().email().required("Ketik Email"),
    address1: yup.string().required("Ketik Alamat"),
    address2: yup.string().required('ketik kota anda'),
    nopol: yup.string().required('ketik Plat Nomer motor'),
    bikeCompany: yup.string().required('Pilih Merek'),
    model: yup.string().required('Pilih tipe motor'),
    cc: yup.string().required('Ketik cc motor'),
    odometer: yup.string().required('Ketik km motor'),
    serviceTime: yup.string().required('Pilih slot waktu'),
    serviceDate: yup.string().required('Pilih slot tanggal'),
    serviceType: yup.array().required('Centang yg diinginkan'),
});

// Service at garage
var registerSchemaValidation = yup.object({
    userName: yup.string().min(3, 'nama minimal 3 huruf').required("Ketik nama anda"),
    mobile: yup.string().matches(/^[0-9]{10}/, "Ketik No HP yg masih berlaku").required("Ketik No HP"),
    email: yup.string().email().required("Ketik Email"),
    address1: yup.string().required("Ketik Alamat"),
    address2: yup.string().required('ketik kota anda'),
    nopol: yup.string().required('ketik Plat Nomot motor'),
    bikeCompany: yup.string().required('Pilih Merek'),
    model: yup.string().required('Pilih tipe motor'),
    cc: yup.string().required('Ketik cc motor'),
    odometer: yup.string().required('Ketik km motor'),
    serviceTime: yup.string().required('Pilih slot waktu'),
    serviceDate: yup.string().required('Pilih slot tanggal'),
    serviceType: yup.array().required('Centang yg diinginkan'),
});

const Booking = () => {
    const initTime   = "12:00";
    const initDate   = "2024-01-12T17:00:00.000Z";
    const locale = { name: 'id', label: 'Indonesian' };
    const [selectedServices, setSelectedServices] = useState();
    const [disable, setDisable] = useState(false);
    const [checked, setChecked] = useState(true); //checkbox
    const today = dayjs();
    const [date, setDate] = useState(initDate);
    const [time, setTime] = useState(initTime);
    const { bikes, services, url } = AllStates();
    const bikeAry = []; const serviceArray = [];
    let brand = []; let tipeServis = [];
    const timeslot = ["09:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00"];
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    function gotoProfile() { navigate('/profile') };
    function gotoLogin() { navigate('/login') };

    // formik function
    const { values, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
        initialValues: {
            userName: user.userName, address1: user.address1, mobile: user.mobile, email: user.email, 
            address2: user.address2, nopol: "", serviceType: ["", ""], totalHarga: "Rp. 0,-", bikeCompany: '', model: '', 
            cc: '', odometer: '', serviceTime: '', serviceDate: '', serviceCategory: 'servis-ringan',
        },
        validationSchema: checked ? HomeServiceRegisterSchemaValidation : registerSchemaValidation,
        onSubmit: (bookingData, onSubmitProps) => {
            console.log("OnSubmit Trying to submit data");
            sendData(bookingData); 
            onSubmitProps.resetForm();
            setDisable(false)
        }
    });

    function handleDate(value) { console.log("date = ", value); setDate(value); }
    function handleTime(value) { console.log("time = ", value); setTime(value); }
    function unique(array) { return array.filter((item, index) => array.indexOf(item) === index) };
    function checkbox(val) { setChecked(val) };
    bikes && bikes.map((i) => bikeAry.push(i.bikeCompany));
    brand = unique(bikeAry);
    function selectServices(bikeCom) {
        services && services.map((i) => bikeCom === i.bikeCompany ? serviceArray.push({ value: i.serviceType })  : '');
        return serviceArray;
    }

    function updateServices(updatedSelection) {
        //console.log("updated selection = ", updatedSelection);
        setSelectedServices(updatedSelection);
        let total = 0; let selServis = updatedSelection;
        console.log("updated selection = ", selServis);
        values.serviceType = selServis;
        selServis && selServis.map( (j) => {
            services && services.map( (i) => { 
                if ( i.serviceType === j) {
                    console.log("harga i = ", i.price);
                    const harga = parseInt(i.price); total += harga; 
                }
            });
        });
        total = Math.floor(total / 1000); let totalStr = "Rp. "+ total.toString() + ".000,-";
        console.log("total = ", totalStr);
        values.totalHarga = totalStr;
    }

    async function handleDataSubmit() {
        setSelectedServices({}); values.serviceType = ["", ""];
        console.log("OnPress Trying to submit these values");
        console.log(values);
        try {
            const newOrder = await axios.post(`${url}/bookings/addBooking`, values)
            .then( res => {
              let resOrder = res.data.response; console.log("New Booking = ", resOrder);
              //gotoProfile();
            })
            .catch(error => toast.error(error.response.data.message));
        }
        catch (error) { toast.error(error.response.data.message) };
    };

    // fetching order id from backend
    async function sendData(bookingData) {
        console.log("Function sendData Sending booking data = ", bookingData);
        try {
            const newOrder = await axios.post(`${url}/bookings/addBooking`, bookingData)
            .then( res => { 
                let resOrder = res.data.response; console.log("New Booking = ", resOrder); 
                gotoProfile(); window.location.reload(); 
            })
            .catch(error => toast.error(error.response.data.message));
        }
        catch (error) { toast.error(error.response.data.message) };
    };

    function tagRender(props) {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
            <Tag className="site-tag-plus" 
            onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}
            style={{ marginRight: 3, }}> {label} </Tag>
        );
    };

    useEffect(() => {
        if (!user) { gotoLogin() }; //console.log("No gotoLogin user = ", user);
        if (values.bikeCompany.length > 2) { setDisable(true) } }, [values.model])

    return (
        <div className='bookings-form'>
            <form onSubmit={handleSubmit} style={{ backgroundColor: 'transparent' }}>
                <div className='form-filling row col-12'>
                    <div className='left col-12 col-md-4' style={{padding:'10px'}}>
                        <TextField id="outlined-basic1" required label="NAMA" onBlur={handleBlur} variant="outlined" key="NAMA"
                            fullWidth margin="normal" name="userName" value={values.userName} onChange={handleChange} />
                            {touched.userName && errors.userName ? <p className="error-msg" style={{ color: "red" }}>{errors.userName}</p> : ""}
                        <TextField id="outlined-basic3" required label="No HP" variant="outlined" onBlur={handleBlur} key="No HP"
                            inputProps={{maxLength:'10'}} fullWidth margin="normal" name="mobile" value={values.mobile} onChange={handleChange} />
                            {touched.mobile && errors.mobile ? <p className="error-msg" style={{ color: "red" }}>{errors.mobile}</p> : ""}
                        <TextField id="outlined-basic2" required label="EMAIL" variant="outlined" onBlur={handleBlur} key="EMAIL"
                            fullWidth margin="normal" name="email" value={values.email} onChange={handleChange} />
                            {touched.email && errors.email ? <p className="error-msg" style={{ color: "red" }}>{errors.email}</p> : ""}
                        <TextField id="outlined-basic2" required label="Alamat" variant="outlined" onBlur={handleBlur} key="Alamat"
                            fullWidth margin="normal" name="address1" value={values.address1} onChange={handleChange} disabled={!checked} />
                            {touched.address1 && errors.address1 ? <p className="error-msg" style={{ color: "red" }}>{errors.address1}</p> : ""}
                        <TextField id="outlined-basic4" required label="Kota" variant="outlined" onBlur={handleBlur} key="Kota"
                            fullWidth margin="normal" name="address2" value={values.address2} onChange={handleChange} disabled={!checked} />
                            {touched.address2 && errors.address2 ? <p className="error-msg" style={{ color: "red" }}>{errors.address2}</p> : ""}
                    </div>
                    <div className='right col-12 col-md-4' style={{margin:'0' , padding:'10px'}}>
                        <TextField id="outlined-basic5" required label="No Pol" variant="outlined" inputProps={{maxLength:'10'}} key="No Pol"
                            fullWidth margin="normal" name="nopol" value={values.nopol} onChange={handleChange} disabled={!checked} />
                            {touched.nopol && errors.nopol ? <p className="error-msg" style={{ color: "red" }}>{errors.nopol}</p> : ""}
                        <TextField select id="bikeCompany" label="Merek" variant="outlined" onBlur={handleBlur} key="Merek"
                            fullWidth margin="normal" name="bikeCompany" value={values.bikeCompany} onChange={handleChange}
                            error={touched.bikeCompany && Boolean(errors.bikeCompany)} helperText={touched.bikeCompany && errors.bikeCompany} disabled={disable}  >
                            <MenuItem key={"1"} value={""} disabled> --Pilih merk motor--  </MenuItem>
                            {brand.map((i) => <MenuItem value={i} key={i._id} > {i} </MenuItem>)}
                        </TextField>
                        <TextField select id="model" label="Tipe" variant="outlined" onBlur={handleBlur} key="Tipe"
                            fullWidth margin="normal" name="model" value={values.model} onChange={handleChange}
                            error={touched.model && Boolean(errors.model)} helperText={touched.model && errors.model} >
                            <MenuItem key={"2"} value={""} disabled> --pilih tipe motor--  </MenuItem>
                            {bikes && bikes.map((i) => values.bikeCompany && values.bikeCompany === i.bikeCompany ? 
                            <MenuItem value={i.model} key={i._id}> {i.model} </MenuItem> : '')}
                        </TextField>
                        <TextField id="outlined-basic2" required label="cc" variant="outlined" onBlur={handleBlur} key="cc"
                            fullWidth margin="normal" name="cc" value={values.cc} onChange={handleChange} disabled={!checked} />
                            {touched.cc && errors.cc ? <p className="error-msg" style={{ color: "red" }}>{errors.cc}</p> : ""}
                        <TextField id="outlined-basic4" required label="Odometer" variant="outlined" onBlur={handleBlur} key="Odometer"
                            fullWidth margin="normal" name="odometer" value={values.odometer} onChange={handleChange} disabled={!checked} />
                            {touched.odometer && errors.odometer ? <p className="error-msg" style={{ color: "red" }}>{errors.odometer}</p> : ""}
                    </div>
                </div>
                <div className='form-filling row col-12'> 
                    <div className='left col-12 col-md-4' style={{margin:'0' , padding:'10px'}}> 
                        <DatePicker id="controlled" value={date} onChange={handleDate} label={'Pilih Tanggal'} locale={locale.name}
                                formatStyle="large" borderRadius="semi-square" placeholder={'Pilih Tanggal Servis'} />
                        <TextField select id="serviceTime" label="Slot Waktu" variant="outlined" onBlur={handleBlur} key="Slot Waktu"
                            fullWidth margin="normal" name="serviceTime" value={values.serviceTime} onChange={handleChange}
                            error={touched.serviceTime && Boolean(errors.serviceTime)} helperText={touched.serviceTime && errors.serviceTime} >
                            <MenuItem key={"3"} value={""}> --select slot waktu--  </MenuItem>
                            {timeslot.map((i) => <MenuItem value={i} key={i._id}> {i} </MenuItem>)}
                        </TextField>
                    </div>   
                    <div className='right col-12 col-md-4' style={{margin:'0' , padding:'20px'}}> 
                        <TextField id="outlined-basic2" required label="Perkiraan Harga" variant="outlined" onBlur={handleBlur} key="harga"
                            fullWidth margin="normal" name="totalHarga" value={values.totalHarga} onChange={handleChange} />
                            {touched.totalHarga && errors.totalHarga ? <p className="error-msg" style={{ color: "red" }}>{errors.totalHarga}</p> : ""}

                        <span id="dd1">Pilih tipe servis</span>   
                        <Select mode="multiple" showArrow value={selectedServices} onChange={(e) => updateServices(e)} 
                            style={{ width: '100%', }} tagRender={tagRender} options={ selectServices( values.bikeCompany ) }
                        />
                    </div>
                </div>
                <FormControlLabel control={<Checkbox onChange={(e) => checkbox(e.target.checked)} checked={checked} />} label="Home Service" />
                <div className='form-btn'>
                    {/*<button className='btn btn-primary mb-3 register-btn' type='submit' onClick={handleDataSubmit} >PESAN SEKARANG</button>*/}
                    <button className='btn btn-primary mb-3 register-btn' type='submit' >PESAN SEKARANG</button>
                </div>
                <div style={{height:"500px"}}><p>
                     <></>
                     <></>
                </p></div>
            </form>
        </div>
    )
}

export default Booking