import React, { useState } from 'react';
import { AllStates } from '../../context/Context';
import Table from 'react-bootstrap/Table';
import Loading from '../../components/Loading/Loading';
import Carousell from '../../components/Carousel/Carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faSquarePen } from '@fortawesome/free-solid-svg-icons';

function Tab1Caraosel () {
    const{images, url} = AllStates();
    function handleChange() { console.log("OnChange event") };
    function updatePic() { console.log("OnClick event") };

    return (
        <div>
            <div><Carousell /></div>
            <div className='m-3'>
            <div><p style={{ fontSize: '30px' , fontWeight:'bold' }}>Ganti gambar caraosel</p></div>

            { images ? ( 
                images.caraosel.map( (item, index) => {
                    return (
                        <Table striped className='mb-0 mt-0 fixWidth' variant='info' hover responsive key={'table'+index}>
                        <thead ></thead>              
                        <tbody>
                        <tr key={index+'row'}>
                            <td key={index+'imgfull'}><div className='row'>
                                <div><img className="fullsize" alt={images.caraosel_text[index]}
                                    src={url+"/images/caraosel/"+item} 
                                    /></div>
                                <div><p style={{ fontSize: 'larger', fontWeight: 'bold', textAlign: 'center' }}>
                                    {images.caraosel_text[index]}</p></div>
                                </div>
                            </td>
                            <td key={index+'imghalf'}>
                                <img className="halfsize" src={url+"/images/caraosel/"+item} 
                                    alt={images.caraosel_text[index]}></img>
                                <div className='upload-button m-3' key={index+'btn'}>
                                <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>GANTI FOTO 
                                <span><FontAwesomeIcon icon={faSquarePen} /></span> </p></div> 
                                <div key={index+'input'}>
                                    <input type='file' onChange={handleChange} /> <br />
                                    <button onClick={updatePic} className='btn btn-success m-2'> UPLOAD 
                                    <span><FontAwesomeIcon icon={faUpload} /></span>
                                    </button>
                                </div>
                                </div>
                            </td>
                            <td  key={index+'txt1'}>{images.caraosel_text[index]}
                                <div className='upload-button m-3'>
                                <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>GANTI KATA-KATA 
                                <span><FontAwesomeIcon icon={faSquarePen} /></span> </p></div> 
                                <div>
                                    <input onChange={handleChange} /> <br />
                                    <button onClick={updatePic} className='btn btn-success m-2'> UPLOAD 
                                    <span><FontAwesomeIcon icon={faUpload} /></span>
                                    </button>
                                </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        </Table>
                    )
                })  )  : <Loading/> }
            <Table striped className='mb-0 mt-0 fixWidth' variant='info' hover responsive>
            <thead ></thead><tbody><tr>
                <td key="AddFull"><img className="fullsize" src={url+"/images/add_photo.png"} alt="Add Photo"/></td>
                <td key="AddHalf"><img className="halfsize" src={url+"/images/add_photo.png"} alt="Add Photo"></img>
                    <div className='upload-button m-3' key='btnHalf'>
                        <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>TAMBAH FOTO </p></div> 
                        <div>
                        <input type='file' onChange={handleChange} /> <br />
                        <button onClick={updatePic} className='btn btn-success m-2'> UPLOAD 
                            <span><FontAwesomeIcon icon={faUpload} /></span>
                        </button>
                        </div>
                    </div>
                    </td>
                    <td key='inputHalf'>
                    <div className='upload-button m-3'>
                        <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>TAMBAH KATA-KATA </p></div> 
                        <div>
                        <input onChange={handleChange} /> <br />
                        <button onClick={updatePic} className='btn btn-success m-2'> UPLOAD 
                            <span><FontAwesomeIcon icon={faUpload} /></span>
                        </button>
                        </div>
                    </div>
                    </td>
                </tr></tbody>
            </Table>
            </div>
        </div>
    )
}
export default Tab1Caraosel;