import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import "../../components/Booking/Booking.css";
import "../../routes/profile/Profile";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPen } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import * as yup from 'yup'; 
import { useFormik } from 'formik';
import { toast } from 'react-toastify'
import { AllStates } from '../../context/Context';
import MenuItem from '@mui/material/MenuItem'; 
import Adminnav from './Adminnav'
import "./Admin.css";
import { Select, Tag } from 'antd';

// Service at home
var SchemaValidation = yup.object({
    servItemName: yup.string().required("Ketik Rincian Servis"),
    servItemPrice: yup.string().matches(/^[0-9]*$/).required("Ketik Harga Servis"),
    transmisi: yup.string().required("Pilih manual / matic"),
    serviceCategory: yup.string().required("Plih Kategoti Servis"),
});

const AdminUpdateBike = () => {
  const [selectedServices, setSelectedServices] = useState();
  const [disableBtnUpload, setDisableBtnUpload] = useState(true);
  const [disableBtnUpdate, setDisableBtnUpdate] = useState(true);
  const [fileImage, setFileImage] = useState();
  let servItemData = JSON.parse(localStorage.getItem('servItemData'));
  const { url } = AllStates();
  const transmisiArray = ['manual/matic', 'manual', 'matic'];
  const kategoryArray = ['servis-ringan','servis-sedang','servis-berat'];
  const itemArray = [{value: 'servis-ringan'},{value: 'servis-sedang'},{value: 'servis-berat'}];
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  function gotoSettings() { navigate('/admin/set/gambar') };
  function gotoLogin() { navigate('/login')};
  console.log("id item = ", servItemData._id);

  // formik function
  const { values, setFieldValue, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
      initialValues: {
          _id: servItemData._id, servItemName: servItemData.servItemName, servItemPrice: servItemData.servItemPrice, 
          serviceCategory: servItemData.serviceCategory, transmisi: servItemData.transmisi, 
          apliesTo: servItemData.apliesTo, servItemURL: servItemData.servItemURL
      },
      validationSchema: SchemaValidation,
      onSubmit: (itemData, onSubmitProps) => {
          values.apliesTo = selectedServices;
          console.log("selected = ", selectedServices);
          sendData(itemData); 
          onSubmitProps.resetForm();
          setDisableBtnUpdate(true)
      }
  });

  async function sendData(itemData) {
      //console.log("Function sendData Sending item data = ", itemData);
      try {
          await axios.post(`${url}/services/service/item/update`, itemData)
          .then( res => { 
            gotoSettings(); 
            localStorage.removeItem('servItemData'); 
            localStorage.setItem("activeTab", JSON.stringify('rincianServis'));
            window.location.reload();  })
          .catch(error => toast.error(error.response.data.message));
      } catch (error) { toast.error(error.response.data.message) };
  };

  function handleOnChange(e) {
    const key = e.target.name;
    const value = e.target.value || "";

    setDisableBtnUpdate(false);
    switch (key) {
      case 'transmisi' : setFieldValue('transmisi', value.toLowerCase()); return;
      default : setFieldValue(key, value); return;
    }
  };
  
  function handlePicChange(e) {
    setFileImage(e.target.files[0]);
    setDisableBtnUpload(false); 
    setDisableBtnUpdate(false); 
  };
  async function updatePic() {
      const formData = new FormData()
      formData.append('file', fileImage)

      try {
        await axios.post(`${url}/images/new/imagefor/services`, formData)
        .then(res => { 
          setFieldValue('servItemURL', res.data.keyURL);
          setDisableBtnUpdate(false);
        })
        .catch(error => toast.error(error.response.data.message));    } 
      catch (error) { toast.error(error.response.data.message) };  
  };

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
        <Tag className="site-tag-plus" 
        onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}
        style={{ marginRight: 3, }}> {label} </Tag>
    );
};

  useEffect(() => { if (!user) { gotoLogin() }; }, [user]);
  return (
    <div className='set-harga-page'><Adminnav />
    <div className='mt-3' >
      <div> <h3 style={{margin:'10px'}}>Update Rincian Servis</h3> </div>
      <div className='profile-picture-container col-12'>
          <div className='picture col-11 col-md-4'>
            <img src={servItemData.servItemURL} alt="gambar" height='100%'/>
          </div>
          <div className='upload-button m-3'>
              <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>GANTI FOTO SERVIS 
              <span><FontAwesomeIcon icon={faPen} /></span> </p></div> 
              <div>
                <input type='file' onChange={handlePicChange} /> <br />
                <button onClick={updatePic} className='btn btn-success m-2' disabled={disableBtnUpload}>
                   UPLOAD <span><FontAwesomeIcon icon={faUpload} /></span>
                </button>
              </div>
            </div>
        </div>
        <div className='bookings-form'>
          <form onSubmit={handleSubmit} style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div className='form-filling row col-12'>
              <div className='left col-12 col-md-4' style={{padding:'10px'}}>
                  <TextField id="outlined-uncontrolled" label="Rincian Servis" variant="outlined" onBlur={handleBlur} key="servItemName"
                      fullWidth margin="normal" name="servItemName" value={values.servItemName} onChange={handleOnChange}
                      error={touched.servItemName && Boolean(errors.servItemName)} helperText={touched.servItemName && errors.servItemName} >
                  </TextField>
                  <TextField id="outlined-uncontrolled" label="Harga" variant="outlined" onBlur={handleBlur} key="servItemPrice"
                      fullWidth margin="normal" name="servItemPrice" value={values.servItemPrice} onChange={handleOnChange}
                      error={touched.servItemPrice && Boolean(errors.servItemPrice)} helperText={touched.servItemPrice && errors.servItemPrice} >
                  </TextField>
                  <TextField select id="outlined-uncontrolled" label="Trasnmisi" variant="outlined" onBlur={handleBlur} key="Transmisi"
                      fullWidth margin="normal" name="transmisi" value={values.transmisi} onChange={handleOnChange} 
                      error={touched.transmisi && Boolean(errors.transmisi)} helperText={touched.transmisi && errors.transmisi} >
                      {transmisiArray.map((i) => <MenuItem value={i} key={i}> {i} </MenuItem>)}
                  </TextField>
                </div>
                <div className='right col-12 col-md-4' style={{margin:'0' , padding:'10px'}}>
                  <TextField id="outlined-uncontrolled" required label="Kategori" variant="outlined" onBlur={handleBlur} key="cserviceCategoryc"
                      fullWidth margin="normal" name="serviceCategory" value={values.serviceCategory} onChange={handleOnChange} >
                      {touched.serviceCategory && errors.ccserviceCategory ? <p className="error-msg" style={{ color: "red" }}>{errors.serviceCategory}</p> : ""}
                      {kategoryArray.map((i) => <MenuItem value={i} key={i}> {i} </MenuItem>)}
                  </TextField>

                  <span id="dd1" className='mt-2'>Bisa masuk kelompok </span>   
                    <Select mode="multiple" value={selectedServices} onChange={(e) => {setSelectedServices(e); setDisableBtnUpdate(false);}} 
                        style={{ width: '100%', }} tagRender={tagRender} options={itemArray} defaultValue={values.apliesTo}
                    />
                </div>
                <div className='container-set-harga' ><div className='form-btn'>
                    <button className='btn btn-primary mb-3 register-btn' type='submit' disabled={disableBtnUpdate}>
                      UPDATE RINCIAN SERVIS</button>
                </div></div>
            </div>
          </form>
          </div>
        </div>
      </div>
    )
}

export default AdminUpdateBike