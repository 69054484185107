import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { TextField } from '@mui/material'
import '../admin/Admin.css'
import { toast } from 'react-toastify'
import Adminnav from './Adminnav'
import { useNavigate } from 'react-router-dom'
import { AllStates } from '../../context/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPen } from '@fortawesome/free-solid-svg-icons';
import { Select, Tag } from 'antd';
import MenuItem from '@mui/material/MenuItem'; 

const AdminAddServiceItem = () => {
    const {url} = AllStates();
    const [disableBtnUpload, setDisableBtnUpload] = useState(true);
    const [disableBtnUpdate, setDisableBtnUpdate] = useState(true);
    const [fileImage, setFileImage] = useState();
    const [servItemName, setservItemName] = useState(''); 
    const [servItemPrice, setservItemPrice] = useState('');
    const [servItemURL, setservItemURL] = useState('url+"/images/add_photo.png"');
    const [serviceCategory, setserviceCategory] = useState('');
    const [transmisi, settransmisi] = useState('');
    const [apliesTo, setapliesTo] = useState('');
    const navigate = useNavigate();
    const transmisiArray = ['manual/matic', 'manual', 'matic'];
    const kategoryArray = ['servis-ringan','servis-sedang','servis-berat'];
    const itemArray = [{value: 'servis-ringan'},{value: 'servis-sedang'},{value: 'servis-berat'}];

    async function savedata() {
        const newBike = { servItemName, servItemPrice, servItemURL, serviceCategory, transmisi, apliesTo }
        try { await axios.post(`${url}/services/service/item/add`, newBike)
            .then(res => res.data.response ? toast.success('Berhasil') : toast.error('Gagal'))
        } catch (error) { console.log('error menambah motor', error); }
        localStorage.setItem('activeTab', JSON.stringify('rincianServis'));
        navigate('/admin/set/gambar'); window.location.reload();
    };
    function handleChange(e) {
        setFileImage(e.target.files[0]);
        setDisableBtnUpload(false);  
    };
    async function updatePic() {
        const formData = new FormData()
        formData.append('file', fileImage)
    
        try {
          await axios.post(`${url}/images/new/imagefor/services`, formData)
          .then(res => { setservItemURL(res.data.keyURL)})
          .catch(error => toast.error(error.response.data.message));    } 
        catch (error) { toast.error(error.response.data.message) };  
    };

    function tagRender(props) {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
            <Tag className="site-tag-plus" 
            onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}
            style={{ marginRight: 3, }}> {label} </Tag>
        );
    };    

    useEffect(() => { if (JSON.parse(localStorage.getItem('user')).role !== 'admin') { navigate('/') } }, []);
    return (
        <div>
            <Adminnav />
            <div className='profile-picture-container col-12'>
            <div className='picture col-11 col-md-4'><img src={servItemURL} alt="gambar" /></div>
            <div className='upload-button m-3'>
                <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>UPLOAD FOTO  
                <span><FontAwesomeIcon icon={faPen} /></span> </p></div> 
                <div>
                    <input type='file' onChange={handleChange} /> <br />
                    <button onClick={updatePic} className='btn btn-success m-2' disabled={disableBtnUpload}> 
                        UPLOAD <span><FontAwesomeIcon icon={faUpload} /></span>
                    </button>
                </div>
                </div>
            </div>
            <div className='add-new-bike'>
                <p className='add-bike-heading mt-3'> TAMBAH RINCIAN SERVIS</p>
                <TextField className="mb-3 mt-3" label="Rincian Servis" variant="outlined" color="secondary" value={servItemName} onChange={(e) => setservItemName(e.target.value)} style={{ width: "300px" }} />
                <TextField className="mb-3" label="Harga" variant="outlined" color="secondary" value={servItemPrice} onChange={(e) => setservItemPrice(e.target.value)} style={{ width: "300px" }} />
                <TextField select className="mb-3" label="Untuk motor" variant="outlined" color="secondary" value={transmisi} onChange={(e) => settransmisi(e.target.value)} style={{ width: "300px" }} >
                    {transmisiArray.map((i) => <MenuItem value={i} key={i}> {i} </MenuItem>)}</TextField>
                <TextField select className="mb-3" label="Kategori" variant="outlined" color="secondary" value={serviceCategory} onChange={(e) => setserviceCategory(e.target.value)} style={{ width: "300px" }} >
                    {kategoryArray.map((i) => <MenuItem value={i} key={i}> {i} </MenuItem>)}</TextField>
                <div className='add-new-bike mb-2'>
                    <span id="dd1" className='mt-2'>Bisa masuk kelompok </span>   
                    <Select mode="multiple" value={apliesTo} onChange={(e) => {setapliesTo(e); setDisableBtnUpdate(false);}} 
                        style={{ width: '100%', }} tagRender={tagRender} options={itemArray} defaultValue={apliesTo}/>
                </div>
                <button className="mb-5 mt-2 btn btn-primary" color="secondary" onClick={() => savedata()}> SIMPAN </button>
            </div>
        </div>
    )
}
export default AdminAddServiceItem