import React from 'react'
const Adminnav = () => {
    return (
        <div>
            <nav className='navbar navbar-expand-lg navbar-dark bg-info '>
                <div className='container'>
                    <img src="https://gngmotorservice.com/images/favicon.ico" alt="ico" width="20" />
                    <a href="/" className='navbar-brand'> ADMIN  </a>
                    <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navv'> <span className='navbar-toggler-icon'></span> </button>
                    <div className='collapse navbar-collapse' id='navv'>
                        <ul className='navbar-nav'>
                            <li className='nav-item '><a href="/admin/dashboard" className='nav-link'> Dashboard </a></li>
                            <li className='nav-item '><a href="/admin/users" className='nav-link'> Users </a> </li>
                            <li className='nav-item '><a href="/admin/bikes" className='nav-link '> Motors </a></li>
                            <li className='nav-item '><a href="/admin/services" className='nav-link'> Services </a></li>
                            <li className='nav-item '><a href="/admin/set/gambar" className='nav-link '> Settings </a></li>
                            
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Adminnav