import React from 'react'
import '../Footer/Footer.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWordpress, faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
    const navigate = useNavigate();
    function gotoContact(){ navigate('/contact') }

    return (
        <div className='footer'>
            <div className='address '>
                <h3>Lokasi</h3>
                <span className='address'>Jl. Jendral Ahmad Yani No. 79</span>
                <span className='address'>Cempaka Putih Timur</span>
                <span className='address'>Jakarta Pusat</span>
                <span className='address'>10510</span>
            </div>
            <div className='social '>
                <div className='footer-content'>
                    <h3>Medsos</h3>
                    <a className="anchor" href='https://gngmotorservice.wordpress.com/'>
                    <span><FontAwesomeIcon icon={faWordpress} /></span> Blog</a>
                    <a className="anchor" href='https://www.facebook.com/people/GnG-Motor-Services/61555208574827/'>
                    <span><FontAwesomeIcon icon={faFacebook} /></span> Facebook</a>
                    <a className="anchor" href='https://www.instagram.com/gngmotorservice/'>
                    <span><FontAwesomeIcon icon={faInstagram} /></span> Instagram</a>
                    <a className="anchor" href='https://twitter.com/GnGmotorservice'>
                    <span><FontAwesomeIcon icon={faTwitter} /></span> Twitter</a>
                </div>
            </div>
            <div className='company'>
                <div className='footer-content'>
                    <h3>Perusahaan</h3>
                    <a className="anchor" href='#'>Ttg Kami</a>
                    <a className="anchor" href='#'>Karir</a>
                    <a className="anchor" href='#'>Syarat & Kondisi</a>
                    <a className="anchor" href='#'>Kebijakan</a>
                </div>
            </div>
            <div className='contact'>
                <h3>KONTAK</h3>
                <button className='btn btn-success' onClick={gotoContact}>KLIK DISINI</button>
            </div>
        </div>
    )
}

export default Footer