import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../admin/Admin.css'
import Adminnav from './Adminnav'
import Tab1Caraosel from './AdminTab1Caraosel';
import Tab3RincianServis from './AdminTab3RincianServis';
import { useNavigate } from 'react-router-dom';

const AdminSetGambar = () => {
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState('rincianServis');
    
    useEffect(() => { 
      if (JSON.parse(localStorage.getItem('user')).role !== 'admin') { navigate('/') };
      const getActiveTab = JSON.parse(localStorage.getItem("activeTab"));
      if (getActiveTab) { setCurrentTab(getActiveTab); }
     }, [])

    useEffect(() => {
      localStorage.setItem("activeTab", JSON.stringify(currentTab));
    }, [currentTab]);

    return (
      <div>
        <div><Adminnav />
        <div><h3 style={{margin:'10px'}}>Setting Gambar </h3></div>
        <div>
          <Tabs id="justify-tab" className="mb-3 tablink" justify
                  activeKey={currentTab} onSelect={(k) => setCurrentTab(k)}>
              <Tab eventKey="rincianServis" title="Rincian Servis" ><Tab3RincianServis /></Tab>
              <Tab eventKey="caraosel" title="Caraosel" ><Tab1Caraosel /></Tab>
              <Tab eventKey="homepage" title="Homepage" >Tab content Homepage</Tab>
              <Tab eventKey="servisRingan" title="Servis Ringan" >Tab content for servisRingan</Tab>
              <Tab eventKey="servisBerat" title="Servis Berat" >Tab content for servisBerat</Tab>
          </Tabs></div>
          
        </div>
      </div>
    )
}
export default AdminSetGambar