import React, { useState, useEffect } from 'react'
import '../repair/Repair.css'
import { toast } from 'react-toastify'
import { AllStates } from '../../context/Context';
//import InfoIcon from '@mui/icons-material/Info';
//import Tooltip from '@mui/material/Tooltip';
import Mininav from '../../components/Navigation/Mininav';
import Booking from '../../components/Booking/Booking';
import { useNavigate } from 'react-router-dom';

const Repair = () => {
    const {url} = AllStates();
    const url_address = `${url}/bookings/repair/service/addbooking`;
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    useEffect(() => { if (!user) { navigate('/'); toast.warning('Please Login') }  }, [])

    return (
        <div className='repair-service-page'>
            <Mininav />
            <div className='header'>  <img src={require('./images/pulse.gif')} alt="pulse" />  </div>
            <h1> Motor Anda Rusak? Jangan khawatir, kita datang tuk memperbaikinya <span> 🧑‍🔧  </span> </h1>
            <div className='details_and_instructions row '>
                <div className='instructions col-10 col-md-5'>
                    <h3 className='instructions-text' style={{textAlign:'center'}}> Pekerjaan Perbaikan </h3>
                    <p className='instructions-text'> Kami menawarkan kemudahan memanfaatkan semua jenis perbaikan sampai ke rumah anda </p>
                    <p className='instructions-text'>  Sering kali Anda menghadapi masalah yg mengganggu dan Anda tidak benar-benar tahu apa yg salah
                         yg memerlukan perbaikan atau penggantian suku cadang yang cepat. Dalam 90% kasus, Anda tidak akan tahu bagian mana
                         atau perbaikan diperlukan untuk memperbaiki masalah Anda. Kami telah membangun teknologinya
                         dan keahlian untuk membantu Anda mengidentifikasi masalah dan juga kemungkinan solusinya. 
                         Dalam 90% kasus, Mekanik kami selesaikan masalahnya pada kunjungan pertama.</p>

                    <p className='instructions-text'>
                         Anda juga dapat meminta penggantian suku cadang tertentu atau perbaikan yg secara khusus untuk dilakukan.
                    </p>

                    <h4 className='instructions-text'>Pengecualian:</h4>
                    <p className='instructions-text'>
                         Untuk masalah yang berkaitan dengan mesin atau transmisi, mungkin perlu diperiksa di bengkel pusat kami.
                         Tapi jangan khawatir! Kami dapat melakukan layanan Ambil and Antar dengan biaya nominal.
                    </p>
                </div>
                <div className='repair-images col '>
                <div className='repair-img col-10 col-md-10'>
                    <img src={require('./images/repair.webp')} alt="repair image" />
                </div>
                <div className='pickup-img col-10 col-md-5'>
                <img src={require("./images/pickup.png")} />
            </div>
            </div>
            </div>

            <Booking url_address={url_address} serviceType={"overhaul"}  />

        </div>
    )
}

export default Repair