import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllStates } from '../../context/Context';
import Table from 'react-bootstrap/Table';
import Loading from '../../components/Loading/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faSort } from '@fortawesome/free-solid-svg-icons';
import '../admin/Admin.css'

function Tab3RincianServis () {
    const{servItems} = AllStates();
    const navigate = useNavigate();
    var serial_number = 0; // serial number for table
    const [psearch, setPsearch] = useState('');
    const [sortCriteria, setSortCriteria] = useState('_id');
    const [isDescending, setIsDescending] = useState(true);
    function gotoAddServItem() { navigate('/admin/add/service/item') };
    function gotoUpdateServItem() { navigate('/admin/update/service/item') };

    async function handleRowUpdate(e, data) {
        e.persist();
        localStorage.setItem('servItemData', JSON.stringify(data) );
        console.log("Service Item Data to be updated = ", localStorage.getItem('servItemData'));
        gotoUpdateServItem();
    }
    return (
        <div>
        <div> <h3 style={{margin:'10px'}}>Daftar Rincian Item Servis</h3> </div>
        <div className="row-for-two-cols">
        <section className="container-for-two-cols">
            <div className="left-half">
                <input type='search' placeholder='Cari...' value={psearch} onChange={e => setPsearch(e.target.value)} /> 
            </div> 
            <div className="right-half">
                <button className="button button1" onClick={gotoAddServItem}>Tambah Rincian Servis</button>
            </div>
        </section>
        </div> 
        <div>   
            {/* bikes list */}
            { servItems ? (  <Table striped className='mb-3 mt-3' variant='info' hover responsive>
                <thead >
                    <tr>
                        <th className={"w-3"}>No</th>
                        <th  className={"w-20"}><button key='servItemName' className='button-transparent'  
                            onClick={() => { setSortCriteria('servItemName'); setIsDescending(!isDescending); }}>
                            Rincian Servis  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                        <th className={"w-10"}><button key='servItemPrice' className='button-transparent' 
                            onClick={() => { setSortCriteria('servItemPrice'); setIsDescending(!isDescending); }}>
                            Harga  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                        <th className={"w-10"}><button key='transmisi' className='button-transparent' 
                            onClick={() => { setSortCriteria('transmisi'); setIsDescending(!isDescending); }}>
                            Buat Motor  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                        <th className={"w-10"}><button key='serviceCategory' className='button-transparent' 
                            onClick={() => { setSortCriteria('serviceCategory'); setIsDescending(!isDescending); }}>
                            Kategori  <span><FontAwesomeIcon icon={faSort} /></span></button></th>
                        <th className={"w-5"}>Bisa untuk</th>
                        <th className={"w-22"}>Gambar</th>
                        <th className={"w-10"}>Command</th>
                    </tr>
                </thead>
                <tbody>
                    {/* sort date */}
                    {servItems && servItems.sort(function (a, b) {
                        if (isDescending) {
                            if ( a[sortCriteria] < b[sortCriteria] ) return 1;
                            if ( a[sortCriteria] > b[sortCriteria] ) return -1;
                        } else {
                            if ( a[sortCriteria] < b[sortCriteria] ) return -1;
                            if ( a[sortCriteria] > b[sortCriteria] ) return 1;
                        }                           
                        return 0;
                })
                    .filter(item => {
                        if (psearch === '') { return item } 
                        else if (item.servItemName.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                        else if (item.servItemPrice.toLowerCase().includes(psearch.toLowerCase())) { return item }
                        else if (item.transmisi.toLowerCase().includes(psearch.toLowerCase())) { return item } 
                        else if (item.serviceCategory.toLowerCase().includes(psearch.toLowerCase())) { return item }
                    }).map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td> {serial_number = serial_number + 1}</td>
                                    <td>{item.servItemName}</td>
                                    <td>{item.servItemPrice}</td>
                                    <td>{item.transmisi}</td>
                                    <td>{item.serviceCategory}</td>
                                    <td>{item.apliesTo}</td>
                                    <td><img src={item.servItemURL} alt='gambar' height='20px' width='auto' /></td>
                                    <td><button key={item._id} className='button-transparent' 
                                        onClick={(e) => handleRowUpdate(e, item)}>Edit 
                                        <span><FontAwesomeIcon icon={faPen} /></span> </button> </td>
                                </tr>
                            )
                    })}
                </tbody>
            </Table> ) : <Loading/> }
        </div>
        </div>
    )
}
export default Tab3RincianServis;