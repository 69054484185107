import React, { useEffect, useState, useRef } from 'react'
import Mininav from '../../components/Navigation/Mininav'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios'
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as yup from 'yup';
import '../profile/Profile.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { AllStates } from '../../context/Context';
import Footer from '../../components/Footer/Footer';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading/Loading';
import InlineEdit from "./inlineEdit";
import { useFormik } from 'formik';
import { DatePickerProps } from 'antd';
import { DatePicker, Space } from 'antd';


const dateFormat = 'YYYY-MM-DD';
const dateFormatList = ['DD-MM-YYYY', 'DD-MM-YY', 'DD-MM-YYYY', 'DD-MM-YY'];

var simpleSchemaValidation = yup.object({
  bikeCompany: yup.string().required('Pilih Merek'),
  model: yup.string().required('Pilih tipe motor'),
  nopol: yup.string().required('Ketik plat nomor'),
  odometer: yup.string(),
  transmisi: yup.string().required('Pilih salah satuu')
});

const Profilepic = () => {
  const [disableBtnKirimKeDB, setDisableBtnKirimKeDB] = useState(true);
  const [disableBtnGantiFoto, setDisableBtnGantiFoto] = useState(false);
  const btnRef = useRef();
  const inputRef = useRef();
  const profileRef = useRef();
  const { users, bikes, services, bookings, url } = AllStates();
  var user = JSON.parse(localStorage.getItem('user'));
  var email = ''; if (user) {email = user.email};
  const [dateSTNK, setDateSTNK] = useState('2024-01-01');
  const [dateSIM, setDateSIM] = useState('2024-01-01');
  const [dateServis, setDateServis] = useState('2024-01-01');
  let bikeAry = [], serviceArray = [], transArray = [], modArray = [];
  let brand, modelArray, transmisiArray = [];
  const [picURL, setPicURL] = useState(user.photoURL);
  const [tabSelected, setTabSelected] = useState('userInfo');
  const [storedTextUserName, setStoredTextUserName] = useState(user.userName);
  const [storedTextMobile, setStoredTextMobile] = useState(user.mobile);
  const [storedTextAddress1, setStoredTextAddress1] = useState(user.address1);
  const [storedTextAddress2, setStoredTextAddress2] = useState(user.address2);
  const [storedTextAddress3, setStoredTextAddress3] = useState(user.address3home);
  const [storedTextAddress4, setStoredTextAddress4] = useState(user.address4home);
  const [merekValue, setMerekValue] = useState('');
  const [tipeValue, setTipeValue] = useState('');

  const mURL = url+"/images/motors/motor_kartun.png";

  if (users) {
    const userFromState = Object.values(users).find(usr => usr.email === user.email);
    if (userFromState.lastLogin > user.lastLogin) { 
      console.log("userFromState = ",userFromState);
      user = userFromState; 
      localStorage.removeItem('user');
      localStorage.setItem(JSON.stringify('user', user));
    };
  };
  if (!user) { user = JSON.parse(localStorage.getItem('user')); };
  console.log("user = ", user);
  email = user.email;

  function selectServices(bikeCom) {
    services && services.map((i) => bikeCom === i.bikeCompany ? serviceArray.push({ value: i.serviceType })  : '');
    return serviceArray;
  }

  // formik function
  const { values, handleChange, handleSubmit, handleBlur, errors, touched } = useFormik({
    initialValues: {
      bikeCompany: user.bikeCompany, model: user.model, nopol: user.nopol, transmisi: user.transmisi, 
      odometer: user.odometer, periodeSTNK: user.periodeSTNK, periodeSIM: user.periodeSIM,
      motorNopol: user.motor[0].nopol, alamatPilihan: user.alamat[0].id, tglServis: '2024-01-01'
    },
    validationSchema: simpleSchemaValidation,
    onSubmit: (motorData, onSubmitProps) => {
        sendData(motorData); 
        onSubmitProps.resetForm();
        setDisableBtnKirimKeDB(true);
    }
  });

  modArray = []; modelArray = [];
  function unique(array) { return array.filter((item, index) => array.indexOf(item) === index) };
  bikes && bikes.map((i) => { bikeAry.push(i.bikeCompany); if (i.bikeCompany === merekValue) { modArray.push(i.model)}});
  brand = unique(bikeAry); modelArray = unique(modArray); transmisiArray = ['manual', 'matic'];

  function handleInputEdit(key, value) {
    setDisableBtnKirimKeDB(false);
    switch (key) {
      case 'nopol' : setInputNopol(value); values.nopol=value; return;
      case 'odometer' : setInputOdometer(value); values.odometer=value; return;
      default : return;
    }
  }



  function handleDataMotorChange(e) {
    console.log("e.target = ", e.target);
    setDisableBtnKirimKeDB(false);
    const key = e.target.name;
    const val = e.target.value;
    switch (key) {
      case 'bikeCompany' : 
          values.bikeCompany = val; 
          setMerekValue(val);
          modArray = []; modelArray = [];
          bikes && bikes.map((i) => i.bikeCompany === val ? modArray.push(i.model)  : '');
          console.log("modArray = ",modArray);
          modelArray = unique(modArray);
          console.log("modelArray = ",modelArray); 
          return;
      case 'model'       : 
          values.model= e.target.value; 
          setTipeValue(val);       
          return;
      case 'nopol'       : values.nopol= e.target.value;        return;
      case 'transmisi'   : values.transmisi= e.target.value;    return;
      case 'odometer '   : values.odometer= e.target.value;     return;
      case 'periodeSTNK' : values.periodeSTNK= e.target.value;  return;
      case 'periodeSIM'  : values.periodeSIM= e.target.value;   return;
      default : return;
    }
  }

  async function sendData(mData) {
    console.log("Function sendData Sending motor data = ", mData);
    let upData = { email: email, bikeCompany: mData.bikeCompany };
    updateUserInfo(upData, 'bikeCompany' );
    upData = { email: email, model: mData.model };
    updateUserInfo(upData, 'model' );
    upData = { email: email, nopol: mData.nopol };
    updateUserInfo(upData, 'nopol' );
    upData = { email: email, transmisi: mData.transmisi };
    updateUserInfo(upData, 'transmisi' );
    upData = { email: email, odometer: mData.odometer };
    updateUserInfo(upData, 'odometer' );
    upData = { email: email, periodeSTNK: mData.periodeSTNK };
    updateUserInfo(upData, 'periodeSTNK' );
    upData = { email: email, periodeSIM: mData.periodeSIM };
    updateUserInfo(upData, 'periodeSIM' );
    //const id = values.nopol.toLowerCase().replace(/\s/g, '');
    //updateUserInfo('id', id); console.log("id= ",id);
    console.log("id = ", mData.nopol.toLowerCase().replace(/\s/g, ''));
    //window.location.reload();
  };

  async function handleFileChange(e) {
    const fileImg = e.target.files[0];
    if (fileImg) {
      let urlFoto = url;
      const formData = new FormData();
      formData.append('file', fileImg, fileImg.name);
      formData.append('email', email);

      switch (tabSelected) {
        case 'userInfo' : urlFoto += "/image/update/picture/profile"; break;
        case 'userBike' : urlFoto += "/image/update/picture/userbike"; break;
        default : urlFoto += "/images/upload"; break;
      }

      console.log("formdata = ", formData.getAll('file'));
      try {
        await axios.post(urlFoto, formData)
        .then(res => { 
          let up_user = res.data;
          console.log(up_user);
          localStorage.removeItem('user');
          localStorage.setItem('user', JSON.stringify(up_user));
          window.location.reload() })
        .catch(error => toast.error(error.response.data.message)); } 
      catch (error) { toast.error(error.response.data.message) };
    }
    else { console.log("File not there") };
  };

  async function updateUserInfo(upDt, key) {
    try {
      await axios.post(`${url}/users/update/userinfo/${key}`, upDt)
      .then( res => {
        let upd_user = res.data.userdata; console.log("Updated user = ", upd_user);
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(upd_user));
      }).catch(error => toast.error(error.response.data.message));
    }
    catch (error) { toast.error(error.response.data.message) };  
  };

  async function updateUserName(newUserName) {
    const upData = { email: email, userName: newUserName };
    setStoredTextUserName(newUserName);
    updateUserInfo(upData, 'userName');
  };

  async function updateMobile(newMobile) {
    const upData = { email: email, mobile: newMobile };
    setStoredTextMobile(newMobile);
    updateUserInfo(upData, 'mobile'); 
  };

  async function updateAddress1(newAddress1) {
    const upData = { email: email, address1: newAddress1 };
    setStoredTextAddress1(newAddress1);
    updateUserInfo(upData, 'address1'); 
  };

  async function updateAddress2(newAddress2) {
    const upData = { email: email, address2: newAddress2 };
    setStoredTextAddress2(newAddress2);
    updateUserInfo(upData, 'address2'); 
  };
  async function updateAddress3(newAddress3) {
    const upData = { email: email, address3home: newAddress3 };
    setStoredTextAddress3(newAddress3);
    updateUserInfo(upData, 'address3home'); 
  };

  async function updateAddress4(newAddress4) {
    const upData = { email: email, address4home: newAddress4 };
    setStoredTextAddress4(newAddress4);
    updateUserInfo(upData, 'address4home'); 
  };

  function setOpaque(e) {
    btnRef.current.style.width = `${e.target.width}px`;
    if (disableBtnGantiFoto) { btnRef.current.style.opacity = 0.0; }
    else {btnRef.current.style.opacity = 0.7; };
  };

  function setNotOpaque(e) {
    btnRef.current.style.width = `${e.target.width}px`;
    if (disableBtnGantiFoto) { btnRef.current.style.opacity = 0.0; }
    else {btnRef.current.style.opacity = 0.2; };
  };

  function handleSelect(key) {
    setTabSelected(key);
    switch(key) {
      case 'userInfo'   : 
        setPicURL(user.photoURL); 
        setDisableBtnGantiFoto(false);
        btnRef.current.style.opacity = 0.2;
        return;
      case 'userBike'   : 
        setPicURL(user.motorURL); 
        setDisableBtnGantiFoto(false);
        btnRef.current.style.opacity = 0.2;
        return;
      case 'userServis' : 
        setPicURL(url+"/images/homepage/add_3.webp"); 
        setDisableBtnGantiFoto(true);
        btnRef.current.style.opacity = 0.0;
        return;
      default : 
        setPicURL(user.photoURL);
        setDisableBtnGantiFoto(false);
        btnRef.current.style.opacity = 0.1;
        return;
    }
  };
  
  function onDateChange(date, dateString) {
    console.log(date, dateString);
  };
  

  function formatDateServis(date, dateString) {
    console.log("Tanggal SERVIS = ", dateString);
    values.tanggalSservis = dateString;
    setDateServis(dateString);
  }
  function formatDateSTNK(date, dateString) {
    console.log("Tanggal STNK = ", dateString);
    values.periodeSTNK = dateString;
    setDateSTNK(dateString);
    setDisableBtnKirimKeDB(false);
  }
  function formatDateSIM(date, dateString) {
    console.log("Tanggal SIM = ", dateString);
    values.periodeSIM = dateString;
    setDateSIM(dateString);
    setDisableBtnKirimKeDB(false);
  }

  const handleUploadClick = () => { inputRef.current.click() ; };

  let nopolArray = [], alamatArray = []; 
  const [selectedMotor, setSelectedMotor] = useState(user.motor[0].motorURL);
  const [selectedIndexMotor, setSelectedIndexMotor] = useState(0);
  const [selectedAlamat, setSelectedAlamat] = useState(user.alamat[0].id);
  const [selectedIndexAlamat, setSelectedIndexAlamat] = useState(0);
  const [checkedGantiOli, setCheckedGantiOli] = useState(false);
  const [checkedGantiOliMesin, setCheckedGantiOliMesin] = useState(false);
  const [checkedGantiOliSamping, setCheckedGantiOliSamping] = useState(false);
  const [checkedGantiOliGardan, setCheckedGantiOliGardan] = useState(false);
  const [checkedGantiOliMinyakRem, setCheckedGantiOliMinyakRem] = useState(false);
  const [InputOliMesin, setInputOliMesin] = useState();
  const [InputOliSamping, setInputOliSamping] = useState();
  const [InputOliGardan, setInputOliGardan] = useState();
  const [InputOliMinyakRem, setInputOliMinyakRem] = useState();

  if (user.motor && (user.motor.length > 0)) { user.motor.map((item, i) => nopolArray.push(item.nopol))};
  if (user.alamat && (user.alamat.length > 0)) { user.alamat.map((item, i) => alamatArray.push(item.id))};

  function showMotor(e) {
    console.log("onChange from = ", e.target.value);
    user.motor.map( (item, i) => {
      if (item.nopol === e.target.value) { 
        setSelectedMotor(item.motorURL);
        setSelectedIndexMotor(i);
        values.motorNopol = e.target.value;
      }
    })
  }

  function pilihAlamat(e) {
    console.log("onChange from = ", e.target.value);
    user.alamat.map( (item, i) => {
      if (item.id === e.target.value) { 
        setSelectedAlamat(item.id);
        setSelectedIndexAlamat(i);
        values.alamatPilihan = e.target.value;
      }
    })
  }

  function checkbox(val, keyword) {
    switch (keyword) {
      case 'Oli' : {
        setCheckedGantiOli(val);
        return;
      }
      case 'OliMesin' : {
        setCheckedGantiOliMesin(val);
        return;
      }
      case 'OliSamping' : {
        setCheckedGantiOliSamping(val);
        return;
      }
      case 'OliGardan': {
        setCheckedGantiOliGardan(val);
        return;
      }
      case 'OliMinyakRem' : {
        setCheckedGantiOliMinyakRem(val);
        return;
      }
      default : return;
    }
     
  };

  const [inputNopol, setInputNopol] = useState(values.nopol);
  const [inputOdometer, setInputOdometer] = useState(values.odometer);

  useEffect(() => {

  }, []);

  return (
    <div>
      <Mininav />
      <div className='user-detail'>
        {/* profile picture */}
        <div className='profile-picture-container col-12'>
          <div className='picture col-11 col-md-12' >
            <div className='picture'>
            <img src={`${picURL}`} alt='profile picture'
              className='picture-img' onMouseOver={setOpaque} onMouseLeave={setNotOpaque} ref={profileRef} />
            <button onClick={handleUploadClick} ref={btnRef} width='301px' onMouseOver={setOpaque} onMouseLeave={setNotOpaque}
            className='picture-button' style={{ opacity:0.1 }} disabled={disableBtnGantiFoto} >
            <p style={{ fontSize: '30px' , fontWeight:'bold'  }}>GANTI FOTO 
            <span><FontAwesomeIcon icon={faUserPen} /></span></p></button>    
            <input ref={inputRef} type='file' accept="image/*" onChange={handleFileChange} style={{ display: 'none' }} />        
            </div>
          </div>
        </div>

        <div><Tabs defaultActiveKey="userInfo" id="justify-tab" className="mb-3 tablink" justify onSelect={handleSelect} >
            <Tab eventKey="userInfo" title="User">
        
              <div className='user-detail m-3'>
                <div><p style={{ fontSize: '30px' , fontWeight:'bold' }}>USER DETAIL</p></div>
                  <div className='user-container row' key={user._id}>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label col-4'><p>EMAIL</p></div>
                      <div className='label-right col-8'><p>{email}</p></div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label col-4'><p>Nama</p></div>
                      <div className='label-right col-8'><p>
                        <InlineEdit text={storedTextUserName}
                        onSetText={(text) => updateUserName(text) } /></p></div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label col-4'><p>No HP</p></div>
                      <div className='label-right col-8'><p>
                        <InlineEdit text={storedTextMobile}
                        onSetText={(text) => updateMobile(text) } /></p>
                      </div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label col-4'><p>Alamat Kantor</p></div>
                      <div className='label-right col-8'><p>
                        <InlineEdit text={storedTextAddress1}
                        onSetText={(text) => updateAddress1(text) } /></p></div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label col-4'><p>Kota</p></div>
                      <div className='label-right col-8'><p>
                        <InlineEdit text={storedTextAddress2}
                        onSetText={(text) => updateAddress2(text) } /></p>
                      </div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label col-4'><p>Alamat Rumah</p></div>
                      <div className='label-right col-8'><p>
                        <InlineEdit text={storedTextAddress3}
                        onSetText={(text) => updateAddress3(text) } /></p></div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label col-4'><p>Kota</p></div>
                      <div className='label-right col-8'><p>
                        <InlineEdit text={storedTextAddress4}
                        onSetText={(text) => updateAddress4(text) } /></p>
                      </div>
                    </div>

                  </div>
              </div>
            </Tab>

            <Tab eventKey="userBike" title="Motor">
            <div className='user-detail m-3 ' >
              <form onSubmit={handleSubmit} style={{ backgroundColor: 'transparent', width: '100%' }}>
                <div><p style={{ fontSize: '30px' , fontWeight:'bold' }}>DATA MOTOR</p></div>
                  <div className='user-container row' key="userBike" >
                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label-form col-6'><p>MEREK</p></div>
                      <div className='label-right-form col-6'>
                      <TextField select id="merek" label="Merek" variant="outlined" onBlur={handleBlur} key="Merek"
                      fullWidth margin="normal" name="bikeCompany" value={values.bikeCompany} onChange={handleChange}
                      error={touched.bikeCompany && Boolean(errors.bikeCompany)} helperText={touched.bikeCompany && errors.bikeCompany} >
                      <MenuItem key={"111"} value={""} disabled> --Pilih merk motor--  </MenuItem>
                      {brand.map((i) => <MenuItem value={i} key={i.bikeCompany} > {i} </MenuItem>)}
                  </TextField>
                  </div>
                  </div>
                  
                  <div className='user-detail-row m-2 col-12 col-md-6'>
                  <div className='label-form col-6'><p>TIPE / MODEL</p></div>
                      <div className='label-right-form col-6'>
                  <TextField select id="model" label="Tipe" variant="outlined" onBlur={handleBlur} key="Tipe"
                      fullWidth margin="normal" name="model" value={values.model} onChange={handleChange}
                      error={touched.model && Boolean(errors.model)} helperText={touched.model && errors.model} >
                      <MenuItem key={"222"} value={""} disabled> --pilih tipe motor--  </MenuItem>
                      {bikes && bikes.map((i) => values.bikeCompany && values.bikeCompany === i.bikeCompany ? 
                      <MenuItem value={i.model} key={i.model}> {i.model} </MenuItem> : '')}
                  </TextField>
                      </div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label-form col-6'><p>Nopol</p></div>
                      <div className='label-right-form col-6'>
                        <p><InlineEdit text={inputNopol} onSetText={(text) => {handleInputEdit('nopol', text)}}/></p>
                      </div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label-form col-6'><p>Transmisi</p></div>
                      <div className='label-right-form col-6'>
                        <TextField select id="transmisi" label="Transmisi" variant="outlined" onBlur={handleBlur} key="Transmisi"
                          fullWidth name="transmisi" value={values.transmisi} onChange={handleDataMotorChange}
                          error={touched.transmisi && Boolean(errors.transmisi)} helperText={touched.transmisi && errors.transmisi}  >
                          <MenuItem key={"Tipe transmisi"} value={""} disabled> --Tipe transmisi--  </MenuItem>
                          {transmisiArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                        </TextField></div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label-form col-6'><p>Odometer</p></div>
                      <div className='label-right-form col-6'>
                        <p><InlineEdit text={inputOdometer} onSetText={(text) => {handleInputEdit('odometer', text)}}/></p>
                      </div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label-form col-6'><p>Periode STNK</p></div>
                      <div className='label-right-form col-6'>
                        <DatePicker onChange={formatDateSTNK} label='Select Date' format={dateFormat} key="PeriodeSTNK"
                        value={dayjs(dateSTNK, dateFormat)} defaultValue={dayjs(values.periodeSTNK, dateFormat)} />
                      </div>
                    </div>

                    <div className='user-detail-row m-2 col-12 col-md-6'>
                      <div className='label-form col-6'><p>Periode SIM</p></div>
                      <div className='label-right-form col-6'>
                        <DatePicker onChange={formatDateSIM} label='Select Date' format={dateFormat} key="PeriodeSIM"
                        value={dayjs(dateSIM, dateFormat)} defaultValue={dayjs(values.periodeSIM, dateFormat)}  />
                      </div>
                    </div>
                  </div>
                  <div className='form-btn'><button className='btn btn-primary mt-3 register-btn' 
                    type='submit' disabled={disableBtnKirimKeDB}>KIRIM KE DATABASE</button></div>
                </form>
              </div>
            </Tab>

            <Tab eventKey="userServis" title="Servis">
              <div className='user-detail m-3'>
                <div className='user-detail-right'><p style={{ fontSize: '30px' , fontWeight:'bold', marginRight: '30px' }}>
                  PREFERENSI SERVIS</p></div>
                <div className='wrapper col-12 row'>
                  <div className="a col-11 col-md-6 col">
                    <p style={{ fontSize: '30px' , fontWeight:'bold' }}>UNTUK MOTOR ANDA</p>
                    <img src={selectedMotor} alt='Gambar Motor' width='100%' />
                  </div>
                  <div className="b col-12 col-md-6 col">
                  <div className='user-detail-row m-2 col-12 col-md-11'>
                    <div className='label-form col-4'><p>NOPOL</p></div>
                    <div className='label-right-form col-8'>
                        <TextField select id="motorNopol" label="Nopol" variant="outlined" onBlur={handleBlur} key="motorNopol"
                          fullWidth name="motorNopol" value={values.motorNopol} onChange={showMotor} 
                          error={touched.motorNopol && Boolean(errors.motorNopol)} helperText={touched.motorNopol && errors.motorNopol}  >
                          {nopolArray.map((i) => <MenuItem key={i} value={i} > {i} </MenuItem>)}
                        </TextField>
                    </div>
                  </div>
                  <div className='user-detail-row m-2 col-12 col-md-11'>
                    <div className='label-form col-4'><p>MOTOR</p></div>
                    <div className='label-right-form col-8'>
                        <TextField id="motordetil" required label="Motor" variant="outlined" key="motordetil"  fullWidth name="motordetil" 
                          value={user.motor[selectedIndexMotor].bikeCompany+" "+user.motor[selectedIndexMotor].model+" "+
                          user.motor[selectedIndexMotor].tahun+" warna "+user.motor[selectedIndexMotor].warna} 
                          inputProps={{min: 0, maxLength:'10', style: { textAlign: 'center' }}}/>
                    </div>
                  </div>
                  <div className='user-detail-row m-2 col-12 col-md-11'>
                  <p style={{ fontSize: '20px' , fontWeight:'bold' }}>RENCANA SERVIS</p></div>
                  <div className='user-detail-row m-2 col-12 col-md-11'>
                  <div className='label-form col-4'><p>SERVIS DI</p></div>
                    <div className='label-right-form col-8'>
                        <TextField select id="alamat" label="Tempat" variant="outlined" onBlur={handleBlur} key="alamat"
                          fullWidth name="alamat" value={values.alamatPilihan} onChange={pilihAlamat} 
                          error={touched.alamatPilihan && Boolean(errors.alamatPilihan)} helperText={touched.alamatPilihan && errors.alamatPilihan}  >
                          {alamatArray.map((i) => <MenuItem key={i} value={i} > {i} </MenuItem>)}
                        </TextField>
                    </div>
                  </div>
                  <div className='user-detail-row m-2 col-12 col-md-11'>
                    <div className='label-form-multiline col-4'><p>ALAMAT</p></div>
                    <div className='label-right-form-multiline col-8'>
                        <TextField id="outlined-multiline-static" required label="Alamat" variant="outlined" key="alamatdetil"  name="alamatdetil" 
                          value={user.alamat[selectedIndexAlamat].alamat1+" "+user.alamat[selectedIndexAlamat].alamat2+", "+
                          user.alamat[selectedIndexAlamat].kelurahan+", "+user.alamat[selectedIndexAlamat].kecamatan+", "+
                          user.alamat[selectedIndexAlamat].kotakab+" "+user.alamat[selectedIndexAlamat].kodepos} 
                          inputProps={{min: 0, maxLength:'10', style: { textAlign: 'center' }}} multiline rows={3} fullWidth />
                    </div>
                  </div>
                  <div className='user-detail-row m-2 col-12 col-md-11'>
                      <div className='label-form col-4'><p>TANGGAL</p></div>
                      <div className='label-right-form col-8'>
                        <DatePicker onChange={formatDateServis} label='Select Date' format={dateFormat} key="tanggal"
                        value={dayjs(dateServis, dateFormat)} defaultValue={dayjs('2024-01-01', dateFormat)}  />
                      </div>
                    </div>
                    <Container sx={{ '& .MuiSvgIcon-root': { fontSize: 30 }} } >
                      <Box sx={{ bgcolor: '#cfe8fc', height: '40vh', borderRadius: '8px', marginBottom: '10px', paddingLeft: '10px', paddingTop: '10px' }} >
                          <FormControlLabel control={<Checkbox onChange={(e) => checkbox(e.target.checked, "Oli")} 
                          checked={checkedGantiOli} sx={{ marginTop: -1,}} /> } sx={{ alignItems: 'flex-start', width: '100%' }}
                          label={<p style={{ fontSize: '20px' , fontWeight:'bold' }}>GANTI OLI</p>}  />

                          <FormControlLabel control={<Checkbox onChange={(e) => checkbox(e.target.checked, "OliMesin")} 
                            checked={checkedGantiOliMesin} sx={{ marginTop: -1, paddingTop:1 }} disabled={!checkedGantiOli} /> } 
                            sx={{ alignItems: 'flex-start', width: '30%', marginLeft: 5, paddingTop:2, marginBottom:2  }}
                            label={<p style={{ fontSize: '15px' , fontWeight:'bold' }}>OLI MESIN</p>}  />
                          <TextField select id="transmisi" label="Transmisi" variant="outlined" onBlur={handleBlur} key="Transmisi OliMesin"
                            fullWidth name="transmisi" value={values.transmisi} onChange={handleChange}  sx={{ alignItems: 'flex-end', width: '50%', marginRight: 5 }}
                            error={touched.transmisi && Boolean(errors.transmisi)} helperText={touched.transmisi && errors.transmisi} disabled={!checkedGantiOliMesin} >
                            <MenuItem key={"Tipe transmisi OliMesin"} value={""} disabled> --Tipe transmisi--  </MenuItem>
                            {transmisiArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                            
                          </TextField>

                          <FormControlLabel control={<Checkbox onChange={(e) => checkbox(e.target.checked, "OliSamping")} 
                            checked={checkedGantiOliSamping} sx={{ marginTop: -1, paddingTop:1 }} disabled={!checkedGantiOli}  /> } 
                            sx={{ alignItems: 'flex-start', width: '30%', marginLeft: 5, paddingTop:2, marginBottom:2  }}
                            label={<p style={{ fontSize: '15px' , fontWeight:'bold' }}>OLI SAMPING</p>}  />
                          <TextField select id="transmisi" label="Transmisi" variant="outlined" onBlur={handleBlur} key="TransmisiOliSamping"
                            fullWidth name="transmisi" value={values.transmisi} onChange={handleChange}  sx={{ alignItems: 'flex-end', width: '50%', marginRight: 5 }}
                            error={touched.transmisi && Boolean(errors.transmisi)} helperText={touched.transmisi && errors.transmisi} disabled={!checkedGantiOliSamping} >
                            <MenuItem key={"Tipe transmisiOliSamping"} value={""} disabled> --Tipe transmisi--  </MenuItem>
                            {transmisiArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                           
                          </TextField>

                          <FormControlLabel control={<Checkbox onChange={(e) => checkbox(e.target.checked, "OliGardan")} 
                            checked={checkedGantiOliGardan} sx={{ marginTop: -1, paddingTop:1 }} disabled={!checkedGantiOli}  /> } 
                            sx={{ alignItems: 'flex-start', width: '30%', marginLeft: 5, paddingTop:2, marginBottom:2  }}
                            label={<p style={{ fontSize: '15px' , fontWeight:'bold' }}>OLI GARDAN</p>}  />
                          <TextField select id="transmisi" label="Transmisi" variant="outlined" onBlur={handleBlur} key="TransmisiOliGardan"
                            fullWidth name="transmisi" value={values.transmisi} onChange={handleChange}  sx={{ alignItems: 'flex-end', width: '50%', marginRight: 5 }}
                            error={touched.transmisi && Boolean(errors.transmisi)} helperText={touched.transmisi && errors.transmisi} disabled={!checkedGantiOliGardan} >
                            <MenuItem key={"Tipe trasmisiOliGardan"} value={""} disabled> --Tipe transmisi--  </MenuItem>
                            {transmisiArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                            
                          </TextField>

                          <FormControlLabel control={<Checkbox onChange={(e) => checkbox(e.target.checked, "OliMinyakRem")} 
                            checked={checkedGantiOliMinyakRem} sx={{ marginTop: -1, paddingTop:1 }} disabled={!checkedGantiOli}  /> } 
                            sx={{ alignItems: 'flex-start', width: '30%', marginLeft: 5, paddingTop:2, marginBottom:2  }}
                            label={<p style={{ fontSize: '15px' , fontWeight:'bold' }}>MINYAK REM</p>}  />
                          <TextField select id="transmisi" label="Transmisi" variant="outlined" onBlur={handleBlur} key="TransmisiOliMinyakRem"
                            fullWidth name="transmisi" value={values.transmisi} onChange={handleChange}  sx={{ alignItems: 'flex-end', width: '50%', marginRight: 5 }}
                            error={touched.transmisi && Boolean(errors.transmisi)} helperText={touched.transmisi && errors.transmisi} disabled={!checkedGantiOliMinyakRem} >
                            <MenuItem key={"Tipe ransmisiOliMinyakRem"} value={""} disabled> --Tipe transmisi--  </MenuItem>
                            {transmisiArray.map((i) => <MenuItem value={i} key={i} > {i} </MenuItem>)}
                            
                          </TextField>
                      </Box>
                    </Container>
                  </div>
                </div>
              </div>
            </Tab>
        </Tabs></div>
        {/* Previous bookings list */}
        <div className='m-3'>
        <div><p style={{ fontSize: '30px' , fontWeight:'bold' }}>ORDER ANDA</p></div>
          {
            bookings ? (
              bookings.sort(function (a, b) {
                if (a.serviceDate < b.serviceDate) return -1;
                if (a.serviceDate > b.serviceDate) return 1;
                return 0;
              })
                .map((i) => {
                  if (i.email === email) {
                    return (
                      <div className='show-booking' key={i._id}> 
                        <table key='show-booking' >
                          <tbody>
                            {/* <tr><td>SERVICE</td><td>: {i.serviceType.toUpperCase()}</td></tr> */}
                            <tr><td>SERVICE</td><td>: {i.serviceType}</td></tr>
                            <tr><td>MOTOR</td><td>: {i.bikeCompany} {i.model} {i.cc}</td></tr>
                            <tr><td>NO POL</td><td>: {i.nopol}</td></tr>
                            <tr><td>TANGGAL</td><td>: {i.serviceDate} Jam {i.serviceTime}</td></tr>
                            <tr><td>BIAYA</td><td>: {i.totalHarga} </td></tr>
                          </tbody>
                        </table>
                      </div>
                    )
                  } 
                })
            )  : <Loading/>
          }
        </div>
      </div >
      <Footer />
    </div>
  )
}

export default Profilepic