// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.signup-registration {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important; 
    background: linear-gradient(to right, #E2E2E2, #C9D6FF); 
  }`, "",{"version":3,"sources":["webpack://./src/routes/Register/Register.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,0BAA0B;IAE1B,uDAAuD;EACzD","sourcesContent":["\r\n.signup-registration {\r\n    height: 100vh;\r\n    width: 100vw;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    margin-left: 0 !important;\r\n    margin-right: 0 !important;\r\n    background: -webkit-linear-gradient(to right, #E2E2E2, #C9D6FF); \r\n    background: linear-gradient(to right, #E2E2E2, #C9D6FF); \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
