import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { TextField } from '@mui/material'
import '../admin/Admin.css'
import { toast } from 'react-toastify'
import Adminnav from './Adminnav'
import { useNavigate } from 'react-router-dom'
import { AllStates } from '../../context/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPen } from '@fortawesome/free-solid-svg-icons';

const AdminAddBikes = () => {
    const {url} = AllStates();
    const [disableBtnUpload, setDisableBtnUpload] = useState(true);
    const [fileImage, setFileImage] = useState();
    const [bikeCompany, setBikeCompany] = useState(''); 
    const [model, setModel] = useState('');
    const [transmisi, setTransmisi] = useState('');
    const [cc, setCc] = useState('');
    const [tahun, setTahun] = useState('');
    const [warna, setWarna] = useState('');
    const [image, setImage] = useState(url+"/images/add_photo.png");
    const navigate = useNavigate();

    async function savedata() {
        const newBike = { bikeCompany, model, transmisi, cc, tahun, warna, image }
        try { await axios.post(`${url}/bikes/addBike`, newBike)
            .then(res => res.data.response ? toast.success('Berhasil') : toast.error('Gagal'))
        } catch (error) { console.log('error menambah motor', error); }
        navigate('/admin/bikes'); window.location.reload();
    };
    function handleChange(e) {
        setFileImage(e.target.files[0]);
        setDisableBtnUpload(false);  
    };
    async function updatePic() {
        const formData = new FormData()
        formData.append('file', fileImage)
    
        try {
          await axios.post(`${url}/image/new/picture/motor`, formData)
          .then(res => { setImage(res.data.motorURL)})
          .catch(error => toast.error(error.response.data.message));    } 
        catch (error) { toast.error(error.response.data.message) };  
    };

    useEffect(() => { if (JSON.parse(localStorage.getItem('user')).role !== 'admin') { navigate('/') } }, []);
    return (
        <div>
            <Adminnav />
            <div className='profile-picture-container col-12'>
            <div className='picture col-11 col-md-4'><img src={image} alt="gambar" /></div>
            <div className='upload-button m-3'>
                <div><p style={{ fontSize: 'larger', fontWeight: 'bold' }}>UPLOAD FOTO MOTOR 
                <span><FontAwesomeIcon icon={faPen} /></span> </p></div> 
                <div>
                    <input type='file' onChange={handleChange} /> <br />
                    <button onClick={updatePic} className='btn btn-success m-2' disabled={disableBtnUpload}> 
                        UPLOAD <span><FontAwesomeIcon icon={faUpload} /></span>
                    </button>
                </div>
                </div>
            </div>
            <div className='add-new-bike'>
                <p className='add-bike-heading'> TAMBAH MOTOR</p>
                <TextField className="mb-3 mt-3" label="Merek" variant="outlined" color="secondary" value={bikeCompany} onChange={(e) => setBikeCompany(e.target.value)} style={{ width: "300px" }} />
                <TextField className="mb-3" label="Tipe" variant="outlined" color="secondary" value={model} onChange={(e) => setModel(e.target.value)} style={{ width: "300px" }} />
                <TextField className="mb-3" label="Transmisi" variant="outlined" color="secondary" value={transmisi} onChange={(e) => setTransmisi(e.target.value)} style={{ width: "300px" }} />
                <TextField className="mb-3" label="cc" variant="outlined" color="secondary" value={cc} onChange={(e) => setCc(e.target.value)} style={{ width: "300px" }} />
                <TextField className="mb-3" label="Tahun" variant="outlined" color="secondary" value={tahun} onChange={(e) => setTahun(e.target.value)} style={{ width: "300px" }} />
                <TextField className="mb-3" label="Warna" variant="outlined" color="secondary" value={warna} onChange={(e) => setWarna(e.target.value)} style={{ width: "300px" }} />
                <button className="mb-5 btn btn-primary" color="secondary" onClick={() => savedata()}> SIMPAN </button>
            </div>
        </div>
    )
}
export default AdminAddBikes